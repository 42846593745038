import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }
  public getAllRoles() {
    return this.http.get(`${API_URL}roles/`);
  }
  public getRole(id) {
    return this.http.get(`${API_URL}roles/${id}/`);
  }
  public getRoles(event) {
    return this.http.post(`${API_URL}roles/`, event);
  }
  public getRolesForType(user_type) {
    return this.http.get(`${API_URL}roles/${user_type}/`);
  }
  public submitRoles(data) {
    return this.http.post(`${API_URL}add-roles/`, data);
  }
}