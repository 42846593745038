﻿
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterContentChecked, ElementRef, Injectable } from '@angular/core';
import { StoresService } from '../stores/stores.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomersService } from '../customers/customers.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';


import { EmployeesService } from '../employees/employees.service';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    // store_id;
    //private currentUserSubject: BehaviorSubject<User>;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient,
        public dialog: MatDialog,) {
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post(`${environment.apiUrl}login/`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user['user'].length>0 /*&& user.token*/) {
                    var page_settings = {
                        "companies": { "items_per_page": 10, "current_page": 1 },
                        "customers": { "items_per_page": 10, "current_page": 1 },
                        "employees": { "items_per_page": 10, "current_page": 1 },
                        "nominal_accounts": { "items_per_page": 10, "current_page": 1 },
                        "transactions": { "items_per_page": 10, "current_page": 1 },
                        "returns": { "items_per_page": 10, "current_page": 1 },
                        "receipts": { "items_per_page": 10, "current_page": 1 },
                        "quotations": { "items_per_page": 10, "current_page": 1 },
                        "products": { "items_per_page": 10, "current_page": 1 },
                        "raw_products": { "items_per_page": 10, "current_page": 1 },
                        "promotions": { "items_per_page": 10, "current_page": 1 },
                        "purchase_transactions": { "items_per_page": 10, "current_page": 1 },
                        "purchase_returns": { "items_per_page": 10, "current_page": 1 },
                        "purchase_receipts": { "items_per_page": 10, "current_page": 1 },
                        "suppliers": { "items_per_page": 10, "current_page": 1 },
                        "payments": { "items_per_page": 10, "current_page": 1 },
                        "stores": { "items_per_page": 10, "current_page": 1 },
                        "delivery_notes": { "items_per_page": 10, "current_page": 1 }

                    };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user['user']));
                    localStorage.setItem('pageSettings', JSON.stringify(page_settings));
                    this.currentUserSubject.next(user['user']);
                }

                return user['user'];
            }));
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('pageSettings');
        this.currentUserSubject.next(null);
        return;
    }

}

