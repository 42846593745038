import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PromotionsService } from './promotions.service';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
export interface PromotionsElement {
  promotion_id: number,
  product_id, sku,
  alternate_sku,
  product_name,
  product_description,
  category,
  brand,
  price_per_unit,
  start_date, end_date, discount, comments
}

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {


  selectedColumns;
  dropdownList = [];
  selectedItems = [];
  loaded = false;
  //////////////////////////////////
  displayedColumns: string[] = ['edit'
  ];
  filter_dropdown = false;
  ELEMENT_DATA: PromotionsElement[];
  selection = new SelectionModel<PromotionsElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  currentUserSubscription;
  currentUser;
  store_id;
  columns;
  columns_selected = ['actions'];
  length;
  pageSize = 10;
   pageIndex; pageEvent: PageEvent;
  
  page;

  queryField: FormControl = new FormControl();
  constructor(private promotionsService: PromotionsService,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private local: LocalStorageService, private cdr: ChangeDetectorRef) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["promotions"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["promotions"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.getNumberofPromotions();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["promotions"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["promotions"]["current_page"];
    this.getServerData(null);
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchPromotion(data, this.store_id).toPromise().then((data: Array<PromotionsElement>) => {
        this.ELEMENT_DATA = data;
      });
    });

    this.promotionsService.getPromotionsColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        this.dropdownList.push({ item_id: data[key]['column_name'], item_text: this.getColumnName(data[key]['column_name']) });
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "product_id") ? "product_name" : ((data[key]['column_name'] == "store_id") ? "store_name" : data[key]['column_name']));
        }
      }
      this.loaded = true;
    });
  }

  getColumnName(inp): string {
    if (inp == "promotion_id") return "ID";
    if (inp == "product_id" || inp == "product_name") return "Product";
    if (inp == "start_date") return "From";
    if (inp == "end_date") return "To"; ///////////
    if (inp == "discount") return "Discount";
    if (inp == "comments") return "Comments";
    if (inp == "is_active") return "Active";
    if (inp == "store_id" || inp == "store_name") return "Store";
  }
  selectionChange(item, i, event) {
    this.promotionsService.setPromotionsColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.promotions = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.promotions = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  getNumberofPromotions = function () {
    this.promotionsService.getPromotionsCount(this.store_id).toPromise().then(respons => {
      if (respons[0]) { this.length = respons[0]["COUNT(*)"]; }
      else { this.length = 0; }
    });
  }
  public getServerData(event?: PageEvent) {
    if (this.store_id !== 'all') {
      if (event) {
        this.promotionsService.getPromotions(event, this.store_id).subscribe((data: Array<PromotionsElement>) => {
          this.ELEMENT_DATA = data;
          var pageSettings = JSON.parse(localStorage.pageSettings);
          
          pageSettings.promotions = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
        });
      }
      else {
        var pageSettings = JSON.parse(localStorage.pageSettings);
        this.promotionsService.getPromotions({
          'length': this.length,
          'pageIndex': pageSettings.promotions.current_page,
          'pageSize': pageSettings.promotions.items_per_page,
          'previousPageIndex': 0
        }, this.store_id).subscribe((data: Array<PromotionsElement>) => {
          this.ELEMENT_DATA = data;
        });
      }
    }
    else {
      this.getPromotions()
    }
    return event;
  }
  getPromotions() {
    this.promotionsService.getAllPromotions().subscribe((data: Array<PromotionsElement>) => {
      this.ELEMENT_DATA = data;
    });
  }
  
  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
}