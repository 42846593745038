
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UploadService } from '../_services/upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, tap, takeUntil, debounceTime, switchMap } from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';
import { SearchService } from '../_services/search/search.service';
import { ToastrService } from 'ngx-toastr';
import bsCustomFileInput from 'bs-custom-file-input';
import { Papa } from 'ngx-papaparse';
import { ProductsService } from '../products/products.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { concat } from 'rxjs';


@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent implements OnInit {

  file_path; data; loading = false;
  // DJANGO_SERVER = 'http://127.0.0.1:8000'
  message; imagePath; imgURL; uploadedYet = false;
  form: FormGroup;
  response; product;
  upload_images_qty = "one";
  imageURL;
  selectedFile: File = null;
  DJANGO_SERVER = 'http://127.0.0.1:8000';
  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver: boolean = false;
  public productMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public productMultiFilterCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  protected _onDestroy = new Subject<void>();

  /** list of products filtered by search keyword */
  public filteredProductsMulti: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private formBuilder: FormBuilder, private uploadService: UploadService,
    public local: LocalStorageService,
    private _searchService: SearchService, private toasterService: ToastrService,
    private papa: Papa, private productsService: ProductsService, private router: Router) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    bsCustomFileInput.init()
    this.form = this.formBuilder.group({
      profile: ['']
    });
    // set initial selection
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), false)))
      .subscribe(filteredProducts => {
        this.searching = false;
        this.filteredProductsMulti.next(filteredProducts);
      },
        error => {
          this.searching = false;
        });
  }
  fileOverBase(event): void {
    this.hasBaseDropZoneOver = event;
  }
  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }
  onSelectFiles(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.selectedFile = <File>event.target.files[i];
        if (event.target.files && event.target.files[i]) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageURL = event.target.result;
            this.urls.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
        var formData = new FormData();
        formData.append('image', this.selectedFile, this.selectedFile.name);
        formData.append('product', this.selectedFile.name.split(".")[0]);
        this.request_data.push(formData);
      }
    }
  }
  Upload() {
    this.uploadedYet = true;
    const files = this.getFiles();
    let requests = [];
    files.forEach((file) => {
      let formData = new FormData();
      formData.append('image', file.rawFile, file.name);
      formData.append('product_sku', file.name.split("_")[0]);
      requests.push(this.uploadService.uploadMultiple(formData));
    });

    concat(...requests).subscribe(
      (res) => {
        if (res["error"]) {
          var node = document.createElement("LI");                 // Create a <li> node
          var textnode = document.createTextNode(res["error"]);         // Create a text node
          node.appendChild(textnode);                              // Append the text to <li>
          document.getElementById("errorLog").appendChild(node);     // Append <li> to <ul> with id="myList"
          // document.getElementById("errorLog").appendChild(res["error"])
        }
      },
      (err) => {
        var node = document.createElement("LI");                 // Create a <li> node
        var textnode = document.createTextNode(err);         // Create a text node
        node.appendChild(textnode);                              // Append the text to <li>
        document.getElementById("errorLog").appendChild(node);
      }
    );
    // this.router.navigate(['/products']);
  }
  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageURL = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  onUpload() {
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    formData.append('product', this.product.product_id);
    this.uploadService.upload(formData).subscribe(res => {
      this.toasterService.success('Quantity Updated', 'Updated!', {
        timeOut: 5000
      });
      this.imagePath = "";
      this.imageURL = null;
      this.imgURL = null;
      this.product = null;
      this.productMultiFilterCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), false)))
        .subscribe(filteredProducts => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
          error => {
            this.searching = false;
          });
    })
    this.selectedFile = null;
    document.forms.namedItem("image_form").reset();
  }
  change(event) {
    let p_id = event.source.value.product_id;
    this.product = event.source.value;
  }
  openedChange(event) {
    if (!event) {
      this.productMultiFilterCtrl = new FormControl();
      this.filteredProductsMulti = new ReplaySubject<any>(1);
      this.productMultiFilterCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), false)))
        .subscribe(filteredProducts => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
          error => {
            this.searching = false;
          });
    }
  }
  urls = [];
  request_data = [];
  ConvertCSVtoJSON() {
    this.productsService.addProductImagesCSV(this.request_data).subscribe((data: Array<object>) => {
      // this.router.navigate(['/products']);
    }, err => {
      this.loading = false;
    }
    );
  }

}