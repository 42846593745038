import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { StoresService } from '../stores/stores.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss']
})
export class AddStoreComponent implements OnInit {
  public id: string;
  submitted = false;
  myform: FormGroup;
  store_name: FormControl;
  address: FormControl;
  city: FormControl;
  postcode: FormControl;
  phone: FormControl;
  account_number: FormControl;
  acc_id: FormControl;
  model;
  
  nominal_accounts;
  selectedAccounts;
  constructor(private route: ActivatedRoute, private storesService: StoresService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toaster: ToastrService,
    private nominalService: NominalAccountsService) { 
      window.scrollTo(0,0);}

  ngOnInit() {
    
    this.model = {};
    this.id = this.route.snapshot.paramMap.get('id');
    this.createFormControls();
    this.createForm();
    if (this.id != null) {
      this.storesService.getStore(this.id).subscribe((data: Array<object>) => {
        this.model = data[0];
      });
    }
    this.nominalService.getAllNominalAccounts().toPromise().then(data=>{
      this.nominal_accounts = data;
      this.selectedAccounts = this.nominal_accounts;
    })
  }

  createFormControls() {
    this.store_name = new FormControl('', Validators.required);
    this.address = new FormControl('');
    this.city = new FormControl('');
    this.postcode = new FormControl('');
    this.phone = new FormControl('');
    this.account_number = new FormControl('');
    this.acc_id = new FormControl('');
  }
  onKey(value) { 
    this.selectedAccounts = this.search(value);
    }
    
    // Filter the states list and send back to populate the selectedStates**
    search(value: string) { 
      let filter = value.toLowerCase();
      return this.nominal_accounts.filter(option => option.code_number.toLowerCase().includes(filter) || option.description.toLowerCase().includes(filter));
    }

  createForm() {
    this.myform = new FormGroup({
      store_name: this.store_name,
      address: this.address,
      city: this.city,
      postcode: this.postcode,
      phone: this.phone,
      account_number: this.account_number,
      acc_id: this.acc_id
    });
  }
  submit = function () {
    this.submitted = true;
    if (this.myform.status === "VALID") {
      this.confirmationDialogService.confirm('Submit?', `Are you sure to submit?`).then((confirmed) => {
        if (confirmed) {
          if (this.id != null) {
            this.storesService.updateStore(this.id, this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/stores']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              })
          }
          else {
            this.storesService.addStore(this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/stores']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              })
          }
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }
}
