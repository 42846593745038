import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PurchaseReceiptsService {

  constructor( private http: HttpClient) { }
  public getAllPurchaseReceipts(){
    return  this.http.get(`${API_URL}purchase-receipts/`);
  }
  public getAllPurchaseReturns(){
    return  this.http.get(`${API_URL}purchase-returns/`);
  }
  public getPurchaseReceipt(id){
    return  this.http.get(`${API_URL}purchase-receipts/view/${id}/`);
  }
  public getPurchaseReturn(id){
    return  this.http.get(`${API_URL}purchase-returns/view/${id}/`);
  }
  public getPurchaseReceiptsCount(store_id){
    return  this.http.post(`${API_URL}purchase-receipts-count/`, {"store_id":store_id});
  }
  public getPurchaseReturnsCount(store_id){
    return  this.http.post(`${API_URL}purchase-returns-count/`, {"store_id":store_id});
  }
  public getTodaysPurchaseReceiptsCount(store_id){
    return  this.http.get(`${API_URL}todays-purchases/${store_id}/`);
  }
  public getPurchaseReceipts(event, store_id, to?, from?, order_by?, query?){
    return  this.http.post(`${API_URL}get-purchase-receipts/`, {"event":event, "store_id":store_id, "to":to, "from":from, "order_by":order_by, "query": query});
  }
  public getPurchaseReturns(event, store_id, to?, from?, order_by?, query?){
    return  this.http.post(`${API_URL}get-purchase-returns/`, {"event":event, "store_id":store_id, "to":to, "from":from, "order_by":order_by, "query": query});
  }
  public getPurchaseReceiptsColumns() {
    return this.http.get(`${environment.apiUrl}purchase-receipts-columns/`);
  }
  public getPurchaseReturnsColumns() {
    return this.http.get(`${environment.apiUrl}purchase-returns-columns/`);
  }
  public setPurchaseReceiptsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-purchase-receipts-columns/`, {"item_id":item_id, "state":state});
  }
  public setPurchaseReturnsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-purchase-returns-columns/`, {"item_id":item_id, "state":state});
  }
  
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////DJANGO SERVICES NOT DEFINED/////////////////////////
  /////////////////////////////////////////////////////////////////////////////////// 
  public getPurchaseReceiptsToday(date, store_id, event){
    return this.http.post(`${API_URL}get-purchase-receipts-today/${store_id}/`, {"date":date, "event":event});
  }
  public getPurchaseReceiptsCountToday(date, store_id){
    return this.http.post(`${API_URL}todays-purchases/${store_id}/`, {"date":date});
  }
  public getPurchaseReturnsCountToday(date, store_id){
    return this.http.post(`${API_URL}todays-purchase-returns-count/${store_id}/`, {"date":date});
  }
  public getPurchaseReturnsToday(date, store_id, event){
    return this.http.post(`${API_URL}todays-purchase-returns/${store_id}/`, {"date":date});
  }
  ///////////////////////////////////////////////////////////////////////////////////
  public getReport(date, store_id){
    return this.http.post(`${API_URL}report/${store_id}/`, {"date":date});
  }
  public correctMargins(){
    return this.http.get(`${API_URL}correct-purchase-receipts-margin/`);
  }
  public resend(content){
    return this.http.post(`${API_URL}resend-email/`, content)
  }
}