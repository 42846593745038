import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CompaniesService } from '../companies/companies.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { StoresService } from '../stores/stores.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  public id: string;
  submitted = false;
  myform: FormGroup;
  company_id: FormControl;
  company_name: FormControl;
  store_id: FormControl;
  stores;
  model;
  constructor(private route: ActivatedRoute, private companiesService: CompaniesService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toaster: ToastrService,
    public storesService: StoresService) {
      window.scrollTo(0,0); }

  ngOnInit() {
    
    this.model = {};
    this.id = this.route.snapshot.paramMap.get('id');
    this.createFormControls();
    this.createForm();
    if (this.id != null) {
      
    this.company_id.setValue(this.id);
      this.companiesService.getCompany(this.id).subscribe((data: Array<object>) => {
        this.model = data[0];
      });
    }
    this.storesService.getAllStores().subscribe(data=>{
      this.stores=data;
    })
  }

  createFormControls() {
    this.company_id = new FormControl('');
    this.company_name = new FormControl('', Validators.required);
    this.store_id = new FormControl('', Validators.required);
  }

  createForm() {
    this.myform = new FormGroup({
      company_id: this.company_id,
      company_name: this.company_name,
      store_id: this.store_id
    });
  }
  submit = function () {
    this.submitted = true;
    if (this.myform.status === "VALID") {
      this.confirmationDialogService.confirm('Submit?', `Are you sure to submit?`).then((confirmed) => {
        if (confirmed) {
          if (this.id != null) {
            this.companiesService.updateCompany(this.id, this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/companies']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              })
          }
          else {
            this.companiesService.addCompany(this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/companies']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              })
          }
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }
}
