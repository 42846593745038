﻿import { Routes, RouterModule, CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { RawProductsComponent } from './raw-products/raw-products.component';
import { EmployeesComponent } from './employees/employees.component';
import { StoresComponent } from './stores/stores.component';
import { CompaniesComponent } from "./companies/companies.component";
import { PromotionsComponent } from './promotions/promotions.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { AddStoreComponent } from './add-store/add-store.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import { AddPromotionComponent } from './add-promotion/add-promotion.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ProductCSVUploadComponent } from './product-csv-upload/product-csv-upload.component';
import { EditProductComponent } from './edit-raw-product/edit-raw-product.component';
import { RolesComponent } from './roles/roles.component';
import { CustomerCSVUploadComponent } from './customer-csv-upload/customer-csv-upload.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { NominalAccountsComponent } from './nominal-accounts/nominal-accounts.component';
import { AddNominalAccountsComponent } from './add-nominal-accounts/add-nominal-accounts.component';
import { PurchaseReceiptComponent } from './purchase-receipt/purchase-receipt.component';
import { QuotesComponent } from './quote/quote.component';
import { EditQuotesComponent } from './edit-quote/edit-quote.component';

import { QuotationsComponent } from './quotations/quotations.component';
import { NominalAccountCSVUploadComponent } from './nominal-csv-upload/nominal-csv-upload.component';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RolesService } from './roles/roles.service';
import { StockComponent } from './stock/products/products.component';

import { UserService, AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { NotFoundComponent } from './not-found/not-found.component';
import { PurchasePaymentComponent } from './purchase-payment/purchase-payment.component';
import { MakePurchasePaymentComponent } from './make-purchase-payment/make-purchase-payment.component';
import { NominalTransferComponent } from './nominal-transfer/nominal-transfer.component';
import { DeliveryNoteComponent } from './delivery-note/delivery-note.component';
import { DeliveryNotesComponent } from './delivery-notes/delivery-notes.component';
import { DeliveryReceiptComponent } from './delivery-receipt/delivery-receipt.component';
import { PickingReceiptComponent } from './picking-receipt/picking-receipt.component';
import { ViewProductTransactionsComponent } from './view-product-transactions/view-product-transactions.component';
import { EmailCredsComponent } from './email-creds/email-creds.component';


@Injectable({ providedIn: 'root' })
export class DirectAccessGuard implements CanActivate {
    currentUserSubscription: Subscription;
    currentUser: any;
    store_id; roles;
    constructor(private router: Router,
        private rolesService: RolesService,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        public local: LocalStorageService, ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user ? user[0] : "";
            if (this.currentUser.user_type != 'admin') {
                this.store_id = this.currentUser.store_id;
                this.local.set("store_id", this.store_id, 0, 's');
            }
            else {
                this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
            }

        });
        if(this.currentUser){
        this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().then(data => {
            this.roles = data;
        });
    }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if(this.currentUser){
        this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().
            then(data => {
                this.roles = data;
            }).finally(() => {
                if (this.roles[route.data.route_path] == 1) {
                    this.router.navigateByUrl(state.url);
                    return true;
                }
                else {
                    console.log(this.roles);
                    // this.local.set("access_allowed", false);
                    this.router.navigateByUrl('not-found');
                    return false;
                }
            }).catch(() => {
                console.log("error");
            });
        return true;
        }
    }
}
const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Home', route_path: 'dashboard'} },
    // { path: '/', component: HomeComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Home', route_path: 'dashboard'} },
    { path: 'login', component: LoginComponent },
    { path: 'not-found', component: NotFoundComponent },
    { path: 'register', component: RegisterComponent },

    // otherwise redirect to home
    { path: 'suppliers', loadChildren: 'src/app/suppliers/suppliers.module#SuppliersModule', canActivate: [DirectAccessGuard], data: { breadcrumb: 'Suppliers', route_path: 'suppliers'} },
    { path: 'customers',loadChildren: 'src/app/customers/customers.module#CustomersModule', canActivate: [DirectAccessGuard], data: { breadcrumb: 'Customers', route_path: 'customers'} },
    { path: 'view',loadChildren: 'src/app/view/view.module#ViewModule', data: { breadcrumb: 'View'} },

    { path: 'products',loadChildren: 'src/app/products/products.module#ProductsModule', data: { breadcrumb: 'Products'} },
    { path: 'products/add', component: AddProductComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Products > Add', route_path: 'products/add'}},
    { path: 'products/add/:id', component: AddProductComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Products > Add', route_path: 'products/add/id'}},
    { path: 'products/edit/:id', component: AddProductComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Products > Edit', route_path: 'products/add/id'} },
    { path: 'employees', component: EmployeesComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Employees', route_path: 'employees'}  },
    // { path: 'employees/add', component: AddEmployeeComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Employees > Add', route_path: '"employees/add'}  },
    { path: 'employees/add', component: AddEmployeeComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Employees > Add', route_path: 'employees/add' } },
    { path: 'employees/edit/:id', component: AddEmployeeComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Employees > Edit', route_path: 'employees/edit/id'}  },

    { path: 'companies', component: CompaniesComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Companies', route_path: 'companies'} },
    { path: 'companies/add', component: AddCompanyComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Comapanies > Add', route_path: 'companies/add'} },
    { path: 'companies/edit/:id', component: AddCompanyComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Comapanies > Add', route_path: 'companies/edit/id'} },

    { path: 'stores', component: StoresComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Stores', route_path: 'stores'} },
    { path: 'stores/add', component: AddStoreComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Stores > Add', route_path: 'stores/add'}},
    { path: 'stores/edit/:id', component: AddStoreComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Stores > Edit', route_path: 'stores/edit/id'} },

    { path: 'stock/products',loadChildren: 'src/app/stock/products.module#StockProductsModule', data: { breadcrumb: 'StockProducts'} },
    // { path: 'products/add', component: AddProductComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Products > Add', route_path: 'stock/products/add'}},
    // { path: 'products/add/:id', component: AddProductComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Products > Add', route_path: 'stock/products/add/id'}},

    { path: 'promotions', component: PromotionsComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Promotions', route_path: 'promotions'}  },
    { path: 'promotions/add', component: AddPromotionComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Promotions > Add', route_path: 'promotions/add'}  },
    { path: 'promotions/edit/:id', component: AddPromotionComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Promotions > Add', route_path: 'promotions/edit/id'}  },

    { path: 'delivery-note/:transaction_id', component: DeliveryNoteComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Sales > Delivery Note', route_path: 'sale-transactions'} },
    { path: 'delivery-notes', component: DeliveryNotesComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Delivery Notes', route_path: 'sale-transactions'} },
    { path: 'delivery-note/view/:dn_id', component: DeliveryReceiptComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Delivery Notes', route_path: 'sale-transactions'} },
    //{ path: 'delivery-note/view/:dn_id', component: PickingReceiptComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Delivery Receipt', route_path: 'sale-transactions'} },


    { path: 'product/transaction/:id', component:ViewProductTransactionsComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Sales > Invoices ', route_path: 'sale-transactions'} },
    { path: 'sales', loadChildren: 'src/app/sales/sales.module#SalesModule', canActivate: [DirectAccessGuard],data: { breadcrumb: 'Sales', route_path: 'sales'} },
    { path: 'sales/:sale_type', loadChildren: 'src/app/sales/sales.module#SalesModule', canActivate: [DirectAccessGuard],data: { breadcrumb: 'Sales', route_path: 'sales'}},
    { path: 'purchases', loadChildren: 'src/app/purchases/purchases.module#PurchasesModule', canActivate: [DirectAccessGuard], data: { breadcrumb: 'Purchases', route_path: 'purchases'}},
    { path: 'purchases/:purchase_type', loadChildren: 'src/app/purchases/purchases.module#PurchasesModule', canActivate: [DirectAccessGuard], data: { breadcrumb: 'Purchases', route_path: 'purchases'}},
    { path: 'nominal/csv', component: NominalAccountCSVUploadComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Nominal > Add', route_path: 'nominal/add'}},
    { path: 'products/csv-import', component: ProductCSVUploadComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Products > Add', route_path: 'products/add'} },
    { path: 'products/add-stock', component: ProductCSVUploadComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Products > Add', route_path: 'products/add'} },
    { path: 'products/csv-export', component: ProductCSVUploadComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Products > Add', route_path: 'products/add'} },
    { path: 'customers/csv-import', component: CustomerCSVUploadComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Customers > Add', route_path: 'customers/add'}},
    { path: 'customers/csv-export', component: CustomerCSVUploadComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Customers > Add', route_path: 'customers/add'}},
    { path: 'raw-products', component: RawProductsComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Raw Products', route_path: 'raw-products'}},
    { path: 'raw-products/edit/:id', component: EditProductComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Raw Products > Add', route_path: 'raw-products/edit/id'}},
    { path: 'roles', component: RolesComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Roles', route_path: 'roles'}},
    { path: 'receipt', component: ReceiptComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Sales', route_path: 'sales'}},
    { path: 'picking-receipt', component: PickingReceiptComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Picking Receipt', route_path: 'sale-transactions'}},
    { path: 'delivery-receipt', component: DeliveryReceiptComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Delivery Receipt', route_path: 'sale-transactions'}},
    { path: 'purchase-receipt', component: PurchaseReceiptComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Purchases', route_path: 'purchases'}},
    { path: 'reporting', component: ReportingComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Reporting', route_path: 'reporting'}},
    { path: 'product-images', component: ProductImagesComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Products Images', route_path: 'product-images'}},
    { path: 'nominal', component: NominalAccountsComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Nominal', route_path: 'nominal'}},
    { path: 'nominal/add', component: AddNominalAccountsComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Nominal > Add', route_path: 'nominal/add'}},
    { path: 'nominal/edit/:id', component: AddNominalAccountsComponent, canActivate: [DirectAccessGuard],data: { breadcrumb: 'Nominal > Edit', route_path: 'nominal/edit/id'}},

    { path: 'quotes', component: QuotesComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Quotes', route_path: 'quotes'}},
    { path: 'edit-quotes', component: EditQuotesComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Edit Quotes', route_path: 'sale-transactions'}},
    { path: 'quotations', component: QuotationsComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Quotations', route_path: 'sale-transactions'} },
    { path: 'purchase-payment', component: PurchasePaymentComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Purchase Payments', route_path: 'purchase-payment'}},
    { path: 'purchase-payment/:id', component: MakePurchasePaymentComponent, canActivate: [DirectAccessGuard], data: { breadcrumb: 'Purchase Payments', route_path: 'purchase-payment'}},
    { path: 'nominal-transfer', component: NominalTransferComponent, canActivate: [DirectAccessGuard],  data: { breadcrumb: 'Nominal Transfer', route_path: 'nominal-transfer'}},
    { path: 'email-creds', component: EmailCredsComponent, },
];

export const routing = RouterModule.forRoot(appRoutes);
