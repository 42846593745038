import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor( private http: HttpClient) { }
  public getAllStores(){
    return  this.http.get(`${API_URL}stores/`);
  }
  public getStore(id){
    return  this.http.get(`${API_URL}store/${id}/`);
  }
  public updateStore(id, store_object){
    return this.http.post(`${API_URL}update-store/${id}/`,store_object);
  }
  public addStore(store_object){
    return this.http.post(`${API_URL}add-store/`,store_object);
  }
  public getStoresCount(){
    return  this.http.get(`${API_URL}stores-count/`);
  }
  public getStores(event){
    return  this.http.post(`${API_URL}get-stores/`, event);
  }
  public getStoresColumns() {
    return this.http.get(`${environment.apiUrl}stores-columns/`);
  }
  public setStoresColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-stores-columns/`, {"item_id":item_id, "state":state});
  }
}
