import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponentNew } from './confirmation-dialog-new.component';

@Injectable()
export class ConfirmationDialogServiceNew {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    btnProceedText: string = 'Proceed',
    btnCancelText: string = 'Cancel',
    btnPaymentText: string = 'A/C Payments',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponentNew, { backdrop: 'static', keyboard: false, windowClass: 'dialog-new-class' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnProceedText = btnProceedText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.btnPaymentText = btnPaymentText;

    return modalRef.result;
  }

}
