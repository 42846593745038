// [search.Service.ts]
import { Injectable } from '@angular/core';
import { Observable,  } from 'rxjs';
import { filter } from 'rxjs/operator/filter';
import { debounceTime } from 'rxjs/operator/debounceTime';
import { distinctUntilChanged } from 'rxjs/operator/distinctUntilChanged';
import { map } from 'rxjs/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
//, this.local.get("store_id")
export class SearchService {
  baseUrl: string = `${API_URL}search-product/`;
  constructor(private _http: HttpClient) { }
  search(queryString: string, id, paginator?) {
    let _URL = this.baseUrl 
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchProduct(queryString: string, id, in_stock_filter?, paginator?) {
    let _URL = `${API_URL}search-product-sales/`;
    return this._http.post(_URL, {q:queryString, id:id, in_stock_filter: in_stock_filter, paginator: paginator});
  }
  searchProductPurchase(queryString: string, id, in_stock_filter?, paginator?) {
    let _URL = `${API_URL}search-product-purchase/`;
    return this._http.post(_URL, {q:queryString, id:id, in_stock_filter: in_stock_filter, paginator: paginator});
  }
  searchNominalTransactions(queryString: string, id, in_stock_filter?, paginator?) {
    let _URL = `${API_URL}search-nominal-transactions/`;
    return this._http.post(_URL, {q:queryString, id:id, in_stock_filter: in_stock_filter, paginator: paginator});
  }
  
  searchCustomer(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-customer/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchCustomerSales(queryString: string, id) {
    let _URL = `${API_URL}search-customer-sales/`;
    return this._http.post(_URL, {q:queryString, id:id});
  }
  searchPromotion(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-promotion/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchTransaction(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-transaction/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchTransactionSales(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-transaction-sales/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchReceipt(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-receipt/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchPurchase(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-purchase/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchPurchaseReceipt(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-purchase-receipt/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchReturn(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-return/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchStore(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-store/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchSupplier(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-supplier/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchSupplierPayments(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-supplier-payments/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchEmployee(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-employee/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchPurchaseReturn(queryString: string, id, paginator?) {
    let _URL = `${API_URL}search-purchase-return/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchRawProduct(queryString: string, id, paginator?){
    let _URL = `${API_URL}search-raw-product/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchCompany(queryString: string, id, paginator?){
    let _URL = `${API_URL}search-company/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchNominalAccount(queryString: string, id, paginator?){
    let _URL = `${API_URL}search-nominal-account/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
  searchQuotation(queryString: string, id, paginator?){
    let _URL = `${API_URL}search-quotation/`;
    return this._http.post(_URL, {q:queryString, id:id, paginator: paginator});
  }
}