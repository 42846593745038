import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionsService } from '../promotions/promotions.service';
import { ProductsService } from '../products/products.service';
import { SearchService } from '../_services/search/search.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { MatSelect, } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { tap, filter, } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-promotion',
  templateUrl: './add-promotion.component.html',
  styleUrls: ['./add-promotion.component.scss']
})
export class AddPromotionComponent implements OnInit, OnDestroy {
  valid = false;
  submitted = false;
  protected products: Array<any> = new Array<any>();

  /** control for the selected product for multi-selection */
  public productMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public productMultiFilterCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of products filtered by search keyword */
  public filteredProductsMulti: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  dropdownList;
  results;
  lengthVar;
  queryField: FormControl = new FormControl();
  public id: string;
  model;
  currentUserSubscription; 
  currentUser;
  store_id;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor(private route: ActivatedRoute, private promotionsService: PromotionsService, private router: Router,
    private _searchService: SearchService, private productsService: ProductsService,
    private confirmationDialogService: ConfirmationDialogService, private authenticationService: AuthenticationService,
    private toaster: ToastrService, private local: LocalStorageService) {
      window.scrollTo(0,0);
      this.model = { "start_date": "", "end_date": "", "discount": "", "comments": "" };
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user[0];
        if (this.currentUser.user_type != 'admin') {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, 's');
        }
        else {
          this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
        }

  
      });
       
     }


  ngOnInit() {
    this.model = { "start_date": "", "end_date": "", "discount": "", "comments": "" };
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id != null) {
      this.promotionsService.getPromotion(this.id).subscribe((data: Array<object>) => {
        this.model = data[0];
        this.productsService.getProduct(this.model["product_id"], this.store_id).toPromise().then(data=>{
          this.productMultiCtrl.setValue(data);
        })
      });
      this.productMultiCtrl.valueChanges.subscribe(value =>{
         this.products.push(value[0]);
      });
      //
    }
    else {
      this.model = { "start_date": "", "end_date": "", "discount": "", "comments": "" };
    }
    // set initial selection
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.search(search, this.store_id)))
      .subscribe(filteredProducts => {
        this.searching = false;
        this.filteredProductsMulti.next(filteredProducts);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  submit = function () {
    if (this.currentUser.user_type != 'admin') {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, 's');
    }
    else {
      this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    }
    this.model["store_id"] = this.store_id;
    this.submitted = true;
    if (this.productMultiCtrl.value != null && this.model.start_date != "" && this.model.end_date != "" && this.model.discount != "") {
      this.valid = true;
    }
    else {
      this.valid = false;
    }
    if (this.valid) {
      this.confirmationDialogService.confirm('Submit?', `Are you sure to submit?`).then((confirmed) => {
        if (confirmed) {
          if (!("comments" in this.model)) {
            this.model["comments"] = "       ";
          }
          if (this.id != null) {
            this.promotionsService.updatePromotion(this.id, this.model).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/promotions']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              });
          }
          else {
            let p_p = this.productMultiCtrl.value;
            p_p.forEach(element => {
              this.model["product_id"] = element.product_id;
              this.promotionsService.addPromotion(this.model, this.store_id).subscribe((data) => {
                this.toaster.success('Added successfully!', 'Success', {
                  timeOut: 3000
                });
                this.router.navigate(['/promotions']);
              },
                error => {
                  this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                    timeOut: 3000
                  });
                });
            })
          }
        }
      })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      console.log("invalid");

    }
  }
}
