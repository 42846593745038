﻿
import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterContentChecked, ElementRef, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';
import { AlertService, AuthenticationService } from '@app/_services';

import { StoresService } from '../stores/stores.service';
import { EmployeesService } from '../employees/employees.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    store_id;

    constructor(
        public local: LocalStorageService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private toasterService: ToastrService,
    ) {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue != null) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        console.log(this.f.username.value);
        console.log(this.f.password.value);
        // this.http.post(`${environment.apiUrl}login/`, { username, password })
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(data.length>0){
                        this.openStoreSelectDialog(data[0]);
                    }
                    else{
                        this.f.username.setValue('')
                        this.f.password.setValue('') 
                        this.toasterService.error('Username or Password is incorrect', 'Authentication Error', {
                            timeOut: 1000
                        });
                    }
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    openStoreSelectDialog = function (data): void {
        const dialogRef = this.dialog.open(AdminStoreSelectDialog, {
            closeOnNavigation: false,
            disableClose: true,
            height: 'fit-content',
            width: 'fit-content',
            borderRadius: '10px',
            backgroundColor: 'none',
            data: { data }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.local.set("store_id", result, 0, 's');
            let user = JSON.parse(localStorage.getItem('currentUser'));
            user[0]["store_id"] = result;
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.store_id = result;
            window.location.reload();
            return result;
        });
    }
   
}

@Component({
    selector: 'admin-store-select',
    templateUrl: 'admin-store-select-dialog.html',
})
export class AdminStoreSelectDialog {
    predefined = false; model; id; submitted = false;
    stores;
    selectedStore;
    constructor(
        public dialogRef: MatDialogRef<AdminStoreSelectDialog>,
        private usersService: EmployeesService,
        private confirmationDialogService: ConfirmationDialogService,
        private toasterService: ToastrService,
        private storesService: StoresService,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.model = data["data"];
        this.storesService.getAllStores().toPromise().then(data => {
            this.stores = data;
        })
    }
    setUserStore(store_id) {
        this.selectedStore = store_id;
        this.usersService.updateUserStore(this.model["user_id"], store_id).subscribe(data => {
            this.onNoClick();
        })
    }
    onNoClick(): void {
        this.dialogRef.close(this.selectedStore);
    }
}