import { Component, OnInit, ViewChild, Output, EventEmitter, Inject, AfterViewInit, HostListener } from '@angular/core';
import { SuppliersService } from '../suppliers/suppliers.service';
import { MatTableDataSource, PageEvent, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '@app/_services';
import { Location, DecimalPipe } from '@angular/common';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PurchaseTransactionDialog } from '../purchases/purchase-transactions/purchase-transactions.component';
import { ConfirmationDialogService } from '@app/confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from '@app/roles/roles.service';
import { ToastrService } from 'ngx-toastr';
import { NominalAccountsService } from '@app/nominal-accounts/nominal-accounts.service';
import { PurchasesService } from '@app/purchases/purchases.service';
interface SuppliersStatementElement {
  transaction_id,
  transaction_date,     // 0
  store_name,
  debit,
  credit,
  supplier_ref
}

@Component({
  selector: 'app-make-purchase-payment',
  templateUrl: './make-purchase-payment.component.html',
  styleUrls: ['./make-purchase-payment.component.css'],
  providers: [DatePipe]
})
export class MakePurchasePaymentComponent implements OnInit {
  isSticky: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    this.isSticky = window.pageYOffset >= 150;
    if (pos > max - 250) {
      this.isSticky = false;
    }
  }
  predefined = false; model; id; all_checked = false;
  month; submitted = false; errorInPhone = false; purchase_transactions; returns; from; to; order_by; datetime;
  selectedColumns; store; comments; discount; net_total; vat_amount; total_after_VAT; payment; four_months; three_months; two_months; one_months; current;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  loaded = false;
  //////////////////////////////////
  displayedColumns: string[] = [
  ];
  ELEMENT_DATA: SuppliersStatementElement[] = [];
  dataSource = new MatTableDataSource<SuppliersStatementElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<SuppliersStatementElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  length;
  currentUserSubscription;
  currentUser;
  store_id; supplier_statement;
  pageSize = 10; supplier; transaction;
  pageIndex; pageEvent: PageEvent;
  statement_type: string = "Pending";
  statement_types: string[] = ['Pending'];
  roles;
  queryField: FormControl = new FormControl();
  selectedAccounts; return_;
  selected_transactions = []; checked = [];
  total = 0;
  nominal_accounts;
  nominal_accounts_net;
  selectedNominalAccounts;
  payment_nominal_account;
  net_nominal_account = '5000';
  payment_type: string;
  selected_cns = []; selected_month = "all";
  selected_purchases = []; max_date; min_date; months;


  purchase_date;
  constructor(
    private location: Location,
    private searchService: SearchService,
    private nominalService: NominalAccountsService,
    private confirmationDialogService: ConfirmationDialogService,
    private authenticationService: AuthenticationService,
    private supplierService: SuppliersService, private local: LocalStorageService,
    private purchasesService: PurchasesService,
    private toasterService: ToastrService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialog: MatDialog, private router: Router,
    private rolesService: RolesService) {
    window.scrollTo(0, 0);
    this.id = this.route.snapshot.paramMap.get('id');
    this.month = this.route.snapshot.paramMap.get('month');
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }
      if (this.month == null || this.month == undefined) {
        this.supplierService.getSupplierStatements({
          "supplier_id": this.id, "store_id": this.local.get("store_id"),
          "statement_type": (this.statement_type == "Pending") ? "Brief" : this.statement_type
        }).toPromise().then((data: Array<SuppliersStatementElement>) => {
          this.purchase_transactions = data["purchase_transactions"];
          this.returns = data["returns"];
          this.months = data["months"];
          this.store = data["store"][0];
          this.supplier = data["supplier"][0];
          this.four_months = data["four_months"][0];
          this.three_months = data["three_months"][0];
          this.two_months = data["two_months"][0];
          this.one_months = data["one_months"][0];
          // this.current = data["current"][0];
          // this.ELEMENT_DATA = data;
          // this.dataSource = new MatTableDataSource<SuppliersStatementElement>(this.ELEMENT_DATA);
          // this.dataSource.paginator = this.paginator;
        });
      }
      else {

        this.supplierService.getSupplierStatements({
          "supplier_id": this.id,
          "store_id": this.local.get("store_id"), "statement_type": (this.statement_type == "Pending") ? "Brief" : this.statement_type,
          "month": this.month
        }).toPromise().then((data: Array<SuppliersStatementElement>) => {
          this.purchase_transactions = data["purchase_transactions"];
          this.returns = data["returns"];
          this.months = data["months"];
          this.store = data["store"][0];
          this.supplier = data["supplier"][0];
          this.four_months = data["four_months"][0];
          this.three_months = data["three_months"][0];
          this.two_months = data["two_months"][0];
          this.one_months = data["one_months"][0];
          // this.current = data["current"][0];
          // this.ELEMENT_DATA = data;
          // this.dataSource = new MatTableDataSource<SuppliersStatementElement>(this.ELEMENT_DATA);
          // this.dataSource.paginator = this.paginator;
        });
      }

    });

    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().
      then(data => {
        this.roles = data;
      });
    this.supplierService.getSupplierStatementsColumns().toPromise().then(data => {
      let i = 0;
      for (const key in data) {
        i++;
        this.dropdownList.push({ item_id: data[key]['column_name'], item_text: this.getColumnName(data[key]['column_name']) });
        if (data[key]['show'] == 1) {
          this.selectedItems.push({ item_id: data[key]['column_name'], item_text: data[key]['column_name'] });
          this.displayedColumns.push(data[key]['column_name']);
        }
      }
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        itemsShowLimit: 5,
        enableCheckAll: false,
        enableSelectAll: false,
      };
      this.loaded = true;
    });
    this.nominalService.getAllNominalAccountsNet().toPromise().then(data => {
      this.nominal_accounts_net = data;
      this.selectedNominalAccounts = this.nominal_accounts_net;
    });
    this.payment_type = "cash";
    this.payment_nominal_account = "780";
    this.purchase_date = this.CheckDate();
    this.nominalService.getAllPurchasesNominalAccounts().toPromise().then(data => {
      this.nominal_accounts = data;
      this.selectedAccounts = this.nominal_accounts;
    });
    this.nominalService.getAllNominalAccountsNet().toPromise().then(data => {
      this.nominal_accounts_net = data;
      this.selectedNominalAccounts = this.nominal_accounts_net;
    });

  }
  CheckDate() {
        var date = new Date();
        var currMonth;
        var currDate;
        var dm = date.getMonth() + 1;
        if(dm<10){
          currMonth = "0" + dm;
        } else if (dm >= 10) {
          currMonth = dm;
        }
        var dd = date.getDate()
        if(dd<10){
          currDate = "0" + dd;
        } else if (dd >= 10) {
          currDate = dd;
        }
        var nddate = date.getFullYear()+ "-" + currMonth + "-" + currDate ;
        console.log(nddate);
        // document.getElementById("paymentDate").setAttribute('value', nddate);
        return nddate;
}
  getStatementMonth() {
    this.supplierService.getSupplierStatements({
      "supplier_id": this.id,
      "store_id": this.local.get("store_id"),
      "statement_type": "monthly",
      "selected_month": this.selected_month

    }).toPromise().then((data: Array<SuppliersStatementElement>) => {
      this.purchase_transactions = data["purchase_transactions"];
      this.returns = data["returns"];
      this.months = data["months"];
      this.store = data["store"][0];
      this.supplier = data["supplier"][0];
      this.four_months = data["four_months"][0];
      this.three_months = data["three_months"][0];
      this.two_months = data["two_months"][0];
      this.one_months = data["one_months"][0];
      for (var i = 0; i < this.purchase_transactions.length; i++) {
        this.selected_purchases.push(this.purchase_transactions[i]["transaction_id"]);
        this.total_after_VAT = this.total_after_VAT + this.purchase_transactions[i]["outstanding"];
      }
      this.total = 0;
      this.payment = 0;
      for (let i = 0; i < this.purchase_transactions.length; i++) {
        if (this.purchase_transactions[i].checked && (this.purchase_transactions[i].transaction_type == "Invoice")) {
          this.total = this.total + this.purchase_transactions[i]["payable"];
          this.payment = this.total;
          this.selected_purchases.push(this.purchase_transactions[i].transaction_id);
        }
      }
    });
  }
  date_range() {
    this.supplierService.getSupplierStatements({
      "supplier_id": this.id,
      "store_id": this.local.get("store_id"),
      "statement_type": this.statement_type,
      "min_date": this.from,
      "max_date": this.to

    }).toPromise().then((data: Array<SuppliersStatementElement>) => {
      this.purchase_transactions = data["purchase_transactions"];
      this.returns = data["returns"];
      this.months = data["months"];
      this.store = data["store"][0];
      this.supplier = data["supplier"][0];
      this.four_months = data["four_months"][0];
      this.three_months = data["three_months"][0];
      this.two_months = data["two_months"][0];
      this.one_months = data["one_months"][0];
    });
  }
  onNominal(value) {
    this.selectedNominalAccounts = this.searchNominal(value);
  }
  searchNominal(value: string) {
    let filter = value.toLowerCase();
    return this.nominal_accounts_net.filter(option => option.code_number.toLowerCase().includes(filter) || option.description.toLowerCase().includes(filter));
  }
  set_payment = function (paymentType) {
    this.payment_type = paymentType.description ? paymentType.description : paymentType;
    this.payment_nominal_account = paymentType.code_number ? paymentType.code_number : { 'cash': '780', 'card': '782' };
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


  }
  checkAllPurchaseInvoices(event) {
    if (event.checked) {
      this.selected_purchases = []
      for (let i = 0; i < this.purchase_transactions.length; i++) {
        this.purchase_transactions[i].checked = true;
        this.selected_purchases.push(this.purchase_transactions[i].transaction_id);
      }
    }
    else {
      this.all_checked = false;
      this.selected_purchases = [];
      for (let i = 0; i < this.purchase_transactions.length; i++) {
        this.purchase_transactions[i].checked = false;
      }
    }
    this.total = 0;
    this.payment = 0;
    for (let i = 0; i < this.purchase_transactions.length; i++) {
      if (this.purchase_transactions[i].checked) {
        this.total = this.total + this.purchase_transactions[i]["payable"];
        this.payment = this.total;
      }
    }
  }
  //clearInvoice(checking[i], i, purchase_transaction.transaction_id, purchase_transaction.payable)
  // clearInvoice(value, index, transaction_id, outstanding) {
  //   console.log("Testing")
  //   console.log(value)
  //   console.log(index)
  //   console.log(transaction_id)
  //   console.log(outstanding)
  //   if (value == true) {
  //     this.selected_purchases.push(transaction_id);
  //     this.total_after_VAT = this.total_after_VAT + outstanding;
  //   }
  //   else {
  //     this.all_checked = false;
  //     this.total_after_VAT = this.total_after_VAT - outstanding;
  //     // this.selected_transactions.splice(this.selected_transactions.indexOf(transaction_id), 1);
  //     this.selected_cns.splice(this.purchase_transactions.indexOf(transaction_id), 1);
  //     this.selected_purchases.splice(this.purchase_transactions.indexOf(transaction_id), 1);
  //   }
  //   this.total = 0;
  //   for (let i = 0; i < this.purchase_transactions.length; i++) {
  //     if (this.purchase_transactions[i].checked && (this.purchase_transactions[i].transaction_type == "Invoice")) {
  //       this.total = this.total + this.purchase_transactions[i]["payable"];
  //       this.selected_purchases.push(this.purchase_transactions[i].transaction_id);
  //     }
  //   }
  //   console.log("this total after vat")
  //   console.log(this.total_after_VAT)
  //   console.log("this total")
  //   console.log(this.total)
  //   console.log('this.selected_cns')
  //   console.log(this.selected_cns)
  //   console.log(this.selected_purchases)
  // }
  clearInvoice(object) {
    console.log(object);
    this.selected_purchases = [];
    this.total = 0;
    this.payment = 0;
    for (let i = 0; i < this.purchase_transactions.length; i++) {
      if (this.purchase_transactions[i].checked) {
        this.selected_purchases.push(this.purchase_transactions[i].transaction_id);
        this.total = this.total + this.purchase_transactions[i]["payable"];
        this.payment = this.total;
      }
    }
    console.log(this.selected_purchases)
    console.log(this.total)
  }
  goBack() {
    this.location.back();
  }
  statementTypeChange() {
    this.supplierService.getSupplierStatements({
      "supplier_id": this.id,
      "store_id": this.local.get("store_id"),
      "statement_type": (this.statement_type == "Pending") ? "Brief" : this.statement_type
    }).toPromise().then((data: Array<SuppliersStatementElement>) => {
      this.purchase_transactions = data["purchase_transactions"];
      this.returns = data["returns"];
      this.months = data["months"];
      this.store = data["store"][0];
      this.supplier = data["supplier"][0];
      this.four_months = data["four_months"][0];
      this.three_months = data["three_months"][0];
      this.two_months = data["two_months"][0];
      this.one_months = data["one_months"][0];
      // this.current = data["current"][0];\
      // this.ELEMENT_DATA = data;
      // this.dataSource = new MatTableDataSource<SuppliersStatementElement>(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });

  }
  ngOnInit() {

    this.datetime = Date.now();
    // this.CheckDate();
    // console.log(document.getElementById('paymentDate').getAttribute('value'));
  }
  // addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  //   (type == "from") ? this.from = new Date(event.value) : (type == "to") ? this.to = new Date(event.value) : "";
  // }
  getColumnName(inp): string {
    if (inp == "store_name") return "Store";
    if (inp == "transaction_date") return "Date";
    if (inp == "transaction_id") return "PurchaseTransaction"; ///////////
    if (inp == "supplier_ref") return "Reference";
    if (inp == "debit") return "Debit";
    if (inp == "credit") return "Credit";
  }
  onItemSelect(item: any) {
    this.supplierService.setSupplierStatementsColumnDisplay(item['item_id'], true).subscribe(data => { });
    let itemIndex;
    this.dropdownList.forEach((myObject, index) => {
      if (myObject["item_id"] == item["item_id"]) {
        itemIndex = index + 1;
        this.displayedColumns.splice(itemIndex, 0, item['item_id']);
      }
    });

  }
  onItemDeSelect(item: any) {
    this.supplierService.setSupplierStatementsColumnDisplay(item['item_id'], false).subscribe(data => { });
    this.displayedColumns.splice(this.displayedColumns.indexOf(item["item_id"]), 1)
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  toggleDD() {
    if (this.showdd1) {
      this.showdd1 = false;
    }
    else {
      this.showdd1 = true;
    }
  }

  getPurchaseTransaction(id) {
    this.supplierService.getPurchaseTransaction(id).subscribe(data => {
      const dialogRef = this.dialog.open(PurchaseTransactionDialog, {
        height: '100%',
        width: '80%',
        data: { "id": id, "transaction": data }
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    })
  }
  getReturn(id) {
    let url = "/view/purchase-returns/" + id;
    this.router.navigate([url]);
  }
  submitPurchase() {
    console.log("Hello");
    console.log(this.purchase_date);
    var date = new Date();
    var today =  date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    console.log(today);
    console.log(typeof today);
    if (!this.supplier) {
      this.toasterService.error("Please make sure to select a supplier.", "Supplier not entered!", {
        timeOut: 5000
      });
    }
    if (!this.payment) {
      this.toasterService.error("Please make sure to enter a payment amount.", "No payment entered!", {
        timeOut: 5000
      });
    }
    if (this.supplier && this.payment) {
      this.store_id = this.local.get("store_id");
      let data = {
        "supplier": this.supplier,
        "type": this.payment_type,
        "payment": this.payment,
        "payment_nominal_account": this.payment_nominal_account,
        "store_id": this.store_id, "user": this.currentUser,
        "supplier_ref": null, "payment_mode": "receipt", "credit_notes": this.selected_cns,
        "purchases": this.selected_purchases,
        "purchase_date": this.CheckDate()
      };

      this.confirmationDialogService.confirm('Complete Payment?', `Are you sure to complete payment?`)
        .then((confirmed) => {
          if (confirmed) {
            this.purchasesService.submitReceipt(data).subscribe(res => {
              if (res == "Ok") {
                this.supplierService.getSupplierStatements({
                  "supplier_id": this.id, "store_id": this.local.get("store_id"),
                  "statement_type": (this.statement_type == "Pending") ? "Brief" : this.statement_type
                }).toPromise().then((data: Array<SuppliersStatementElement>) => {
                  this.purchase_transactions = data["purchase_transactions"];
                  this.returns = data["returns"];
                  this.months = data["months"];
                  this.store = data["store"][0];
                  this.supplier = data["supplier"][0];
                  this.four_months = data["four_months"][0];
                  this.three_months = data["three_months"][0];
                  this.two_months = data["two_months"][0];
                  this.one_months = data["one_months"][0];
                  // this.current = data["current"][0];
                  // this.ELEMENT_DATA = data;
                  // this.dataSource = new MatTableDataSource<SuppliersStatementElement>(this.ELEMENT_DATA);
                  // this.dataSource.paginator = this.paginator;
                });
                this.toasterService.success("Receiving completed against supplier!", "Success!", {
                  timeOut: 5000
                });
                this.payment = 0;
                this.total = 0;
                this.selected_cns = [];
                this.selected_purchases = [];
                this.from = null;
                this.to = null;
                this.purchase_date = this.CheckDate();
                this.payment_type = "cash";
                this.payment_nominal_account = "780";
              }
            });
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

      // supplier, type, payment, payment_type, payment_nominal_account, store, user, supplier_ref

    }
  }
}

