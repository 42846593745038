import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { CustomersService } from '../customers/customers.service';
import { UserService, AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'customer-csv-upload',
  templateUrl: './customer-csv-upload.component.html',
  styleUrls: ['./customer-csv-upload.component.scss']
})
export class CustomerCSVUploadComponent implements OnInit {
  loading = false;
  file_path; data; store_id; currentUserSubscription; currentUser;
  constructor(private papa: Papa, private CustomersService: CustomersService, private router: Router,
    private authenticationService: AuthenticationService,
    public local: LocalStorageService) {
    window.scrollTo(0, 0);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user[0];
      this.local.set("store_id", this.currentUser.store_id, 0, 's');
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
  }
  done = function () {
  }

  ConvertCSVtoJSON() {
    this.loading = true;
    this.CustomersService.addCustomerCSV(this.data).subscribe((data: Array<object>) => {
      this.loading = false;
      this.router.navigate(['/customers']);
    }, err => {
      this.loading = false;
    });

  }

  customers = [];
  // category_relation = [];
  handleFileSelect(evt) {
    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          this.data = results;
          for (let i = 0; i < results.data.length; i++) {
            let customer = {
              first_name: results.data[i].first_name,
              last_name: results.data[i].last_name,
              address: results.data[i].address,
              city: results.data[i].city,
              postcode: results.data[i].postcode,
              phone: results.data[i].phone,
              email_id: results.data[i].email_id,
              credit_limit: results.data[i].credit_limit,
              current_credit: results.data[i].current_credit
            }
            this.customers.push(customer);

          }
          console.log('Parsed: k', results.data);
        }
      });
    }
  }
}
