
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomersService } from '../customers/customers.service';
import { ProductsService } from '../products/products.service';
import { RolesService } from '../roles/roles.service';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { element } from '@angular/core/src/render3';
import { Router } from '@angular/router';

interface RolesElement {
  user_type: String,
  dashboard,
  customers,
  products,
  promotions,
  suppliers,
  sales,
  employees,
  stores,
  transactions,
  raw_products,
  companies,
  returns
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  user_type = ""; user_types: Array<any>; changeMade: Boolean = false; roles;
  displayedColumns: string[] = [
    'user_type',
    'dashboard',
    'customers',
    'products',
    'promotions',
    'suppliers',
    'sales',
    'employees',
    'companies',
    'stores',
    'transactions',
    'raw_products',
    'returns'];
  ELEMENT_DATA: RolesElement[];
  dataSource = new MatTableDataSource<RolesElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<RolesElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;


  length;
  pageSize = 10;
  
  
  @Output() page: EventEmitter<PageEvent>;

  queryField: FormControl = new FormControl();
  constructor(private rolesService: RolesService,
    public dialog: MatDialog,
    private searchService: SearchService,
    private toaster: ToastrService,
    private router: Router
    , private confirmationDialogService: ConfirmationDialogService) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getRoles();
  }
  getRoles() {
    // : Array<RolesElement>
    this.rolesService.getAllRoles().toPromise().then((data) => {
      this.roles = data["roles"];
      // this.dataSource = new MatTableDataSource<RolesElement>(this.ELEMENT_DATA);
      this.user_types = data["user_types"];
    })
  }
  /** Whether the number of selected elements matches the total number of rows. */
  addField = function () {
    let user_type_exists: Boolean = false;
    this.user_types.forEach(element => {
      if (element == this.user_type.toLowerCase()) {
        user_type_exists = true;
      }
    });
    //&& !(this.user_type in this.user_types)
    if (this.user_type.length >= 4 && !user_type_exists) {
      let tempRole: RolesElement = {
        user_type: this.user_type.toLowerCase(),
        dashboard: false,
        customers: false,
        products: false,
        promotions: false,
        suppliers: false,
        sales: false,
        employees: false,
        stores: false,
        transactions: false,
        raw_products: false,
        companies: false,
        returns: false
      }

      // this.ELEMENT_DATA.push(tempRole);
      this.user_types.push(this.user_type.toLowerCase());
      this.roles.forEach(element => {
        element[this.user_type.toLowerCase()] = 0;
      })
      this.dataSource._updateChangeSubscription();
      this.user_type = "";
      this.changeStatus();
    }
    else {
      if (this.user_type.length < 4) {
        this.toaster.warning('Make sure you have entered a proper user type', 'User Type Invalid', {
          timeOut: 3000
        });
      }
      else {
        if (user_type_exists) {
          this.toaster.error('Make sure you have entered a unique user type', 'User Type Already Exists', {
            timeOut: 3000
          });
        }
        else {

          this.toaster.error('OOPS! An unknown problem occured', 'User Type has problem', {
            timeOut: 3000
          });
        }
      }
    }
  }
  changeStatus = function () {
    this.changeMade = true;
  }
  submitChanges = function () {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure to save?')
      .then((confirmed) => {
        if (confirmed) {
          this.rolesService.submitRoles(this.roles).subscribe(data => {
            this.toaster.success('Added successfully!', 'Success', {
              timeOut: 3000
            });
            this.changeMade = false;
            this.getRoles();
          }, error => {
            this.toaster.error('Sorry! Something went wrong!', 'Failure', {
              timeOut: 3000
            });
          });
        }
      })
  }
}
