import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PurchaseTransactionsService {

  constructor( private http: HttpClient) { }
  public getAllPurchaseTransactions(){
    return  this.http.get(`${API_URL}purchase-transactions/`);
  }
  public getAllPurchaseReturns(){
    return  this.http.get(`${API_URL}purchase-returns/`);
  }
  public getPurchaseTransaction(id){
    return  this.http.get(`${API_URL}purchase-transactions/view/${id}/`);
  }
  public getPurchaseReturn(id){
    return  this.http.get(`${API_URL}purchase-returns/view/${id}/`);
  }
  public getPurchaseTransactionsCount(store_id){
    return  this.http.post(`${API_URL}purchase-transactions-count/`, {"store_id":store_id});
  }
  public getPurchaseReturnsCount(store_id){
    return  this.http.post(`${API_URL}purchase-returns-count/`, {"store_id":store_id});
  }
  public getTodaysPurchaseTransactionsCount(store_id){
    return  this.http.get(`${API_URL}todays-purchases/${store_id}/`);
  }
  public getPurchaseTransactions(event, store_id, to?, from?, order_by?, query?){
    return  this.http.post(`${API_URL}get-purchase-transactions/`, {"event":event, "store_id":store_id, "to":to, "from":from, "order_by":order_by, "query": query});
  }
  public getPurchaseReturns(event, store_id, to?, from?, order_by?, query?){
    return  this.http.post(`${API_URL}get-purchase-returns/`, {"event":event, "store_id":store_id, "to":to, "from":from, "order_by":order_by, "query": query});
  }
  public getPurchaseTransactionsColumns() {
    return this.http.get(`${environment.apiUrl}purchase-transactions-columns/`);
  }
  public getPurchaseReturnsColumns() {
    return this.http.get(`${environment.apiUrl}purchase-returns-columns/`);
  }
  public setPurchaseTransactionsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-purchase-transactions-columns/`, {"item_id":item_id, "state":state});
  }
  public setPurchaseReturnsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-purchase-returns-columns/`, {"item_id":item_id, "state":state});
  }
  public getPurchaseTransactionsviaSKU(event, store_id,to_searchviasku,from_searchviasku, query) {
    return this.http.post(`${API_URL}get-purchase-transactions-via-sku/`, { "paginator": event, "store_id": store_id,"to_searchviasku": to_searchviasku, "from_searchviasku": from_searchviasku, "query": query });
  }
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////DJANGO SERVICES NOT DEFINED/////////////////////////
  /////////////////////////////////////////////////////////////////////////////////// 
  public getPurchaseTransactionsToday(date, store_id, event){
    return this.http.post(`${API_URL}get-purchase-transactions-today/${store_id}/`, {"date":date, "event":event});
  }
  public getPurchaseTransactionsCountToday(date, store_id){
    return this.http.post(`${API_URL}todays-purchases/${store_id}/`, {"date":date});
  }
  public getPurchaseReturnsCountToday(date, store_id){
    return this.http.post(`${API_URL}todays-purchase-returns-count/${store_id}/`, {"date":date});
  }
  public getPurchaseReturnsToday(date, store_id, event){
    return this.http.post(`${API_URL}todays-purchase-returns/${store_id}/`, {"date":date});
  }
  ///////////////////////////////////////////////////////////////////////////////////
  public getReport(date, store_id){
    return this.http.post(`${API_URL}report/${store_id}/`, {"date":date});
  }
  public correctMargins(){
    return this.http.get(`${API_URL}correct-purchase-transactions-margin/`);
  }
  public resend(content){
    return this.http.post(`${API_URL}resend-email/`, content)
  }
}