import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor( private http: HttpClient) { }
  public getAllSuppliers(){
    return  this.http.get(`${API_URL}suppliers/`);
  }
  public getSupplier(id){
    return  this.http.get(`${API_URL}supplier/${id}`);
  }
  public updateSupplier(id, supplier){
    return this.http.post(`${API_URL}update-supplier/${id}/`,supplier);
  }
  public addSupplier(supplier){
    return this.http.post(`${API_URL}add-supplier/`,supplier);
  }
  public getSuppliersCountPayment(){
    return  this.http.get(`${API_URL}suppliers-count-payment/`);
  }
  public getSuppliersCount(){
    return  this.http.get(`${API_URL}suppliers-count-payment/`);
  }
  public getSuppliers(event){
    return  this.http.post(`${API_URL}get-suppliers/`, event);
  }
  public getSuppliersPayments(event){
    return  this.http.post(`${API_URL}get-suppliers-payment-page/`, event);
  }
  public getSuppliersColumns(user_type) {
    return this.http.post(`${environment.apiUrl}suppliers-columns/`, {"user_type": user_type});
  }
  public setSuppliersColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-suppliers-columns/`, {"item_id":item_id, "state":state});
  }
  
  public getSupplierStatements(id){
    return this.http.post(`${API_URL}get-supplier-statement/`, id);
  }
  public getSupplierStatementsColumns() {
    return this.http.get(`${API_URL}get-supplier-statements-columns/`);
  }
  public setSupplierStatementsColumnDisplay(item_id, state) {
    return this.http.post(`${API_URL}set-supplier-statements-columns/`, {"item_id":item_id, "state":state});
  }
  public getPurchaseTransaction(id: any) {
    return this.http.get(`${API_URL}purchase-transactions/view/${id}`);
  }
  public getPurchaseReturn(id: any) {
    return this.http.get(`${API_URL}purchase-returns/view/${id}`);
  }
  public getSupplierMonthlyStatement() {
    return this.http.get(`${API_URL}get-suppliers-monthly-statement/`); 
  }
  public getSupplierMonthly(no_of_months) {
    return this.http.post(`${API_URL}get-suppliers-monthly-statement/`, {"months": no_of_months});
  }
  public setSelectedSupplier(id){
    return this.http.get(`${API_URL}set-supplier-selection/${id}`);
  }
}