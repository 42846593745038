import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ColumnsService {

  constructor( private http: HttpClient) { }

    getCustomersColumns() {
        return this.http.get(`${environment.apiUrl}customers-columns/`);
    }
    getProductsColumns() {
        return this.http.get(`${environment.apiUrl}products-columns/`);
    }
    getPromotionsColumns() {
        return this.http.get(`${environment.apiUrl}promotions-columns/`);
    }
    getRawProductsColumns() {
        return this.http.get(`${environment.apiUrl}raw-products-columns/`);
    }
    getStoresColumns() {
        return this.http.get(`${environment.apiUrl}stores-columns/`);
    }
    getSuppliersColumns() {
        return this.http.get(`${environment.apiUrl}suppliers-columns/`);
    }
    getTransactionsColumns() {
        return this.http.get(`${environment.apiUrl}transactions-columns/`);
    }
    getUersColumns() {
        return this.http.get(`${environment.apiUrl}users-columns/`);
    }
    
}