import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ProductsService } from '../products/products.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  selectedIndex = 0; infotabActive = false; inventorytabActive = true;
  productInfoFormSumitted: boolean
  public id: string;
  model = {};
  ////////////////////////////////////////////////////////////////////////
  closeResult: string;
  constructor(private route: ActivatedRoute, private productsService: ProductsService, private router: Router) {
    window.scrollTo(0,0); }

  //////////////////////////////////////////////////////////////////////
  ngOnInit() {
    this.productInfoFormSumitted = false;
    this.id = this.route.snapshot.paramMap.get('id');
    //this.selectedIndex = this.id;
    if (this.id != null) {
      // this.productsService.getProduct(this.id).subscribe((data: Array<object>) => {
      //   this.model = data[0];
      // });
      this.selectedIndex++;
      if (this.selectedIndex == 0) {
        this.infotabActive = false;
        this.inventorytabActive = true;
      }
      else {
        this.inventorytabActive = false;
      }
    }
    this.selectedIndex = 0;
  }
  submit = function () {
  }
}
