import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SuppliersService } from '../suppliers/suppliers.service';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from '@app/roles/roles.service';
enum SuppliersElement {
  supplier_id, supplier_name, address, city, postcode, country, phone, email_id, opening_hours, closing_hours

}

@Component({
  selector: 'app-purchase-payment',
  templateUrl: './purchase-payment.component.html',
  styleUrls: ['./purchase-payment.component.css']
})
export class PurchasePaymentComponent implements OnInit {
  selectedColumns;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  loaded = false;
  //////////////////////////////////
  displayedColumns: string[] = ['supplier_name', 'current_credit',
  ];
  filter_dropdown = false;
  ELEMENT_DATA: SuppliersElement[];
  dataSource = new MatTableDataSource<SuppliersElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<SuppliersElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  length;
  currentUserSubscription;
  currentUser;
  store_id;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100]; pageIndex; pageEvent: PageEvent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent>; roles;

  queryField: FormControl = new FormControl();
  constructor(private suppliersService: SuppliersService, private searchService: SearchService, private authenticationService: AuthenticationService,
    private toaster: ToastrService, private local: LocalStorageService,
    private rolesService: RolesService) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["payments"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["payments"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["payments"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["payments"]["current_page"];
    this.getNumberofSuppliers();
    this.getServerData(null);
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchSupplierPayments(data, this.local.get("store_id")).toPromise().then((data: Array<SuppliersElement>) => {
        this.displayedColumns = data["columns"]
        this.ELEMENT_DATA = data["suppliers"];
        this.dataSource = new MatTableDataSource<SuppliersElement>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      });
    });

    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().
      then(data => {
        this.roles = data;
      });
  }

  getColumnName(inp): string {
    if (inp == "supplier_id") return "Supplier ID";
    else if (inp == "supplier_name") return "Name";
    else if (inp == "credit_limit") return "Credit Limit";
    else if (inp == "current_credit") return "Total";
    else if (inp == "balance") return "Balance";
    else return inp;
  }
  getNumberofSuppliers = function () {
    this.suppliersService.getSuppliersCountPayment().toPromise().then(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  public getServerData(event?: PageEvent) {
    if (event) {
      this.suppliersService.getSuppliersPayments(event).subscribe(data => {
        this.displayedColumns = data["columns"]
        this.ELEMENT_DATA = data["suppliers"];
        this.dataSource = new MatTableDataSource<SuppliersElement>(this.ELEMENT_DATA);
        var pageSettings = JSON.parse(localStorage.pageSettings);
        
        pageSettings.payments = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
        localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
      });
    }
    else {
      var pageSettings = JSON.parse(localStorage.pageSettings);
      this.suppliersService.getSuppliersPayments({
        'length': this.length,
        'pageIndex': pageSettings.payments.current_page,
        'pageSize': pageSettings.payments.items_per_page,
        'previousPageIndex': 0
      }).subscribe((data: Array<SuppliersElement>) => {
        this.displayedColumns = data["columns"]
        this.ELEMENT_DATA = data["suppliers"];
        this.dataSource = new MatTableDataSource<SuppliersElement>(this.ELEMENT_DATA);
      });
    }
    return event;
  }
  getSuppliers() {
    this.suppliersService.getAllSuppliers().subscribe((data: Array<SuppliersElement>) => {
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource<SuppliersElement>(this.ELEMENT_DATA);
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: SuppliersElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;

    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }
  toggleDD() {
    if (this.showdd1) {
      this.showdd1 = false;
    }
    else {
      this.showdd1 = true;
    }
  }
}
