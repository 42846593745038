import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterContentChecked, ElementRef, } from '@angular/core';
import { SearchService } from '../_services/search/search.service';
import { ProductsService } from '../products/products.service';
import { QuotesService } from './quote.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionsService } from '../promotions/promotions.service';
import { CustomersService } from '../customers/customers.service';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { tap, filter, } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService, AuthenticationService } from '@app/_services';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { StoresService } from '@app/stores/stores.service';
import { DatePipe } from '@angular/common';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef } from '@angular/core';
import { CompaniesService } from '@app/companies/companies.service';
import { TransactionsService } from '../sales/transactions/transactions.service';
import { ProductImagesDialog } from '../products/products/products.component';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';
import { AddRawProductDialog, AuthenticationDialog, CustomerDialog } from '../sales/sales/sales.component';
import { SalesService } from '../sales/sales.service';
import { eventNames } from 'process';

export interface PromotionsElement {
  promotion_id,
  product_id, sku,
  alternate_sku,
  product_name,
  product_description,
  category,
  brand,
  price_per_unit,
  start_date, end_date, discount, comments
}
@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  providers: [DatePipe]
})

export class QuotesComponent implements OnInit, OnDestroy, AfterContentChecked {
  customer_dropdown=false;
  in_stock_filter = false; checked = [];
  saleProducts = new Array<any>(); customer_ref;
  customer; selectedProducts; payment; store;
  transaction_id; dd = false;
  results; pro;
  lengthVar;
  queryField: FormControl = new FormControl();
  net_total = 0;
  currentUser: any; verified_by = null;
  currentUserSubscription: Subscription; selectedValue; receipt = false;
  extra_note; comments; transactions; selected_transactions = [];
  protected products: any[];
  public quantityControl: FormControl = new FormControl();
  /** control for the selected product for multi-selection */
  public productMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public productMultiFilterCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of products filtered by search keyword */
  public filteredProductsMulti: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  dropdownList;
  protected customers: any[];

  /** control for the selected customer for multi-selection */
  public customerMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public customerMultiFilterCtrl: FormControl = new FormControl();

  /** list of customers filtered by search keyword */
  public filteredCustomersMulti: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** control for the selected customer for multi-selection */
  public transactionMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public transactionMultiFilterCtrl: FormControl = new FormControl();

  /** list of customers filtered by search keyword */
  public filteredTransactionsMulti: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('multiSelect') singleSelect: MatSelect;

  @ViewChild('selectButton') selectButton;
  public id: string;
  model = {}; company;
  store_id; quoteClicked = false; stores;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  total_after_VAT: number;
  vat_amount: number;
  quotation_id;
  constructor(
    private cdr: ChangeDetectorRef,
    public local: LocalStorageService,
    private _searchService: SearchService,
    private salesService: SalesService,
    private productsService: ProductsService,
    private promotionsService: PromotionsService,
    public dialog: MatDialog,
    private quoteService: QuotesService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private storesService: StoresService,
    private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService, private toasterService: ToastrService,
    private companiesService: CompaniesService,
    private transactionsService: TransactionsService,
    private route: ActivatedRoute, private router: Router,
    private nominalService: NominalAccountsService
  ) {
    window.scrollTo(0, 0);

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
    this.companiesService.getCompany(this.store_id).subscribe(data => {
      this.company = data ? data[0] : false;
    })
    this.storesService.getStore(this.store_id).toPromise().then(data => {
      this.store = data[0];
    });
  }

  // Filter the states list and send back to populate the selectedStates**

  toggleQuoteClicked() {
    this.quoteClicked = !this.quoteClicked;
  }
  clearInvoice(value, index, transaction_id, outstanding) {
    if (value == true) {
      this.selected_transactions.push(transaction_id);
      this.total_after_VAT = this.total_after_VAT + outstanding;
    }
    else {

      this.total_after_VAT = this.total_after_VAT - outstanding;
      this.selected_transactions.splice(this.selected_transactions.indexOf(transaction_id), 1);
    }
  }

  ngAfterContentChecked() {
  }
  ngOnInit() {
    if (this.currentUser.user_type != 'admin') {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, 's');
    }
    else {
      this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    }
    // set initial selection
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), this.in_stock_filter)))
      .subscribe(filteredProducts => {
        this.searching = false;
        this.filteredProductsMulti.next(filteredProducts);
      },
        error => {
          this.searching = false;
        });
    this.transactionMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchTransaction(search, this.local.get("store_id"))))
      .subscribe(filteredtransaction => {
        this.searching = false;
        this.filteredTransactionsMulti.next(filteredtransaction);
      },
        error => {
          this.searching = false;
        });
    this.customerMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchCustomerSales(search, this.local.get("store_id"))))
      .subscribe(filteredCustomers => {
        this.searching = false;
        this.filteredCustomersMulti.next(filteredCustomers);
      },
        error => {
          this.searching = false;
        });
    if (this.local.get("tempSaleQuotation")) {
      var quote = this.local.get("tempSaleQuotation");
      this.saleProducts = quote.saleProducts;
      this.net_total = quote.net_total;
      this.store_id = quote.store_id;
      this.company = quote.company;
      this.store = quote.store;
      this.quotation_id = quote.quotation_id;
      this.payment = quote.payment ? quote.payment : 0;
      this.customer = quote.customer;
      this.vat_amount = quote.vat_amount;
      this.total_after_VAT = quote.total;
      this.extra_note = quote.extra_note;
      this.comments = quote.comments;
      this.local.remove("tempSaleQuotation");
    }
  }
  InStockToggle() {
    this.in_stock_filter = !this.in_stock_filter
    if (this.in_stock_filter) {
      (document.getElementById('in-stock-btn') as HTMLElement).style.backgroundColor = '#009bce';
      (document.getElementById('in-stock-btn') as HTMLElement).style.color = 'white';
    }
    else {
      (document.getElementById('in-stock-btn') as HTMLElement).style.backgroundColor = 'white';
      (document.getElementById('in-stock-btn') as HTMLElement).style.color = 'black';
    }
  }

  getImages(id, name, sku) {
    const dialogRef = this.dialog.open(ProductImagesDialog, {
      height: '80%',
      width: '35%',
      data: { "id": id, "sku": sku, "name": name }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  change(event) {
    let p_id = event.source.value.product_id;
    let already_added = false;
    let i = 0;
    this.saleProducts.forEach(element => {
      if (element["product"]["product_id"] == p_id && event.isUserInput) {
        already_added = true;
        this.saleProducts[i]["quantity"]++;
        this.saleProducts[i]["display_price"] = <number>(this.saleProducts[i]["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
        // this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) -
        //   <number>(this.saleProducts[i]["promotion"]["discount"]);
        // this.saleProducts[i]["display_vat_inc"] = (<number>(this.saleProducts[i]["price_on_promotion"]) +
        //   <number>(this.saleProducts[i]["price_on_promotion"]) * 20 / 100);
        // this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["display_vat_inc"]) * <number>(this.saleProducts[i]["quantity"]));

        this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]);
        this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
        this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));


        this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
        this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["quantity"]) * ((this.saleProducts[i]["price_on_promotion"] ?
          <number>(this.saleProducts[i]["price_on_promotion"]) : <number>(this.saleProducts[i]["product"].price_per_unit)));
        this.saleProducts[i]["net_total"] = <number>((this.saleProducts[i]["net_total"] + this.saleProducts[i]["net_total"] * 20 / 100));
        this.saleProducts[i]["net_total"] = this.saleProducts[i]["net_total"];
      }
      i++;
    });

    if (event.isUserInput && !already_added) {
      var element = event.source.value;
      if (event.source.selected) {
        let temp = {};
        temp["product"] = element;
        temp["product"]["best_price"] = <number>(temp["product"]["best_price"]);
        temp["product"]["default_price"] = <number>(temp["product"]["default_price"]);
        temp["product"]["price_per_unit"] = <number>(temp["product"]["price_per_unit"]);
        this.productsService.getProductCategoryName(element.category_id).toPromise()
          //output: 'First Example'
          .then(result => {
            temp["category"] = result[0];
          });
        this.promotionsService.getPromotionByProductID(element.product_id).toPromise()
          //output: 'First Example'
          .then((result) => {
            let pro: PromotionsElement = {
              promotion_id: "",
              product_id: temp["product"].product_id, sku: temp["product"].sku,
              alternate_sku: temp["product"].alternate_sku,
              product_name: temp["product"].product_name,
              product_description: temp["product"].product_description,
              category: temp["product"].category_id,
              brand: temp["product"].brand,
              price_per_unit: <number>(temp["product"].price_per_unit),
              start_date: Date, end_date: Date,
              discount: 0,
              comments: ""
            };
            let resu = result[0] ? result[0] : pro;
            temp["promotion"] = resu;
            //temp["price_on_promotion"] = <number>(temp["product"].price_per_unit) - (<number>(temp["product"].price_per_unit) * ((temp["promotion"] ? <number>(temp["promotion"].discount) : 0) / 100));
            temp["quantity"] = 1;
            temp["net_total"] = <number>temp["quantity"] * ((temp["price_on_promotion"] ? <number>temp["price_on_promotion"] : <number>temp["product"].price_per_unit));
            temp["net_total"] = (temp["net_total"] + temp["net_total"] * 20 / 100);
            temp["price_per_unit"] = <number>(temp["product"].price_per_unit);
            temp["default_price"] = <number>(temp["product"].default_price);
            temp["best_price"] = <number>(temp["product"].best_price);
            temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
            // temp["price_on_promotion"] = <number>(temp["display_price"]) -
            //   <number>(temp["promotion"]["discount"]);
            // temp["display_vat_inc"] = (<number>(temp["price_on_promotion"]) +
            //   <number>(temp["price_on_promotion"]) * 20 / 100);
            // temp["display_total"] = (<number>(temp["display_vat_inc"]) * <number>(temp["quantity"]));

            temp["price_on_promotion"] = <number>(temp["display_price"]) + <number>(temp["display_price"]) * 20 / 100 - <number>(temp["promotion"]["discount"]);
            temp["display_vat_inc"] = <number>(temp["display_price"]) + <number>(temp["display_price"]) * 20 / 100;
            temp["display_total"] = (<number>(temp["price_on_promotion"]) * <number>(temp["quantity"]));
            temp["discounted_price"] = <number>(temp["display_price"]) - <number>(temp["promotion"]["discount"]) / 1.2;
            this.saleProducts.push(temp);
            this.net_total = 0;
            for (let i = 0; i < this.saleProducts.length; i++) {
              this.net_total = this.net_total + <number>(this.saleProducts[i]["discounted_price"]) * <number>(this.saleProducts[i]["quantity"]);
            }
            this.vat_amount = <number>(this.net_total * 20 / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
          });
        if (this.currentUser.user_type != 'admin') {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, 's');
        }
        else {
          this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
        }
      }
      else {
        for (let i = 0; i < this.saleProducts.length; i++) {
          if (this.saleProducts[i]["product"].product_id == event.source.value.product_id) {
            this.productMultiCtrl.value.splice(i, 1);
            this.saleProducts.splice(i, 1);
            this.net_total = 0;
            for (let p of this.saleProducts) {
              this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
            }
            this.vat_amount = <number>(this.net_total * 20 / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
          }
        }
        if (this.currentUser.user_type != 'admin') {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, 's');
        }
        else {
          this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
        }
      }
    }
    else {
      if (already_added) {
        this.toasterService.success('Quantity Updated', 'Updated!', {
          timeOut: 5000
        });
        this.net_total = 0;
        for (let p of this.saleProducts) {
          this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
        }
        this.vat_amount = <number>(this.net_total * 20 / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
      }
    }

    this.productMultiFilterCtrl = new FormControl();
    this.filteredProductsMulti = new ReplaySubject<any>(1);
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), this.in_stock_filter)))
      .subscribe(filteredProducts => {
        this.searching = false;
        this.filteredProductsMulti.next(filteredProducts);
      },
        error => {
          this.searching = false;
        });
  }
  openedChange(event) {
    if (!event) {
      this.productMultiFilterCtrl = new FormControl();
      this.filteredProductsMulti = new ReplaySubject<any>(1);
      this.productMultiFilterCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), this.in_stock_filter)))
        .subscribe(filteredProducts => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
          error => {
            this.searching = false;
          });
    }
  }
  openedTransactionChange(event) {
    if (!event) {
      this.transactionMultiFilterCtrl = new FormControl();
      this.filteredTransactionsMulti = new ReplaySubject<any>(1);
      this.transactionMultiFilterCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap(search => this._searchService.searchTransaction(search, this.local.get("store_id"))))
        .subscribe(filteredTransactions => {
          this.searching = false;
          this.filteredTransactionsMulti.next(filteredTransactions);
        },
          error => {
            this.searching = false;
          });
    }
  }


  mouseEnter(event) {
    event.fromElement.disabled = true;
  }
  mouseLeave(event) {
  }
  changeCustomer(event) {
    if (event.isUserInput) {
      var element = event.source.value;
      if (event.source.selected) {
        this.customer = element;
      }
    }
    else {
      var element = event.source.value;
      if (event.source.selected) {
        this.customer = element;
      }
    }
    this.net_total = 0;
    if (this.customer.first_name.includes('Cash') && this.customer) {
      for (let p of this.saleProducts) {

        p["display_price"] = <number>(p["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);

        p["price_on_promotion"] = <number>(p["display_price"]) + <number>(p["display_price"]) * 20 / 100 - <number>(p["promotion"]["discount"]);
        p["display_vat_inc"] = <number>(p["display_price"]) + <number>(p["display_price"]) * 20 / 100;
        p["display_total"] = (<number>(p["price_on_promotion"]) * <number>(p["quantity"]));
        p["discounted_price"] = <number>(p["display_price"]) - <number>(p["promotion"]["discount"]) / 1.2;

        p["net_total"] = (<number>(p["quantity"]) * <number>(p["price_on_promotion"]) + <number>(p["quantity"]) * <number>(p["price_on_promotion"]) * 20 / 100);
        p["net_total"] = p["net_total"];
        this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
      }
      this.vat_amount = <number>(this.net_total * 20 / 100);
      this.total_after_VAT = <number>(this.net_total + this.vat_amount);

    }
    else {
      for (let p of this.saleProducts) {
        p["price_on_promotion"] = <number>(p["product"]["price_per_unit"]) - <number>(p["product"]["price_per_unit"]) / 100 * <number>(p["promotion"]["discount"]);
        p["net_total"] = (<number>(p["quantity"]) * <number>(p["price_on_promotion"]) + <number>(p["quantity"] * p["price_on_promotion"]) * 20 / 100);
        p["net_total"] = p["net_total"];
        p["display_price"] = <number>(p["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
        p["price_on_promotion"] = <number>(p["display_price"]) + <number>(p["display_price"]) * 20 / 100 - <number>(p["promotion"]["discount"]);
        p["display_vat_inc"] = <number>(p["display_price"]) + <number>(p["display_price"]) * 20 / 100;
        p["display_total"] = (<number>(p["price_on_promotion"]) * <number>(p["quantity"]));
        p["discounted_price"] = <number>(p["display_price"]) - <number>(p["promotion"]["discount"]) / 1.2;

        this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
      }
      this.vat_amount = <number>(this.net_total * 20 / 100);
      this.total_after_VAT = <number>(this.net_total + this.vat_amount);

    }

    if (this.receipt && this.customer != "" && this.customer != false) {
      this.quoteService.getCustomerOutstanding(this.customer).subscribe(data => {
        this.transactions = data;
      })
    }

  }
  quantityChange(event, i) {
    this.saleProducts[i]["quantity"] = <number>(event) * 1;
    this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]) * 1;
    this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
    this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));
    this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) * 1 - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;

    this.saleProducts[i]["net_total"] = <number>(event) * ((this.saleProducts[i]["price_on_promotion"] ? <number>(this.saleProducts[i]["price_on_promotion"]) : <number>(this.saleProducts[i]["product"]["price_per_unit"])));
    this.saleProducts[i]["net_total"] = (<number>(this.saleProducts[i]["net_total"]) + <number>(this.saleProducts[i]["net_total"]) * 20 / 100);
    this.net_total = 0;

    for (let p of this.saleProducts) {
      this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
    }
    this.vat_amount = <number>(this.net_total * 20 / 100);
    this.total_after_VAT = <number>(this.net_total * 1 + this.vat_amount);

  }
  priceChangevat(event, i) {
    this.saleProducts[i]["display_price"] = <number>((event.target.valueAsNumber/1.2));
    this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]);
    this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
    this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));
    this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
    this.saleProducts[i]["product"]["price_per_unit"] = <number>((event.target.valueAsNumber/1.2));
    this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
    this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]);
    this.net_total = 0;
    for (let p of this.saleProducts) {
      this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
    }
    this.vat_amount = <number>(this.net_total * 20 / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);
}
  priceChange(event, i) {
      this.saleProducts[i]["display_price"] = <number>(event.target.valueAsNumber);
      this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]);
      this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
      this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));
      this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
      this.saleProducts[i]["product"]["price_per_unit"] = <number>(event.target.valueAsNumber);
      this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
      this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]);
      this.net_total = 0;
      for (let p of this.saleProducts) {
        this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
      }
      this.vat_amount = <number>(this.net_total * 20 / 100);
      this.total_after_VAT = <number>(this.net_total + this.vat_amount);
  }
  discountChange(event, i) {
    let tempdiscount = <number>(this.saleProducts[i]["promotion"]["discount"]);
    let tempDiscountedPrice = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - event.target.valueAsNumber;
    if (<number>(this.saleProducts[i]["product"]["default_price"]) < tempDiscountedPrice) {

      this.saleProducts[i]["promotion"]["discount"] = event.target.valueAsNumber;
      this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]);
      this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
      this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));

      this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;

      this.saleProducts[i]["net_total"] = <number>this.saleProducts[i]["price_on_promotion"] * <number>this.saleProducts[i]["quantity"];
      this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]);
      this.net_total = 0;

      for (let p of this.saleProducts) {
        this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
      }
      this.vat_amount = <number>(this.net_total * 20 / 100);
      this.total_after_VAT = <number>(this.net_total + this.vat_amount);

    }
    else {
      const dialogRef = this.dialog.open(AuthenticationDialog, {
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.authentication_state == "successful") {
          this.verified_by = result.user.user_id;

          this.saleProducts[i]["promotion"]["discount"] = event.target.valueAsNumber;
          this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]);
          this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
          this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));

          this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]);
          this.net_total = 0;

          for (let p of this.saleProducts) {
            this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
          }
          this.vat_amount = <number>(this.net_total * 20 / 100);
          this.total_after_VAT = <number>(this.net_total + this.vat_amount);

        }
        else {
          this.saleProducts[i]["promotion"]["discount"] = <number>(tempdiscount);
        }
      });

    }

  }
  twentyPercent(amount) {
    return (Number(amount) + Number(amount * 20 / 100));

  }
  RoundOff(amount) {
    return Number(amount);
  }
  delete(i) {
    this.cdr.detectChanges();
    // this.multiSelect._selectionModel.selected[i].deselect();
    this.net_total = this.net_total - ((this.saleProducts[i]["discounted_price"] ? <number>(this.saleProducts[i]["discounted_price"]) : <number>(this.saleProducts[i]["product"]["price_per_unit"])) * <number>(this.saleProducts[i]["quantity"]));
    // this.multiSelect._onChange( this.saleProducts[i]);
    //this.multiSelect._selectionModel.deselect(this.saleProducts[i]);
    this.vat_amount = <number>(this.net_total * 20 / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);

    this.saleProducts.splice(i, 1);
    // this.productMultiCtrl.value.splice(i, 1);
    // this.multiSelect._selectionModel.selected.splice(i, 1);
    this.selectedProducts = this.productMultiCtrl.value;
    this.net_total = 0;

    this.vat_amount = 0;
    this.total_after_VAT = 0;
    for (let p of this.saleProducts) {
      this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
    }
    this.vat_amount = <number>(this.net_total * 20 / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);

  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.currentUserSubscription.unsubscribe();
    localStorage.removeItem('quotation_number');
    localStorage.removeItem('quotation_id');

  }
  toggleDropdown = function () {
    this.dd = !this.dd;
  }

  category = function (cat_id) {
    return this.productsService.getProductCategoryName(cat_id).subscribe((data) => {
    })
  }
  editCustomer = function () {
    this.customer_dropdown=false;
    this.openCustomerDialog();
  }
  openCustomerDialog = function (): void {
    if (this.currentUser.user_type != 'admin') {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, 's');
    }
    else {
      this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    }
    const dialogRef = this.dialog.open(CustomerDialog, {
      height: '100%',
      width: '70%',
      data: { "customer": this.customer, "store_id": this.store_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.customer = result ? result : null;
    });
  }
  openAddRawProductDialog = function (): void {

    this.customer_dropdown=false;
    const dialogRef = this.dialog.open(AddRawProductDialog, {
      height: '100%',
      width: '70%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.productsService.getProduct(result.product_id).subscribe((data) => {
        let temp = {};
        temp["product"] = data[0];
        temp["product"]["best_price"] = <number>(temp["product"]["best_price"]);
        temp["product"]["default_price"] = <number>(temp["product"]["default_price"]);
        temp["product"]["price_per_unit"] = <number>(temp["product"]["price_per_unit"]);
        this.productsService.getProductCategoryName(data[0].category_id).toPromise()
          //output: 'First Example'
          .then(result => {
            temp["category"] = result[0];
          });
        this.promotionsService.getPromotionByProductID(data[0].product_id).toPromise()
          //output: 'First Example'
          .then((result) => {
            let pro: PromotionsElement = {
              promotion_id: "",
              product_id: temp["product"].product_id, sku: temp["product"].sku,
              alternate_sku: temp["product"].alternate_sku,
              product_name: temp["product"].product_name,
              product_description: temp["product"].product_description,
              category: temp["product"].category_id,
              brand: temp["product"].brand,
              price_per_unit: <number>(temp["product"].price_per_unit),
              start_date: Date, end_date: Date,
              discount: 0,
              comments: ""
            };
            let resu = result[0] ? result[0] : pro;
            temp["promotion"] = resu;
            //temp["price_on_promotion"] = <number>(temp["product"].price_per_unit) - (<number>(temp["product"].price_per_unit) * ((temp["promotion"] ? <number>(temp["promotion"].discount) : 0) / 100));
            temp["quantity"] = 1;
            temp["net_total"] = <number>temp["quantity"] * ((temp["price_on_promotion"] ? <number>temp["price_on_promotion"] : <number>temp["product"].price_per_unit));
            temp["net_total"] = (temp["net_total"] + temp["net_total"] * 20 / 100);
            temp["price_per_unit"] = <number>(temp["product"].price_per_unit);
            temp["default_price"] = <number>(temp["product"].default_price);
            temp["best_price"] = <number>(temp["product"].best_price);
            temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);

            temp["price_on_promotion"] = <number>(temp["display_price"]) + <number>(temp["display_price"]) * 20 / 100 - <number>(temp["promotion"]["discount"]);
            temp["display_vat_inc"] = <number>(temp["display_price"]) + <number>(temp["display_price"]) * 20 / 100;
            temp["display_total"] = (<number>(temp["price_on_promotion"]) * <number>(temp["quantity"]));
            temp["discounted_price"] = <number>(temp["display_price"]) - <number>(temp["promotion"]["discount"]) / 1.2;
            this.saleProducts.push(temp);
            this.net_total = 0;
            for (let i = 0; i < this.saleProducts.length; i++) {
              this.net_total = this.net_total + <number>(this.saleProducts[i]["discounted_price"]) * <number>(this.saleProducts[i]["quantity"]);
            }
            this.vat_amount = <number>(this.net_total * 20 / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);

          });
        if (this.currentUser.user_type != 'admin') {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, 's');
        }
        else {
          this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
        }
      });
    });
  }
  showCustomerTransactions = function () {

  }
  setPrice(event, i) {
    this.saleProducts[i]["price_on_promotion"] = this.selectedValue * 1;
    let tempPrice = <number>(this.saleProducts[i]["product"]["price_per_unit"]);
    if (<number>(this.saleProducts[i]["default_price"]) * 1 <= <number>(event) * 1) {// - (<number>(this.saleProducts[i]["default_price"]) * <number>(this.saleProducts[i]["promotion"]["discount"]) / 100)
      this.saleProducts[i]["display_price"] = <number>(event) * 1;
      this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]) * 1;
      this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
      this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));
      this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) * 1 - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
      this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
      this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]) * 1;
      this.net_total = 0;
      for (let p of this.saleProducts) {
        this.net_total = this.net_total + <number>(p["discounted_price"]) * <number>(p["quantity"]);
      }
      this.vat_amount = <number>(this.net_total * 20 / 100) * 1;
      this.total_after_VAT = <number>(this.net_total + this.vat_amount) * 1;
    }
    else {
      const dialogRef = this.dialog.open(AuthenticationDialog, {
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.authentication_state == "successful") {
          this.verified_by = result["user"].user_id;
          this.saleProducts[i]["product"]["price_per_unit"] = <number>(event) * 1;
          this.saleProducts[i]["display_price"] = <number>(event) * 1;
          this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]) * 1;
          this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
          this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));
          this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) * 1 - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]) * 1;
          this.net_total = 0;

          for (let p of this.saleProducts) {
            this.net_total = this.net_total * 1 + <number>(p["discounted_price"]) * <number>(p["quantity"]);
          }
          this.vat_amount = <number>(this.net_total * 1 * 20 / 100);
          this.total_after_VAT = <number>(this.net_total * 1 + this.vat_amount);

        }
        else {
          this.saleProducts[i]["display_price"] = (this.customer && !this.customer.first_name.includes('Cash')) ?
            this.saleProducts[i]["product"].best_price * 1 : this.saleProducts[i]["product"].price_per_unit * 1;

          this.saleProducts[i]["price_on_promotion"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100 - <number>(this.saleProducts[i]["promotion"]["discount"]) * 1;
          this.saleProducts[i]["display_vat_inc"] = <number>(this.saleProducts[i]["display_price"]) * 1 + <number>(this.saleProducts[i]["display_price"]) * 20 / 100;
          this.saleProducts[i]["display_total"] = (<number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]));

          this.saleProducts[i]["discounted_price"] = <number>(this.saleProducts[i]["display_price"]) * 1 - <number>(this.saleProducts[i]["promotion"]["discount"]) / 1.2;
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["price_on_promotion"]) * <number>(this.saleProducts[i]["quantity"]);
          this.saleProducts[i]["net_total"] = <number>(this.saleProducts[i]["net_total"]) * 1;
          this.net_total = 0;

          for (let p of this.saleProducts) {
            this.net_total = this.net_total * 1 + <number>(p["discounted_price"]) * <number>(p["quantity"]);
          }
          this.vat_amount = <number>(this.net_total * 20 / 100);
          this.total_after_VAT = <number>(this.net_total * 1 + this.vat_amount * 1);

        }
      }, error => {
        console.log(error);
      });


    }

  }
  confirmQuotesSubmit() {
    
    if (!this.customer || this.customer == "undefined") {
      this.salesService.getDefaultCustomer().toPromise().then(data => {
        this.customer = data[0];
      })
      return
    }
    if (this.currentUser.user_type != 'admin') {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, 's');
    }
    else {
      this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    }
    if (!this.customer || this.customer == "undefined") {
      this.quoteService.getDefaultCustomer().toPromise().then(data => {
        this.customer = data[0];
      })
    }
    this.companiesService.getCompany(this.store_id).subscribe(data => {
      this.company = data ? data[0] : false;
    })
    this.storesService.getStore(this.store_id).toPromise().then(data => {
      this.store = data[0];
    });

    this.openReceiptDialog();

  }
  openReceiptDialog = function (): void {
    var quantity =0 
    for( var i=0 ;i< this.saleProducts.length ; i++){
      quantity = quantity + this.saleProducts[i].quantity 
    }
    if(quantity === 0){
      this.toasterService.error('Products quantity are zero. Update products quantity for Credit Notes!', 'Error!', {
        timeOut: 5000
      });
      return
    }
    
    /* if (!this.customer || this.customer == "undefined") {
      this.quoteService.getDefaultCustomer().toPromise().then(data => {
        this.customer = data[0];
      })
    } */
    if (this.saleProducts.length > 0) {
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

      this.storesService.getStore(this.store_id).toPromise().then(data => {
        this.store = data[0];
      });
      this.local.set("tempSaleQuotation", {
        "saleProducts": this.saleProducts, "customer": this.customer,
        "total": this.total_after_VAT, "net_total": this.net_total,
        "sold_by": this.currentUser.first_name.charAt(0) + this.currentUser.last_name.charAt(0),
        "store_id": this.store_id, "verified_by": this.verified_by,
        "company": this.company, "store": this.store, "vat_amount": this.vat_amount,
        "currentUser": this.currentUser, "customer_ref": this.customer_ref,
        "extra_note": this.extra_note, "comments": this.comments,
      }, 0, 's');
      this.router.navigate(['/receipt']);
    }
    else {
      this.toasterService.error('No products Selected. Select receipt for payment!', 'Error!', {
        timeOut: 5000
      });
    }
  }

  cancel() {
    this.selected_transactions = [];
    this.saleProducts = new Array<any>();
    this.customer = "";
    this.verified_by = null;
    this.selectedProducts = ""; this.payment = "";
    this.results = ""; this.pro = "";
    this.lengthVar = "";
    this.queryField = new FormControl();
    this.net_total = 0;
    this.vat_amount = 0;
    this.total_after_VAT = 0;
    this.products = [];
    this.quantityControl = new FormControl();
    /** control for the selected product for multi-selection */
    this.productMultiCtrl = new FormControl();
    /** control for the MatSelect filter keyword multi-selection */
    this.productMultiFilterCtrl = new FormControl();
    /** indicate search operation is in progress */
    this.searching = false;
    /** list of products filtered by search keyword */
    this.filteredProductsMulti = new ReplaySubject<any>(1);
    /** control for the selected customer for multi-selection */
    this.customerMultiCtrl = new FormControl();
    /** control for the MatSelect filter keyword multi-selection */
    this.customerMultiFilterCtrl = new FormControl();
    /** list of customers filtered by search keyword */
    this.filteredCustomersMulti = new ReplaySubject<any>(1);
    this.customer_ref = "";
    this.comments = "";
    this.extra_note = "";
    // set initial selection
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchProduct(search, this.local.get("store_id"), this.in_stock_filter)))
      .subscribe(filteredProducts => {
        this.searching = false;
        this.filteredProductsMulti.next(filteredProducts);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
    this.customerMultiFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this._searchService.searchCustomerSales(search, this.local.get("store_id"))))
      .subscribe(filteredCustomers => {
        this.searching = false;
        this.filteredCustomersMulti.next(filteredCustomers);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
    this.local.remove("tempSaleQuotation");
    // this.toasterService.warning('Quote Cancelled Successfully', 'Cancelled!', {
    //   timeOut: 5000
    // });
  }
  deleteCustomer = function () {
    this.customer = false;
    this.customerMultiCtrl = new FormControl();
  }
}
