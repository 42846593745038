import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { ProductsService } from '../products.service';
import { MatTableDataSource, PageEvent, MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

import { FormControl } from '@angular/forms';
import { SearchService } from '../../_services/search/search.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef } from '@angular/core';
import { element } from 'protractor';
import { NgxSpinnerService } from 'ngx-spinner';



const API_URL = environment.apiUrl;
enum ProductsElement {
  product_id,
  sku,
  alternate_sku,
  product_name,
  product_description,
  category_id,
  category_name,
  price_per_unit,
  reorder_level,
  deleted,
  cost,
  default_price,
  best_price,

}
enum ProductCategoryElement {
  category_id,
  category_name,
  category_description
}
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  in_stock_filter;
  loaded = false;
  columns;
  columns_selected = ['actions'];
  filter_dropdown = false;
  selectedField; selectedCategory; pageIndex;
  productFields = [];
  queryField: FormControl = new FormControl();
  ELEMENT_DATA: ProductsElement[];
  selection = new SelectionModel<ProductsElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  productCategories: ProductCategoryElement[];
  length; store_id; page;
  pageSize = 10; currentUser; currentUserSubscription;
  
  pageEvent: PageEvent;
  a_to; a_from; sort_by; order_by = 'asc';
  date_from = new Date();
  date_to = new Date();
  constructor(private productsService: ProductsService,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService,
    private local: LocalStorageService, private searchService: SearchService,
    public dialog: MatDialog, private cdr: ChangeDetectorRef, public spinner: NgxSpinnerService,) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["products"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["products"]["current_page"];

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }
  ngOnInit() {
    // this.spinner.show()
    this.getProductCategories();
    this.getProductFieldNames();
    this.getNumberofProducts();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["products"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["products"]["current_page"];
    this.getServerData({ "items_per_page": this.pageSize, "page": this.page });
    this.queryField.valueChanges.subscribe(data => {
      if (data.length === 0) {
        this.search({ "items_per_page": this.pageSize, "page": this.page });
      }
      else {
        this.searchService.search(data, this.store_id, { "items_per_page": this.pageSize, "page": this.page }).toPromise().then((data: Array<ProductsElement>) => {
        this.ELEMENT_DATA = data;
      });
      }
    });
    this.productsService.getProductsColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push(data[key]['column_name']);
        }
      }
      this.loaded = true;
    });
  }
  deleteProduct = function (product_id, sku) {
    this.confirmationDialogService.confirm('Delete Product', `Are you sure to delete product ` + this.sku + `?`).then((confirmed) => {
      if (confirmed) {
        this.productsService.deleteProduct(product_id).subscribe(data => {
          this.getServerData();
          this.cdr.detectChanges();
        });
      }
    }).catch((error) => console.log(error));
    
  }
  getImages(id, sku, name) {
    const dialogRef = this.dialog.open(ProductImagesDialog, {
      height: '80%',
      width: '35%',
      data: { "id": id, "sku": sku, "name": name }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  InStockToggle() {
    this.in_stock_filter = !this.in_stock_filter
    if (this.in_stock_filter) {
      (document.getElementById('in-stock-btn') as HTMLElement).style.backgroundColor = '#009bce';
      (document.getElementById('in-stock-btn') as HTMLElement).style.color = 'white';
    }
    else {
      (document.getElementById('in-stock-btn') as HTMLElement).style.backgroundColor = 'white';
      (document.getElementById('in-stock-btn') as HTMLElement).style.color = 'black';
    }
  }
  getColumnName(inp): string {
    if (inp == "product_id") return "Product ID";
    if (inp == "sku") return "SKU";
    if (inp == "alternate_sku") return "Alternate SKU";
    if (inp == "category_name" || inp == "category_id") return "Category"; ///////////
    if (inp == "price_per_unit") return "Price C";
    if (inp == "reorder_level") return "Reorder Level";
    if (inp == "default_price") return "Price A";
    if (inp == "best_price") return "Price B";
    if (inp == "product_name") return "Name";
    if (inp == "product_description") return "Description";
    if (inp == "location") return "Location";
    if (inp == "brand") return "Brand";
    if (inp == "upc") return "UPC";
    if (inp == "cost") return "Cost";
    if (inp == "deleted") return "Availablility";
    if (inp == "quantity") return "Quantity";
    if (inp == "in_stock") return "Quantity";
    if (inp == "value") return "Value";
    if (inp == "discontinued") return "Discontinued";
  }
  selectionChange(item, i, event) {
    this.productsService.setProductsColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.products = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.search({ "items_per_page": this.pageSize, "page": this.page });
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.products = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.search({ "items_per_page": this.pageSize, "page": this.page });
  }
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  exportProducts() {
    let csvData;
    this.productsService.exportProducts(this.store_id).subscribe(data => {
      csvData = this.ConvertToCSV(data);
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([csvData], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'Products.csv';
      a.click();
    });
  }
  getNumberofProducts = function () {
    this.productsService.getProductsCount(this.store_id).toPromise().then(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  search(event) {
      this.productsService.getProducts(event, this.store_id, this.a_to, this.a_from, this.date_from, this.date_to, this.sort_by, this.order_by, this.queryField.value).subscribe((data: Array<ProductsElement>) => {
        this.ELEMENT_DATA = data;
      });
    
  }
  public getServerData(event?) {
    if (this.store_id !== 'all') {
        this.productsService.getProducts(event? event : { "items_per_page": this.pageSize, "page": this.page }, this.store_id).subscribe((data: Array<ProductsElement>) => {
          this.ELEMENT_DATA = data;
          console.log("shamsail products");
          console.log(data);
          var pageSettings = JSON.parse(localStorage.pageSettings);
          pageSettings.products = { "items_per_page": this.pageSize, "current_page": this.page };
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
        });
    }
    else {
      this.getProducts()
    }
    return event;
  }
  getProducts() {
    this.productsService.getAllProducts({ "items_per_page": this.pageSize, "page": this.page }).subscribe((data: Array<ProductsElement>) => {
      this.ELEMENT_DATA = data;
    });
  }
  getProductCategories() {
    this.productsService.getAllProductCategories().subscribe((data: Array<ProductCategoryElement>) => {
      this.productCategories = data;
    });

  }

  getProductFieldNames() {
    this.productsService.getProductFieldNames().subscribe((data: Array<any>) => {
      this.productFields = data;
    });

  }

  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
}
@Component({
  selector: 'product-images-dialog',
  templateUrl: 'product-images-dialog.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductImagesDialog implements AfterViewInit, OnInit {
  id; product_name; sku; product_images; selected_image; product; quantity;
  items: GalleryItem[]; imageData; img_data = [];
  myThumbnail; myFullresImage;
  constructor(public gallery: Gallery, private toasterService: ToastrService, private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ProductImagesDialog>, private productsService: ProductsService, private local: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data) {
    window.scrollTo(0, 0);

    this.id = data.id;
    this.product_name = data.name;
    this.sku = data.sku;
    this.quantity = (data.quantity)? data.quantity : undefined;
  }
  ngOnInit() {
    this.productsService.getProduct(this.id, this.local.get("store_id")).subscribe(product => {
      this.product = product[0];
    })
    this.productsService.getImages(this.id, this.quantity? this.quantity: undefined).toPromise().then(images => {
      this.product_images = images;
      this.cdr.markForCheck();

      if (this.product_images.length > 0) {
        this.myThumbnail = this.getSrc(this.product_images[0].image);
        this.myFullresImage = this.getSrc(this.product_images[0].image);
        this.selected_image = this.product_images[0].id;
      }
      else {
        this.myThumbnail = null;
        this.myFullresImage = null;
        this.selected_image = null;
      }
    });

  }


  ngAfterViewInit() {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getSrc(image) {
    let x = API_URL + 'media/' + image;
    return x;

  }
  setMythumbnail(image) {
    let x = API_URL + 'media/' + image;
    this.myThumbnail = x;
    this.myFullresImage = x;

    // document.getElementById("zoom").getElementsByClassName("ngxImageZoomContainer")[0].setAttribute("style", "max-height: 600px; max-width: 450px");
    // document.getElementById("zoom").getElementsByClassName("ngxImageZoomContainer")[0].getElementsByTagName("img")[0].setAttribute("height", "600px");
    // document.getElementById("zoom").getElementsByClassName("ngxImageZoomContainer")[0].getElementsByTagName("img")[0].setAttribute("width", "450px");
    return x;
  }
  magnify(imgID, zoom) {
    var img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass center");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
      img.width * zoom + "px " + img.height * zoom + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;

      }
      /*set the position of the magnifier glass:*/
      glass.style.left = x - w + "px";
      glass.style.top = y - h + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
    }
    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }
  mouseleave() {
    document.getElementsByClassName("img-magnifier-glass")[0].remove();
  }
  delete() {

    this.cdr.markForCheck();
    this.productsService.deleteImage(this.selected_image).toPromise().then(images => {
      if (images != "404") {
        document.getElementById(this.selected_image).remove();
        this.product_images = images;
        this.cdr.detectChanges();
        if (this.product_images.length > 0) {
          this.myThumbnail = this.getSrc(this.product_images[0].image);
          this.myFullresImage = this.getSrc(this.product_images[0].image);
          this.selected_image = this.product_images[0].id;
        }
        else {
          this.myThumbnail = null;
          this.myFullresImage = null;
          this.selected_image = null;
        }
        this.toasterService.success('Deleted successfully', 'Image Deleted!', {
          timeOut: 5000
        });
        this.cdr.detectChanges();
      }
    });
    this.cdr.detectChanges();

  }

}
