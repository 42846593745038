import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor( private http: HttpClient) { }
  public getAllEmployees(){
    return  this.http.get(`${API_URL}users/`);
  }
  public getEmployee(id){
    return  this.http.get(`${API_URL}user/${id}`);
  }
  public updateEmployee(id, user_object){

    return this.http.post(`${API_URL}update-user/${id}/`,user_object);
  }
  public updateUserStore(id, store_id){

    return this.http.post(`${API_URL}update-employee/${id}/`, {"store_id":store_id});
  }

  public addEmployee(user_object){
    return this.http.post(`${API_URL}add-user/`,user_object);
  }
  public getEmployeesCount(store_id){
    return  this.http.post(`${API_URL}employees-count/`, {"store_id":store_id});
  }
  public getEmployees(event, store_id){
    return  this.http.post(`${API_URL}get-employees/`, {"event":event, "store_id": store_id});
  }
  public getEmployeesColumns() {
    return this.http.get(`${environment.apiUrl}users-columns/`);
  }
  public setEmployeesColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-users-columns/`, {"item_id":item_id, "state":state});
  }
  public getUserTypes(){
    return this.http.get(`${API_URL}get-user-types/`);
  }
  public deleteUser(user_id){
    return this.http.post(`${API_URL}delete-employee/`, {"user_id": user_id});
  }
}
