﻿import { Component, AfterViewInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { StoresService } from 'src/app/stores/stores.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { AdminStoreSelectDialog } from './login/login.component';
import { MatSelectSearchVersion } from 'ngx-mat-select-search';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements AfterViewInit {
  currentUser: User; store;
  matSelectSearchVersion = MatSelectSearchVersion;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private confirmationDialogService: ConfirmationDialogService,
    private storesService: StoresService,
    private location: Location,
    public dialog: MatDialog,
    public local: LocalStorageService
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser) {
        this.storesService.getStore(this.currentUser[0].store_id).subscribe(data => {
          this.store = data;
        });
      }
    });


  }
  ngAfterViewInit() {
  }
  openStoreSelectDialog = function (data): void {
    const dialogRef = this.dialog.open(AdminStoreSelectDialog, {
        closeOnNavigation: false,
        disableClose: true,
        height: 'fit-content',
        width: 'fit-content',
        borderRadius: '10px',
        backgroundColor: 'none',
        data: { data }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.local.set("store_id", result, 0, 's');
        let user = JSON.parse(localStorage.getItem('currentUser'));
        user[0]["store_id"] = result;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.store_id = result;
        window.location.reload();
        return result;
    });
}
  logout() {
    if (this.currentUser && this.currentUser[0].user_type == "admin") {
      this.confirmationDialogService.confirm('Select Action', `Would you like to Switch Store or Logout?`, 'Switch Store', 'Logout', 'lg').then((confirmed) => {
        if (confirmed) {
          this.openStoreSelectDialog(this.currentUser[0]);
        }
        else{
          this.authenticationService.logout();
          this.router.navigate(['/login']);
        }
      }).catch(() => {console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
    });

    }
    else {
      this.confirmationDialogService.confirm('Logout?', `Are you sure to Logout?`).then((confirmed) => {
        if (confirmed) {
          this.authenticationService.logout();
          this.router.navigate(['/login']);
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }

  }
  
  w3_open() {
    var mySidebar = document.getElementById("mySidebar");

    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");
    if (mySidebar.style.display === 'block') {
      mySidebar.style.display = 'none';
      overlayBg.style.display = "none";
    } else {
      mySidebar.style.display = 'block';
      overlayBg.style.display = "block";
    }
  }

  // Close the sidebar with the close button
  w3_close() {
    var mySidebar = document.getElementById("mySidebar");

    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");
    mySidebar.style.display = "none";
    overlayBg.style.display = "none";
  }
  goBack() {
    this.location.back();
  }
  goForward() {
    this.location.forward();
  }
}