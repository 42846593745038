import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';

import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '@app/confirmation-dialog/confirmation-dialog.service';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  quotation_id; return_id; return_status; return_quantity=0;
  constructor(private http: HttpClient,
    private route: ActivatedRoute, private router: Router,
    public local: LocalStorageService,private toasterService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService) { }
  
  public submitQuote(data) {
    return this.http.post(`${API_URL}submit-quotation/`, data);
  }
  public getQuotationID(store_id) {
    return this.http.post(`${API_URL}get-quotation-id/` ,{ "store_id": store_id });

  }
  public getReturnID() {
    return this.http.get(`${API_URL}get-return-id/`);

  }
  public getDefaultCustomer() {
    return this.http.get(`${API_URL}get-default-customer/`);

  }
  public authenticate(authentication_code) {
    return this.http.post(`${API_URL}authenticate/`, { "verified_by": authentication_code });
  }
  public sendEmail(content) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    //   return this.http.post(`${API_URL}send-email/`, JSON.stringify(content), {
    //     "headers": headers
    // })
    return this.http.post(`${API_URL}send-email/`, content)
  }
  public submitReceipt(quotations, customer, type, payment_nominal_account, payment, payment_type, store, user, card, cash, comments? ){
    return this.http.post(`${API_URL}submit-receipt/`, { "quotations": quotations, "customer": customer , "type": type, "payment": payment, "payment_type": payment_type, "payment_nominal_account": payment_nominal_account,"store_id": store, "user": user, "comments": comments? comments : "", "payment_mode": "receipt", "card": card, "cash": cash});
  }
  public getCustomerOutstanding(customer){
    return this.http.post(`${API_URL}get-customer-outstanding/`, customer);
  }

}