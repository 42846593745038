import { Component, OnInit, ViewChild, Output, EventEmitter, Inject, AfterViewInit, HostListener } from '@angular/core';
import { SuppliersService } from '../suppliers/suppliers.service';
import { AuthenticationService } from '@app/_services';
import { Location, DecimalPipe } from '@angular/common';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { SearchService } from '../_services/search/search.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '@app/confirmation-dialog/confirmation-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from '@app/roles/roles.service';
import { NominalAccountsService } from '@app/nominal-accounts/nominal-accounts.service';
import { PurchasesService } from '@app/purchases/purchases.service';

@Component({
  selector: 'app-nominal-transfer',
  templateUrl: './nominal-transfer.component.html',
  styleUrls: ['./nominal-transfer.component.css']
})
export class NominalTransferComponent implements OnInit, AfterViewInit {

  isSticky: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    this.isSticky = window.pageYOffset >= 150;
    if (pos > max - 250) {
      this.isSticky = false;
    }
  }
  
  currentUserSubscription;
  currentUser; roles; description = "";
  nominal_accounts; filtered_nominal_accounts;
  from_nominal_account; to_nominal_account; store_id;
  date = new Date();
  amount = 0;
  max_date = new Date();
  constructor(
    private location: Location,
    private searchService: SearchService,
    private nominalService: NominalAccountsService,
    private confirmationDialogService: ConfirmationDialogService,
    private authenticationService: AuthenticationService,
    private supplierService: SuppliersService, private local: LocalStorageService,
    private purchasesService: PurchasesService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog, private router: Router,
    private rolesService: RolesService) {
    window.scrollTo(0, 0);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }
    });
    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().
      then(data => {
        this.roles = data;
      });
    this.nominalService.getAllNominalAccounts().toPromise().then(data => {
      this.nominal_accounts = data;
      this.filtered_nominal_accounts = data;
    });

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }
    });
    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().
      then(data => {
        this.roles = data;
      });
    this.nominalService.getAllNominalAccounts().toPromise().then(data => {
      this.nominal_accounts = data;
      this.filtered_nominal_accounts = data;
    });
    
  }
  ngAfterViewInit(){
    
  }
  onKey(value) {
    this.filtered_nominal_accounts = this.search(value);
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    let filter = value.toLowerCase();
    return this.nominal_accounts.filter(option => option.code_number.toLowerCase().includes(filter) || option.description.toLowerCase().includes(filter));
  }

  submit(){
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }
    });
    let data = {
      "from_account": this.from_nominal_account,
      "to_account": this.to_nominal_account,
      "date": this.date,
      "amount": this.amount,
      "description": this.description,
      "store_id": this.local.get("store_id")
    } 
    this.nominalService.transferBalance(data).subscribe(data=>{
      this.toaster.success('Added successfully!', 'Success', {
        timeOut: 3000
      });
      this.router.navigate(['/nominal']);
    })
  }
}
