import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterContentChecked, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService, AuthenticationService } from '@app/_services';
import { StoresService } from '@app/stores/stores.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  
})

export class NavComponent implements OnInit, OnDestroy {
  currentUser: any; width:any;
  currentUserSubscription: Subscription;stores; store_id; store_name;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private storesService: StoresService,
    public local: LocalStorageService,
    public dialog: MatDialog,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      if(user) {this.currentUser = user[0]};
    });
      this.store_id = this.local.get("store_id")? this.local.get("store_id") :this.currentUser.store_id;
      
      this.local.set("store_id", this.store_id, 0, 's');
      if(this.store_id != "all"){
      this.storesService.getStore(this.store_id).subscribe(data=>{
        this.store_name = data[0]?data[0].store_name:"";
      })
    }else{
      this.store_name = "All";
    }
  }


  ngOnInit() {
    this.storesService.getAllStores().toPromise().then(data=>{
      this.stores = data;
    });
    this.store_id = this.local.get("store_id");
    if(this.store_id == 'all' || this.store_id){
      this.store_name = 'All';
    }
    else{
      this.storesService.getStore(this.store_id).subscribe(data=>{
        this.store_name = data[0].store_name;
      })
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }
  setStore(id, name){
    let expired: number = 0
    this.store_id = id;``
    this.store_name = name;
    this.local.set("store_id", this.store_id, expired, 's');
  }
  showMenu = function (): void {
    const dialogRef = this.dialog.open(LeftBarDialog, {
      background: 'none',
      height: '100%',
      width: 'auto',top: '0px', left: '0px',
      position: "0,0",
      data: {
        
      }
    });
      // const dialogRef = this.dialog.open(LeftBarDialog, {
      //   data: { "customer": this.customer }
      // });
  
      dialogRef.afterClosed().subscribe(result => {
        this.customer = result;
      });
    }
    


}
@Component({
  selector: 'app-leftbar-dialog',
  templateUrl: 'leftbar-dialog.html',
  styles: [`
  {
    .mat-dialog-container.leftbar {
      padding: 0px !important;
      padding-top: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      display:block;
      border-radius:4px;
      box-sizing:border-box;
      overflow:auto;
      outline:0;
      width:100%;
      height:100%;
      min-height:inherit;
      max-height:inherit}`]
})
export class LeftBarDialog {
  predefined = false; model; id;
  constructor(
    public dialogRef: MatDialogRef<LeftBarDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  submit = function () {
   }
   @HostListener('window:resize', ['$event'])
    onResize(event){
       if(event.target.innerWidth > 800){
        this.onNoClick();
       }
    }
}