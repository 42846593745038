
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProductsService } from '../products/products.service';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';


export interface RawProductElement {
  product_id,
  sku,
  product_name,
  description,
  price
}
//Edit	Product Id	SKU	Product Name	Description	Price
@Component({
  selector: 'app-raw-products',
  templateUrl: './raw-products.component.html',
  styleUrls: ['./raw-products.component.css']
})
export class RawProductsComponent implements OnInit {

  loaded = false;
  columns;
  columns_selected = ['actions'];
  ELEMENT_DATA: RawProductElement[];
  selection = new SelectionModel<RawProductElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  currentUserSubscription;
  currentUser;
  store_id;
  filter_dropdown = false;
  length;
  pageSize = 10;
   pageIndex; pageEvent: PageEvent;
  page;

  queryField: FormControl = new FormControl();
  constructor(private productsService: ProductsService,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private local: LocalStorageService,
    private cdr: ChangeDetectorRef) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["raw_products"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["raw_products"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }
    });
  }

  ngOnInit() {
    this.getNumberofRawProducts();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["raw_products"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["raw_products"]["current_page"];
    this.getServerData(null);
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchRawProduct(data, this.store_id).toPromise().then((data: Array<RawProductElement>) => {
        this.ELEMENT_DATA = data;
      });
    });
    this.productsService.getRawProductsColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "store_id") ? "store_name" : data[key]['column_name']);
        }
      }
     
      this.loaded = true;
    });
  }

  getColumnName(inp): string {
    if (inp == "product_id") return "ID";
    if (inp == "sku") return "SKU";
    if (inp == "product_name") return "Name";
    if (inp == "description") return "Description"; ///////////
    if (inp == "price") return "Price";
    if (inp == "store_id" || inp == "store_name") return "Store";
  }
 
  getNumberofRawProducts = function () {
    this.productsService.getRawProductsCount(this.store_id).toPromise().then(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  selectionChange(item, i, event) {
    this.productsService.setRawProductsColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.raw_products = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.raw_products = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  public getServerData(event?: PageEvent) {
    if (this.store_id !== 'all') {
      if (event) {
        this.productsService.getRawProducts(event, this.store_id).subscribe((data: Array<RawProductElement>) => {
          this.ELEMENT_DATA = data;
          var pageSettings = JSON.parse(localStorage.pageSettings);
          
          pageSettings.raw_products = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
        });
      }
      else {
        var pageSettings = JSON.parse(localStorage.pageSettings);
        this.productsService.getRawProducts({
          'length': this.length,
          'pageIndex': pageSettings.raw_products.current_page,
          'pageSize': pageSettings.raw_products.items_per_page,
          'previousPageIndex': 0
        }, this.store_id).subscribe((data: Array<RawProductElement>) => {
          this.ELEMENT_DATA = data;
        });
      }
    }
    else {
      this.getRawProducts()
    }
    return event;
  }
  getRawProducts() {
    this.productsService.getAllRawProducts().subscribe((data: Array<RawProductElement>) => {
      this.ELEMENT_DATA = data;
      var pageSettings = JSON.parse(localStorage.pageSettings);
      
      pageSettings.raw_products = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
      localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
    })
  }

  DeleteRawProducts() {
    console.log("Delete Raw Products");
    // var rawProducts;
    this.productsService.getAllRawProductsWithNoSKU().subscribe((data: Array<RawProductElement>) => { 
      console.log(data);
      this.ELEMENT_DATA = data;
      for (let prod = 0; prod < this.ELEMENT_DATA.length; prod++) {
        console.log(this.ELEMENT_DATA[prod]);
        console.log("Deleting Product " + this.ELEMENT_DATA[prod]);
        // this.productsService.deleteRawProduct(this.ELEMENT_DATA[prod]).subscribe((data) => { });
        // if (prod == 5) {
        //   break;
        // }
      }
    });
    this.getServerData(null);
    // this.ngOnInit();
    // this.getRawProducts();
  }
 
  
  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
}
