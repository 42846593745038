import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';

@Component({
  selector: 'nominal-csv-upload',
  templateUrl: './nominal-csv-upload.component.html',
  styleUrls: ['./nominal-csv-upload.component.scss']
})
export class NominalAccountCSVUploadComponent implements OnInit {
  file_path; data; loading = false;
  constructor(private papa: Papa, private nominalsService: NominalAccountsService,
    private router: Router) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }
  done = function () {
  }

  ConvertCSVtoJSON() {

    this.loading = true;
    this.nominalsService.addNominalAccountCSV(this.data).subscribe((data: Array<object>) => {
      this.router.navigate(['/nominal']);
    }, err => {
      this.loading = false;
    }
    );

  }
  nominal_accounts = [];
  // category_relation = [];
  handleFileSelect(evt) {
    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          this.data = results;
          for (let i = 0; i < results.data.length; i++) {
            let noinal_account = {
              code_number: results.data[i]["N/C"],
              description: results.data[i]["Name"],
              debit: 0,
              credit: 0
            }
            this.nominal_accounts.push(noinal_account);
          }
          console.log('Parsed: k', results.data);
        }
      });
    }
  }
}
