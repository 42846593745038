import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient) { }

  public upload(formData) {
    return this.http.post<any>(`${environment.apiUrl}upload-image/`, formData);
  }
  public uploadMultiple(formData) {
    return this.http.post<any>(`${environment.apiUrl}upload-images/`, formData);
  }
}