import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor( private http: HttpClient) { }
  public getStores(){
    return this.http.get(`${environment.apiUrl}get-reporting-stores/`);
  }
  // public getAllTransactionIds(id,store_id){
  //   return this.http.get(`${API_URL}getAllTransactionIds/${id}/${store_id}`);
  // }
  public getTransactionsByDay(day) {
    return this.http.get(`${environment.apiUrl}transactions-by-day/${day}`);
  }
  
  public getTransactionsByMonth(month) {
    return this.http.get(`${environment.apiUrl}transactions-by-month/${month}`);
  }
  public getProductsReport(){
    return this.http.get(`${environment.apiUrl}products-report/`);
  }
  public getTransactionsByYear(year) {
    return this.http.get(`${environment.apiUrl}transactions-by-year/${year}`);
  }
  
  public getTransactionsOnDate(date) {
    return this.http.get(`${environment.apiUrl}transactions-on-date/${date}`);
  }
  
  public getTransactionsForRange(from, to) {
    return this.http.get(`${environment.apiUrl}transactions/${from}/${to}`);
  }
  
  public getTransactionsColumns() {
    return this.http.get(`${environment.apiUrl}transactions-columns/`);
  }
  public setTransactionsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-transactions-columns/`, {"item_id":item_id, "state":state});
  }
  public getReportForPeriod(data){
    return this.http.post(`${API_URL}get-report-for-period/`, data);
  }
  public downloadReportForPeriod(data){
    return this.http.post(`${API_URL}download-report-for-period/`, data);
  }
  public getReportForPeriodCount(data){
    return this.http.post(`${API_URL}get-report-for-period-count/`, data);
  }
  public getCustomerSalesReport(data?)
  {
    if(data){
      return this.http.post(`${API_URL}get-csr/`, data);
    }
    else{
      return this.http.get(`${API_URL}get-csr/`);
    }
  }
  public getRepresentativeSalesReport(){
    return this.http.get(`${API_URL}get-rsr/`);
  }
  public getProductSalesReport(data){
    return this.http.post(`${API_URL}get-psr/`, data);
  }
  public getSalesAndSummarybyDay(){
    return this.http.get(`${API_URL}get-report-by-day/`);
  }
  public getSalesAndSummarybyMonth(){
    return this.http.get(`${API_URL}get-report-by-month/`);
  }
  public getSalesAndSummarybyYear(){
    return this.http.get(`${API_URL}get-report-by-year/`);
  }
  public getSalesAndSummarybyDate(date){
    return this.http.post(`${API_URL}get-report-by-date/`, date);
  }
  public getSalesAndSummarybyRange(from, to){
    return this.http.post(`${API_URL}get-report-by-range/`, {"from": from, "to": to});
  }
  public getSalesAndSummaryCustomerAndProduct(from?, to?){
    return this.http.post(`${API_URL}get-report-by-customer-product/`, {"from": from?from:null, "to": to?to:null});
  }
  public getProductsBySale(from?, to?){
    return this.http.post(`${API_URL}get-dashboard-products/`, {"from":from, "to": to});
  }
  public getProductsBySaleList(sort_by, from, to){
    return this.http.post(`${API_URL}get-dashboard-products-list/`, {"sort_by":sort_by, "from":from, "to": to});
  }
  public getProductBreakdown(product_id, from, to){
    return this.http.post(`${API_URL}get-product-breakdown/`, {"product_id":product_id, "from":from, "to": to});
  }
  
}