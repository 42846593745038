import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  AfterContentChecked,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { SearchService } from "../../_services/search/search.service";
import { ProductsService } from "../../products/products.service";
import { SalesService } from "../sales.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PromotionsService } from "../../promotions/promotions.service";
import { CustomersService } from "../../customers/customers.service";
import { takeUntil } from "rxjs/operators";
import { MatSelect } from "@angular/material";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { tap, filter } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { UserService, AuthenticationService } from "@app/_services";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { ConfirmationDialogServiceNew } from "../../confirmation-dialog-new/confirmation-dialog-new.service";
import { StoresService } from "@app/stores/stores.service";
import { DatePipe } from "@angular/common";
import { LocalStorageService } from "angular-web-storage";
import { ToastrService } from "ngx-toastr";
import { ChangeDetectorRef } from "@angular/core";
import { environment } from "@environments/environment";
import { CompaniesService } from "@app/companies/companies.service";
import { TransactionsService } from "../../sales/transactions/transactions.service";
import { NominalAccountsService } from "../../nominal-accounts/nominal-accounts.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Event } from "jquery";
import { element } from "protractor";
import { ClassGetter } from "@angular/compiler/src/output/output_ast";

const API_URL = environment.apiUrl;
export interface PromotionsElement {
  promotion_id;
  product_id;
  sku;
  alternate_sku;
  product_name;
  product_description;
  category;
  brand;
  price_per_unit;
  start_date;
  end_date;
  discount;
  comments;
}
@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
  providers: [DatePipe],
})
export class SalesComponent implements OnInit, OnDestroy, AfterContentChecked {
  isSticky: boolean = false;

  @HostListener("window:scroll", ["$event"])
  checkScroll() {
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    this.isSticky = window.pageYOffset >= 150;
    if (pos > max - 100) {
      this.isSticky = false;
    }
  }
  next_item_is = undefined;
  image_subscription;
  loading = true;
  transactions_to_clear;
  current_step = 1;
  myThumbnail;
  myFullresImage;
  selected_image;
  in_stock_filter = false;
  checked = [];
  return_checked = [];
  quotation_heading = false;
  customer_dropdown = false;
  STORAGE_KEY = "suspended_sales";
  return = false;
  saleProducts = new Array<any>();
  customer_ref;
  returnSaleProducts = new Array<any>();
  quantityToReturn = false;
  transaction;
  customer;
  selectedProducts;
  payment;
  store;
  transaction_id;
  dd = false;
  suspendedSaleLoaded = false;
  results;
  pro;
  card = 0;
  cash = 0;
  shipment;
  customer_address;
  total_discount = 0;
  lengthVar;
  restocking_fee;
  queryField: FormControl = new FormControl();
  net_total = 0;
  amount_due;
  original_amount_due_for_transaction;
  cashback;
  payment_type = "Cash";
  currentUser: any;
  verified_by = null;
  last_added_product;
  currentUserSubscription: Subscription;
  selectedValue;
  receipt = false;
  edit = false;
  setOther = true;
  extra_note;
  comments;
  transactions;
  selected_transactions = [];
  selected_returns = [];
  protected products: any[];
  public quantityControl: FormControl = new FormControl();
  public productMultiCtrl: FormControl = new FormControl();
  public productMultiFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public filteredProductsMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
  @ViewChild("multiSelect") multiSelect: MatSelect;
  dropdownList;
  protected customers: any[];
  public customerMultiCtrl: FormControl = new FormControl();
  public customerMultiFilterCtrl: FormControl = new FormControl();
  public filteredCustomersMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
  public transactionMultiCtrl: FormControl = new FormControl();
  public transactionMultiFilterCtrl: FormControl = new FormControl();
  public filteredTransactionsMulti: ReplaySubject<any> = new ReplaySubject<any>(
    1
  );
  @ViewChild("multiSelect") singleSelect: MatSelect;
  @ViewChild("selectButton") selectButton;
  public id: string;
  model = {};
  company;
  store_id;
  saleClicked = false;
  stores;
  protected _onDestroy = new Subject<void>();
  total_after_VAT: number;
  vat_amount: number;
  return_id;
  acc_id;
  quotation_number;
  nominal_accounts;
  nominal_accounts_net;
  selectedAccounts;
  selectedNominalAccounts;
  payment_nominal_account;
  net_nominal_account = "4000";
  transaction_number;
  first_name;
  transaction_loaded = false;
  returns;
  constructor(
    private cdr: ChangeDetectorRef,
    public local: LocalStorageService,
    private _searchService: SearchService,
    private customersService: CustomersService,
    private productsService: ProductsService,
    private promotionsService: PromotionsService,
    public dialog: MatDialog,
    private salesService: SalesService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private storesService: StoresService,
    private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService,
    private confirmationDialogServiceNew: ConfirmationDialogServiceNew,
    private toasterService: ToastrService,
    private companiesService: CompaniesService,
    private transactionsService: TransactionsService,
    private route: ActivatedRoute,
    private router: Router,
    private nominalService: NominalAccountsService,
    public spinner: NgxSpinnerService
  ) {
    this.nominalService
      .getAllNominalAccountsNet()
      .toPromise()
      .then((data) => {
        this.nominal_accounts_net = data;
        this.selectedNominalAccounts = this.nominal_accounts_net;
      });
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user ? user[0] : "";
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      });
    this.companiesService.getCompany(this.store_id).subscribe((data) => {
      this.company = data ? data[0] : false;
    });
    this.storesService
      .getStore(this.store_id)
      .toPromise()
      .then((data) => {
        this.store = data[0];
      });
  }
  onKey(value) {
    this.selectedAccounts = this.search(value);
  }
  onNominal(value) {
    this.selectedNominalAccounts = this.searchNominal(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.nominal_accounts.filter(
      (option) =>
        option.code_number.toLowerCase().includes(filter) ||
        option.description.toLowerCase().includes(filter)
    );
  }
  searchNominal(value: string) {
    let filter = value.toLowerCase();
    return this.nominal_accounts_net.filter(
      (option) =>
        option.code_number.toLowerCase().includes(filter) ||
        option.description.toLowerCase().includes(filter)
    );
  }
  set(expired: number = 0, key, obj) {
    let temp = this.local.get("suspendedSales") || new Array<any>();
    obj["key"] = key;
    let myDate = new Date();
    let current_date = this.datePipe.transform(myDate, "yyyy-MM-dd");
    obj["date"] = current_date;
    temp.push(obj);
    this.local.set("suspendedSales", temp, expired, "s");
    this.toasterService.success("Sale Suspended Successfully", "Suspended!", {
      timeOut: 5000,
    });
  }

  submitShortCustomer() {
    this.customersService
      .addShortCustomer(this.first_name)
      .subscribe((data) => {
        this.customer = data;
        this.customer_dropdown = false;
      });
  }

  save_original_payment(amount_due){
    


      this.original_amount_due_for_transaction = amount_due;
    
  }

  
  paymentChange(event) {
    this.cashback = <number>event;
    if (this.payment_type == "To Pay") {
      console.log(<number>this.payment > 0);
      console.log("Test New");
      // if (<number>this.payment > 0) {
      //   this.payment = <number>0;
      //   document.getElementById("payment").setAttribute("value", null);
      //   // this.toasterService.error()
      //   this.toasterService.error('Setting Payment amount to 0. To Pay cannot have a payment.', 'Payment Mehod!', {
      //     timeOut: 5000
      //   });
      // }
    }
    // console.log("hassan")
    //else if(this.payment_type="cashncard"){
    //   this.amount_due = this.total_after_VAT - this.total_discount;
    //   this.amount_due =  this.amount_due - this.payment;
    // }
    else {
      // console.log(this.restocking_fee);
      console.log("Before");
      
      if (this.return) {
        // console.log("Total after VAT: ",this.total_after_VAT);
        // console.log("Refund Due: ",this.amount_due);
        // console.log("Is it Return: ",this.return);
        // console.log("VAT amount", this.vat_amount);
        // console.log("Net total: ", this.net_total);
        // console.log("This transaction payment: ",this.transaction["payment"]);
        // console.log(typeof event, event);
        
        
        if (this.restocking_fee) {
          this.total_after_VAT = <number>(
            (this.net_total + this.vat_amount - this.restocking_fee)
          );
          this.amount_due = this.original_amount_due_for_transaction - (this.cashback + this.restocking_fee);
        } else {
          this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.cashback = <number>event;
        this.amount_due = this.original_amount_due_for_transaction - this.cashback;
        console.log(this.cashback);
        }
        
        
        // this.amount_due = <number>this.total_after_VAT - <number>this.payment;
        //this.amount_due = <number>this.total_after_VAT - <number>this.transaction['payment'];
      } else if (this.receipt) {
        this.amount_due =
          <number>this.total_after_VAT -
          <number>this.payment -
          <number>this.total_discount;
      } else {
        this.amount_due = <number>this.total_after_VAT - <number>this.payment;
      }
      console.log("After");
      console.log(this.total_after_VAT);
      if (this.amount_due < 0.01 && this.amount_due > 0) {
        // this.amount_due = 0;
        console.log("Welcome");
      }
    }
  }
  restocking_feeChange(event) {
    this.restocking_fee = <number>event;
    this.total_after_VAT =
      <number>(this.net_total + this.vat_amount) - this.restocking_fee;
    // this.amount_due = this.total_after_VAT - this.payment;
    if(this.cashback){
      this.amount_due = this.original_amount_due_for_transaction - (this.cashback + this.restocking_fee);
    }else{
      this.amount_due = this.original_amount_due_for_transaction - this.restocking_fee;
    }
    if (this.amount_due < 0.01 && this.amount_due > 0) {
      this.amount_due = 0;
    }
  }
  cash_change(event) {
    this.payment = this.card + this.cash;
    this.paymentChange(this.payment);
  }
  card_change(event) {
    this.payment = this.card + this.cash;
    this.paymentChange(this.payment);
  }
  toggleSaleClicked() {
    this.saleClicked = !this.saleClicked;
  }
  clearInvoice(value, index, transaction_id, outstanding) {
    console.log("Testing in selection change");
    console.log(this.total_after_VAT);
    if (value == true) {
      this.selected_transactions.push(transaction_id);
      // console.log(this.total_after_VAT);
      this.total_after_VAT = this.total_after_VAT + outstanding;
      console.log("Inside If");
      console.log(this.total_after_VAT);
      this.amount_due =
        this.total_after_VAT - this.payment - this.total_discount;
    } else {
      console.log("Inside Else");
      console.log(this.total_after_VAT);
      //console.log(outstanding);
      // console.log(this.total_discount);
      this.total_after_VAT = this.total_after_VAT - outstanding;
      this.selected_transactions.splice(
        this.selected_transactions.indexOf(transaction_id),
        1
      );
      //console.log(this.total_after_VAT);
      this.amount_due =
        this.total_after_VAT - this.payment - this.total_discount;
    }
    // this.amount_due = this.total_after_VAT - this.payment - this.total_discount;
  }
  returnInvoice(value, index, transaction_id, outstanding) {
    if (value == true) {
      this.selected_returns.push(transaction_id);
      this.total_after_VAT = this.total_after_VAT - outstanding;
    } else {
      this.total_after_VAT = this.total_after_VAT + outstanding;
      this.selected_returns.splice(
        this.selected_returns.indexOf(transaction_id),
        1
      );
    }
  }
  get(suspendedSale) {
    // this.route.queryParams.subscribe(params => {
    //   if (params['sale_type'] == "return") {
    //     this.returnSale();
    //   }
    //   else if (params['sale_type'] == "receipt") {
    //     this.receiptSale();
    //   }
    //   else if (params['sale_type'] == 'edit') {
    //     this.editSale();
    //   }
    //   else {
    //     this.SaleType();
    //   }
    // });
    this.SaleType();
    this.saleProducts = suspendedSale.saleProducts;
    this.returnSaleProducts = suspendedSale.returnSaleProducts;
    this.quotation_number = suspendedSale.quotation_number;
    this.customer = suspendedSale.customer;
    this.net_total = suspendedSale.net_total;
    this.amount_due = suspendedSale.amount_due;
    this.total_after_VAT = suspendedSale.total_after_VAT;
    this.vat_amount = suspendedSale.vat_amount;
    this.payment_type = suspendedSale.payment_type;
    this.payment_nominal_account = suspendedSale.payment_nominal_account;
    this.payment = suspendedSale.payment;
    this.suspendedSaleLoaded = true;
    this.customer_ref = suspendedSale.customer_ref;
    this.extra_note = suspendedSale.extra_note;
    this.comments = suspendedSale.comments;
    this.net_nominal_account = suspendedSale.net_nominal_account;
    this.card = suspendedSale.card;
    this.cash = suspendedSale.cash;
    this.shipment = suspendedSale.shipment;
    this.customer_address = suspendedSale.customer_address;
    this.total_discount = suspendedSale.discount;
    // console.log("Receipt: " + this.receipt);
    // console.log("Edit: " + this.edit);
    // console.log("Return: " + this.return);
  }
  getAll() {
    return this.local.get("suspendedSales");
  }
  clear() {
    this.local.clear();
  }
  removeSuspendedSaleOnUnsuspend(sale) {
    let i = 0;
    let suspendedSales = this.local.get("suspendedSales");
    for (let suspendedSale of suspendedSales) {
      if (suspendedSale.key == sale.key) {
        suspendedSales.splice(i, 1);
      }
      i++;
    }
    this.local.set("suspendedSales", suspendedSales, 0, "s");
    this.toasterService.success(
      "Sale unsuspended Succesfully",
      "Unsuspended!",
      {
        timeOut: 5000,
      }
    );
  }
  deleteSuspendedSale(sale) {
    let i = 0;
    let suspendedSales = this.local.get("suspendedSales");
    for (let suspendedSale of suspendedSales) {
      if (suspendedSale.key == sale.key) {
        suspendedSales.splice(i, 1);
      }
      i++;
    }
    this.local.set("suspendedSales", suspendedSales, 0, "s");
    this.toasterService.success(
      "Suspended Sale removed Successfully",
      "Removed!",
      {
        timeOut: 5000,
      }
    );
  }
  ngAfterContentChecked() {}
  ngOnInit() {
    localStorage.removeItem("quotation_number");
    localStorage.removeItem("quotation_id");
    this.route.queryParams.subscribe((params) => {
      if (params["sale_type"] == "return") {
        console.log("Inside Sales Return");

        this.returnSale();
      } else if (params["sale_type"] == "receipt") {
        console.log("Inside Sales Receipt");
        this.receiptSale();
      } else if (params["sale_type"] == "edit") {
        this.editSale();
      } else {
        console.log("Inside Sales");
        this.SaleType();
      }
    });
    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    this.payment_type = "Cash";
    this.payment_nominal_account = "780";
    this.nominalService
      .getAllSalesNominalAccounts()
      .toPromise()
      .then((data) => {
        let tempArr = [data[1], data[3]];
        //tempArr.push(data[3]);
        this.nominal_accounts = tempArr;
        this.selectedAccounts = this.nominal_accounts;
        console.log(this.selectedAccounts);
      });
    this.nominalService
      .getAllNominalAccountsNet()
      .toPromise()
      .then((data) => {
        this.nominal_accounts_net = data;
        this.selectedNominalAccounts = this.nominal_accounts_net;
      });
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchProduct(
            search,
            this.local.get("store_id"),
            this.in_stock_filter
          )
        )
      )
      .subscribe(
        (filteredProducts) => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
        (error) => {
          this.searching = false;
        }
      );
    this.transactionMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchTransactionSales(
            search,
            this.local.get("store_id")
          )
        )
      )
      .subscribe(
        (filteredtransaction) => {
          this.searching = false;
          this.filteredTransactionsMulti.next(filteredtransaction);
        },
        (error) => {
          this.searching = false;
        }
      );
    this.customerMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchCustomerSales(
            search,
            this.local.get("store_id")
          )
        )
      )
      .subscribe(
        (filteredCustomers) => {
          console.log("1");
          this.searching = false;
          this.filteredCustomersMulti.next(filteredCustomers);
        },
        (error) => {
          this.searching = false;
        }
      );

    if (
      this.route.snapshot.paramMap.get("previousUrl") == "quotations" &&
      this.local.get("tempQuotationSale")
    ) {
      if (this.local.get("tempSale")) {
        this.suspend(this.local.get("tempSale"));
        this.local.remove("tempSale");
      }
      this.quotation_heading = true;
      var sale = this.local.get("tempQuotationSale");
      this.quotation_number = sale.quotation_number;
      if (sale.quotation_number) {
        this.quotation_heading = true;
      }
      this.net_total = sale.net_total;
      this.amount_due = sale.amount_due;
      this.payment_type = "Cash";
      this.payment_nominal_account = "780";
      this.store = sale.store;
      this.payment = sale.payment ? sale.payment : 0;
      this.customer = sale.customer;
      this.vat_amount = sale.vat_amount;
      this.customer_ref = sale.customer_ref;
      this.shipment = sale.shipment;
      this.customer_address = sale.customer_address;
      this.total_after_VAT = sale.total_after_VAT;
      this.extra_note = sale.extra_note;
      this.comments = sale.comments;
      this.net_nominal_account = sale.net_nominal_account;
      this.total_discount = 0;
      sale.saleProducts.forEach((element) => {
        let temp = {};
        element["product"] = {
          product_id: element["product_id"],
          sku: element["sku_at_time"],
          alternate_sku: element["alternate_sku"],
          product_name: element["name_at_time"],
          product_description: element["product_description"],
          category_id: element["category_id"],
          brand: element["brand"],
          upc: element["upc"],
          price_per_unit: element["price"] / 1.2,
          reorder_level: element["reorder_level"],
          deleted: element["deleted"],
          cost: element["cost"],
          default_price: element["price"] / 1.2,
          best_price: element["price"] / 1.2,
          related: element["related"],
        };
        temp["product"] = element["product"];
        temp["product"]["best_price"] = <number>temp["product"]["best_price"];
        temp["product"]["default_price"] = <number>(
          temp["product"]["default_price"]
        );
        temp["product"]["price_per_unit"] = <number>(
          temp["product"]["price_per_unit"]
        );
        let pro: PromotionsElement = {
          promotion_id: "",
          product_id: temp["product"].product_id,
          sku: temp["product"].sku,
          alternate_sku: temp["product"].alternate_sku,
          product_name: temp["product"].product_name,
          product_description: temp["product"].product_description,
          category: temp["product"].category_id,
          brand: temp["product"].brand,
          price_per_unit: <number>temp["product"].price_per_unit,
          start_date: Date,
          end_date: Date,
          discount: 0,
          comments: "",
        };
        let resu = pro;
        temp["promotion"] = resu;
        temp["quantity"] = element.quantity;
        temp["net_total"] =
          <number>temp["quantity"] *
          (temp["price_on_promotion"]
            ? <number>temp["price_on_promotion"]
            : <number>temp["product"].price_per_unit);
        temp["net_total"] = temp["net_total"] + (temp["net_total"] * 20) / 100;
        temp["price_per_unit"] = <number>temp["product"].price_per_unit;
        temp["default_price"] = <number>temp["product"].default_price;
        temp["best_price"] = <number>temp["product"].best_price;
        temp["display_price"] = <number>(
          temp["product"][
            this.customer && this.customer.price_band
              ? this.customer.price_band
              : temp["product"]["price_per_unit"]
              ? "price_per_unit"
              : temp["product"]["best_price"]
              ? "best_price"
              : temp["product"]["default_price"]
              ? "default_price"
              : 0.0
          ]
        );
        // temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
        temp["price_on_promotion"] =
          <number>temp["display_price"] +
          (<number>temp["display_price"] * 20) / 100 -
          <number>temp["promotion"]["discount"];
        temp["display_vat_inc"] =
          <number>temp["display_price"] +
          (<number>temp["display_price"] * 20) / 100;
        temp["display_total"] =
          <number>temp["price_on_promotion"] * <number>temp["quantity"];
        temp["discounted_price"] =
          <number>temp["display_price"] -
          <number>temp["promotion"]["discount"] / 1.2;
        temp["restocking_fee"] = 0;
        temp["restock_percent"] = 0;
        this.saleProducts.push(temp);
      });
    } else if (this.local.get("tempSale")) {
      // this.checked = this.local.get("tempSale")["checked"];
      console.log("shamsail shamsail");
      var sale = this.local.get("tempSale");
      console.log(sale);
      this.saleProducts = sale.saleProducts;
      this.returnSaleProducts = sale.returnSaleProducts;
      this.net_total = sale.net_total;
      this.amount_due = sale.amount_due;
      this.payment_type = sale.payment_type;
      this.payment_nominal_account = sale.payment_nominal_account;
      this.store_id = sale.store_id;
      this.company = sale.company;
      this.store = sale.store;
      this.payment = sale.payment ? sale.payment : 0;
      this.customer = sale.customer;
      this.customer_ref = sale.customer_ref;
      this.shipment = sale.shipment;
      this.customer_address = sale.customer_address;
      this.vat_amount = sale.vat_amount;
      this.total_after_VAT = sale.total;
      this.return = sale.is_return;
      this.extra_note = sale.extra_note;
      this.comments = sale.comments;
      this.net_nominal_account = sale.net_nominal_account;
      this.total_discount = sale.discount;
      this.restocking_fee = sale.restocking_fee;
      this.transaction_id = sale.transaction_id;
      this.transaction_number = sale.transaction_number;
      this.transaction_loaded = true;

      this.transaction = sale.transaction;
      this.transactions_to_clear = sale.transactions_to_clear;
      // for (var i = 0; i < this.saleProducts.length; i++) {
      //   for (var j = 0; j < this.returnSaleProducts.length; j++) {
      //     if (this.saleProducts[i].product["product_id"] == this.returnSaleProducts[j].product["product_id"]) {
      //       this.checked[i] = true;
      //     }
      //     else{
      //       this.checked[i] = false;
      //     }
      //   }
      // }

      this.checked = sale.checked;
      console.log(this.checked);
      console.log(this.saleProducts);
      // var temp_obj_arr = [];
      // for(var i=0;i<this.checked.length;i++)
      // {
      //   if(this.checked[i]){
      //     temp_obj_arr.push(i);
      //   }
      // }

      // console.log(temp_obj_arr)

      if (this.return) {
        for (var i = 0; i < this.saleProducts.length; i++) {
          this.addProduct(false, i, this.saleProducts[i]);
          this.addProduct(this.checked[i], i, this.saleProducts[i]);
        }
      }
    }

    window.scrollTo(0, 0);
    // // checking routes to put return and receipt
    // const routes = this.route.snapshot.queryParams;
    // if (routes.return) {
    //   this.returnSale();
    // } else if (routes.receipt) {
    //   this.receiptSale();
    // }
  }

  getImages(id, name, sku) {
    this.loading = true;
    if (this.image_subscription) {
      this.image_subscription.unsubscribe();
    }
    this.myThumbnail = null;
    this.myFullresImage = null;
    this.selected_image = null;
    // const dialogRef = this.dialog.open(ProductImagesDialog, {
    //   height: '90%',
    //   width: '70%',
    //   data: { "id": id, "sku": sku, "name": name, "quantity": 1 }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
    this.image_subscription = this.productsService
      .getImages(id, 1)
      .subscribe((images) => {
        var product_images = images;
        this.cdr.markForCheck();

        if (product_images) {
          this.myThumbnail = this.getSrc(product_images[0].image);
          this.myFullresImage = this.getSrc(product_images[0].image);
          this.selected_image = product_images[0].id;
        } else {
          this.myThumbnail = null;
          this.myFullresImage = null;
          this.selected_image = null;
        }
      });

    this.loading = false;
  }
  getSrc(image) {
    let x = API_URL + "media/" + image;
    return x;
  }
  TransactionChange(event, id) {
    // console.log(event, id);
    // this.transaction = event.source.value;

    this.selected_transactions = [];
    this.transaction_id = id;
    this.saleProducts = new Array<any>();
    this.returnSaleProducts = new Array<any>();
    this.customer = "";
    this.customer_ref = "";
    this.shipment = "";
    this.customer_address = "";
    this.verified_by = null;
    this.selectedProducts = "";
    this.payment = "";
    this.results = "";
    this.pro = "";
    this.lengthVar = "";
    this.queryField = new FormControl();
    this.net_total = 0;
    this.amount_due = 0;
    this.vat_amount = 0;
    this.total_after_VAT = 0;
    this.products = [];
    this.quantityControl = new FormControl();
    this.productMultiCtrl = new FormControl();
    this.suspendedSaleLoaded = false;
    this.productMultiFilterCtrl = new FormControl();
    this.searching = false;
    this.filteredProductsMulti = new ReplaySubject<any>(1);
    this.customerMultiCtrl = new FormControl();
    this.customerMultiFilterCtrl = new FormControl();
    this.filteredCustomersMulti = new ReplaySubject<any>(1);
    this.payment_type = "Cash";
    this.payment_nominal_account = "780";
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchProduct(
            search,
            this.local.get("store_id"),
            this.in_stock_filter
          )
        )
      )
      .subscribe(
        (filteredProducts) => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
        (error) => {
          this.searching = false;
        }
      );
    this.customerMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchCustomerSales(
            search,
            this.local.get("store_id")
          )
        )
      )
      .subscribe(
        (filteredCustomers) => {
          console.log("2");
          this.searching = false;
          this.filteredCustomersMulti.next(filteredCustomers);
        },
        (error) => {
          this.searching = false;
        }
      );
    if (event.source) {
      this.transactionsService
        .getTransaction(event.source.value.transaction_id)
        .subscribe((t_details) => {
          this.transaction = t_details["transaction"];
          console.log("Code added here");
          console.log(this.transaction);
          this.transaction_loaded = true;
          this.transaction_number =
            t_details["transaction"]["transaction_number"];
          this.customer = t_details["transaction"]["customer"];
          t_details["transaction_details"].forEach((element) => {
            let temp = {};
            element["product"] = {
              product_id: element["product_id"],
              sku: element["sku"],
              alternate_sku: element["alternate_sku"],
              product_name: element["product_name"],
              product_description: element["product_description"],
              category_id: element["category_id"],
              brand: element["brand"],
              upc: element["upc"],
              price_per_unit: element["price_sold_for"] / 1.2,
              reorder_level: element["reorder_level"],
              deleted: element["deleted"],
              cost: element["cost"],
              default_price: element["price_sold_for"] / 1.2,
              best_price: element["price_sold_for"] / 1.2,
              related: element["related"],
            };
            temp["product"] = element["product"];
            temp["product"]["best_price"] = <number>(
              temp["product"]["best_price"]
            );
            temp["product"]["default_price"] = <number>(
              temp["product"]["default_price"]
            );
            temp["product"]["price_per_unit"] = <number>(
              temp["product"]["price_per_unit"]
            );
            let pro: PromotionsElement = {
              promotion_id: "",
              product_id: temp["product"].product_id,
              sku: temp["product"].sku,
              alternate_sku: temp["product"].alternate_sku,
              product_name: temp["product"].product_name,
              product_description: temp["product"].product_description,
              category: temp["product"].category_id,
              brand: temp["product"].brand,
              price_per_unit: <number>temp["product"].price_per_unit,
              start_date: Date,
              end_date: Date,
              discount: 0,
              comments: "",
            };
            let resu = pro;
            temp["promotion"] = resu;
            temp["quantity"] =
              element.quantity_sold - element.quantity_returned;
            temp["quantity_sold"] = element.quantity_sold;
            temp["quantity_returned"] = element.quantity_returned;
            temp["net_total"] =
              <number>temp["quantity"] *
              (temp["price_on_promotion"]
                ? <number>temp["price_on_promotion"]
                : <number>temp["product"].price_per_unit);
            temp["net_total"] =
              temp["net_total"] + (temp["net_total"] * 20) / 100;
            temp["price_per_unit"] = <number>temp["product"].price_per_unit;
            temp["default_price"] = <number>temp["product"].default_price;
            temp["best_price"] = <number>temp["product"].best_price;
            temp["display_price"] = <number>(
              temp["product"][
                this.customer && this.customer.price_band
                  ? this.customer.price_band
                  : temp["product"]["price_per_unit"]
                  ? "price_per_unit"
                  : temp["product"]["best_price"]
                  ? "best_price"
                  : temp["product"]["default_price"]
                  ? "default_price"
                  : 0.0
              ]
            );
            // temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
            temp["price_on_promotion"] =
              <number>temp["display_price"] +
              (<number>temp["display_price"] * 20) / 100 -
              <number>temp["promotion"]["discount"];
            temp["display_vat_inc"] =
              <number>temp["display_price"] +
              (<number>temp["display_price"] * 20) / 100;
            temp["display_total"] =
              <number>temp["price_on_promotion"] * <number>temp["quantity"];
            temp["discounted_price"] =
              <number>temp["display_price"] -
              <number>temp["promotion"]["discount"] / 1.2;
            temp["restocking_fee"] = 0;
            temp["restock_percent"] = 0;
            this.saleProducts.push(temp);
          });
          this.net_total = 0;
          for (let i = 0; i < this.returnSaleProducts.length; i++) {
            this.net_total =
              this.net_total +
              <number>this.returnSaleProducts[i]["discounted_price"] *
                <number>this.returnSaleProducts[i]["quantity"];
          }
          this.vat_amount = <number>((this.net_total * 20) / 100);
          this.total_after_VAT = <number>(
            (this.net_total +
              this.vat_amount -
              this.total_discount -
              this.restocking_fee)
          );
          this.amount_due = this.total_after_VAT;
          console.log("Before For Loop");
          console.log(this.checked);
          for (var ind = 0; ind < this.saleProducts.length; ind++) {
            this.checked[ind] = false;
          }
          console.log("After For Loop");
          console.log(this.checked);
        });

      if (this.currentUser.user_type != "admin") {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, "s");
      } else {
        this.store_id =
          this.local.get("store_id") == "all"
            ? this.currentUser.store_id
            : this.local.get("store_id");
      }
      // this.saleProducts =
    }

    console.log("Testing 123");
    console.log(this.checked);
  }
  confirmReturn() {

    this.total_after_VAT = Math.round(this.total_after_VAT * 100) / 100;
    this.amount_due = Math.round(this.amount_due * 100) / 100;
    console.log("Total: ", this.total_after_VAT)
    console.log("Payment: ", this.cashback)
    console.log("Amount Due: ", this.amount_due)
    console.log("Original Amount Due: ", this.original_amount_due_for_transaction)
    console.log("Invoice amount paid: ", this.transaction.total - this.transaction.payable )

    this.returnSaleProducts.forEach((element) => {
      if (element.quantity > 0) {
        this.quantityToReturn = true;
      } else {
        this.quantityToReturn = false;
      }
    });

    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    if (!this.customer) {
      this.salesService
        .getDefaultCustomer()
        .toPromise()
        .then((data) => {
          this.customer = data[0];
        });
    }
    this.companiesService.getCompany(this.store_id).subscribe((data) => {
      this.company = data ? data[0] : false;
    });
    this.storesService
      .getStore(this.store_id)
      .toPromise()
      .then((data) => {
        this.store = data[0];
      });

    if (this.payment_type == "To Pay") {
      if (this.payment > 0) {
        // this.toasterService.error()
        this.toasterService.error(
          "Setting Payment amount to 0. To Pay cannot have a payment.",
          "Payment Mehod!",
          {
            timeOut: 5000,
          }
        );
        this.payment = 0;
        //this.amount_due = this.total_after_VAT - this.total_discount - this.payment;
      }
    }
    if (this.returnSaleProducts.length != 0 && !this.quantityToReturn) {
      this.toasterService.warning("Nothing to return.", "Sorry!", {
        timeOut: 5000,
      });
    }

    // console.log("Hehehe");
    // console.log(this.quantityToReturn);
    else if (this.returnSaleProducts.length == 0) {
      console.log("Hello");
      this.toasterService.error(
        "Please select an item to return",
        "Nothing Slected..!!!",
        {
          timeOut: 5000,
        }
      );
    }

    if (
      this.customer.first_name.toLowerCase().indexOf("cash") > -1 &&
      this.transaction.payable < this.amount_due
    ) {
      console.log("Here Now");
      console.log(this.customer.first_name.toLowerCase().indexOf("cash"));
      this.openCashTransactionsDialog();
    } else {

      if(this.cashback == null || this.cashback == "" || this.cashback == 0){
        
        console.log("Okay Testing");
      console.log(this.returnSaleProducts);
      this.confirmationDialogService
        .confirm(
          "Please Confirm...",
          `Customer will be credited by £${this.amount_due} to their account.`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                transaction_number: this.transaction_number,
                transaction: this.transaction,
                transactions_to_clear: this.transactions_to_clear,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
                transaction_id: this.transaction_id,
                restocking_fee: this.restocking_fee,
                checked: this.checked,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
            console.log(this.local.get("tempSale"));
          }
        })
        .catch((error) => console.log(error));

      }
      else if( (this.transaction.total - this.transaction.payable) ==  this.total_after_VAT ){
      console.log("When invoice was fully paid earlier");
      console.log(this.original_amount_due_for_transaction);
      console.log(this.returnSaleProducts);
      this.confirmationDialogService
        .confirm(
          "Please Confirm...",
          `Customer has been refunded £${this.cashback} amount in ${this.payment_type}`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                transaction_number: this.transaction_number,
                transaction: this.transaction,
                transactions_to_clear: this.transactions_to_clear,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
                transaction_id: this.transaction_id,
                restocking_fee: this.restocking_fee,
                checked: this.checked,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
            console.log(this.local.get("tempSale"));
          }
        })
        .catch((error) => console.log(error));



      }

      else if(this.cashback == this.total_after_VAT && this.total_after_VAT <= this.original_amount_due_for_transaction){

      console.log("When cashback is equal to total and is less than or equal to amount due");
      console.log(this.returnSaleProducts);
      this.confirmationDialogService
        .confirm(
          "Please Confirm...",
          `Customer has been refunded £${this.cashback} amount in ${this.payment_type}`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                transaction_number: this.transaction_number,
                transaction: this.transaction,
                transactions_to_clear: this.transactions_to_clear,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
                transaction_id: this.transaction_id,
                restocking_fee: this.restocking_fee,
                checked: this.checked,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
            console.log(this.local.get("tempSale"));
          }
        })
        .catch((error) => console.log(error));

      } else if(this.cashback < this.total_after_VAT && this.cashback <= this.original_amount_due_for_transaction){
        
      console.log("Okay Testing");
      console.log(this.returnSaleProducts);
      this.confirmationDialogService
        .confirm(
          "Please Confirm...",
          `Customer has been refunded £${this.cashback} amount in ${this.payment_type} and 
          £${Math.round((this.original_amount_due_for_transaction - this.cashback) * 100) / 100} amount will be credited to their account.`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                transaction_number: this.transaction_number,
                transaction: this.transaction,
                transactions_to_clear: this.transactions_to_clear,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
                transaction_id: this.transaction_id,
                restocking_fee: this.restocking_fee,
                checked: this.checked,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
            console.log(this.local.get("tempSale"));
          } 
        })
        .catch((error) => console.log(error));


      }

    }
  }
  goToEnterItemField() {
    document.getElementById("product_search_bar").click();
  }
  goToDiscount() {
    document.getElementById("discount_" + this.last_added_product).click();
  }
  goToQuantity() {
    document.getElementById("quantity_" + this.last_added_product).click();
  }

  /*   collectionChange(event, i) {
    console.log("Inside Collected");
    console.log(event);
    if (this.saleProducts[i].collected) {
      // console.log("NO");
      // console.log(this.saleProducts[i]);
      this.saleProducts[i].quantity_delivered = this.saleProducts[i].quantity;
      console.log("YO");
      console.log(this.saleProducts[i].quantity_delivered);
    }
    else {
      this.saleProducts[i].quantity_delivered = 0;
    }
  } */

  deliverQuantityChange(event, i) {
    console.log("Inside Dliver Quantity Change");
    let input = <number>event;
    let product = this.saleProducts[i].product.product_id;
    let id = "quantityCollected_" + product;
    let element;
    if (this.saleProducts[i].collected > this.saleProducts[i].quantity) {
      this.toasterService.error(
        "Quantity to deliver cannot exceed quantity sold.",
        "Incorrect value entered!",
        {
          timeOut: 5000,
        }
      );
      this.saleProducts[i].collected = this.saleProducts[i].quantity;
      element = document.getElementById(id);
      element.value = this.saleProducts[i].collected;
    }
    this.saleProducts[i].quantity_delivered =
      this.saleProducts[i].quantity - this.saleProducts[i].collected;
    console.log(this.saleProducts[i]);
    // for (let i = 0; i < this.saleProducts.length; i++) {
    //   console.log(this.saleProducts[i]);

    //   console.log(this.saleProducts[i]);
    // }
  }

  /*   notCollected() {
    console.log("Inside Not Collected");
    for (let i = 0; i < this.saleProducts.length; i++) {
      this.saleProducts[i].collected = !this.saleProducts[i].collected;
      if (this.saleProducts[i].collected) {
        this.saleProducts[i].quantity_delivered = this.saleProducts[i].quantity;
      }
    }
  } */

  change(event) {
    console.log("Now");
    let p_id = event.source.value.product_id;
    let already_added = false;
    let i = 0;
    this.saleProducts.forEach((element) => {
      if (element["product"]["product_id"] == p_id && event.isUserInput) {
        already_added = true;
        this.saleProducts[i]["quantity"]++;
        // Added Here
        // this.saleProducts[i]["display_price"] = <number>(this.saleProducts[i]["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : (this.saleProducts[i]["product"]["price_per_unit"]) ? "price_per_unit" : (this.saleProducts[i]["product"]["best_price"]) ? "best_price" : (this.saleProducts[i]["product"]["default_price"]) ? "default_price" : 0.00]);
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"];
        this.saleProducts[i]["display_vat_inc"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100;
        this.saleProducts[i]["display_price"] =
          <number>this.saleProducts[i]["display_vat_inc"] / (1 + 20 / 100);
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["quantity"] *
          (this.saleProducts[i]["price_on_promotion"]
            ? <number>this.saleProducts[i]["price_on_promotion"]
            : <number>this.saleProducts[i]["product"].price_per_unit);
        this.saleProducts[i]["net_total"] = <number>(
          (this.saleProducts[i]["net_total"] +
            (this.saleProducts[i]["net_total"] * 20) / 100)
        );
        this.saleProducts[i]["net_total"] = this.saleProducts[i]["net_total"];
      }
      i++;
    });

    this.last_added_product = event.source.value.product_id;

    if (event.isUserInput && !already_added) {
      var element = event.source.value;
      console.log("Huehuehue");
      console.log(event.source);
      if (event.source.selected) {
        console.log("Inside If");
        let temp = {};
        temp["product"] = element;
        temp["product"]["best_price"] = <number>temp["product"]["best_price"];
        temp["product"]["default_price"] = <number>(
          temp["product"]["default_price"]
        );
        temp["product"]["price_per_unit"] = <number>(
          temp["product"]["price_per_unit"]
        );
        this.productsService
          .getProductCategoryName(element.category_id)
          .toPromise()
          //output: 'First Example'
          .then((result) => {
            temp["category"] = result[0];
          });
        // console.log("Testing Hassan");
        // console.log(this.customer);
        // console.log(this.customer.length);
        // console.log(typeof(this.customer));
        // console.log("End testing hassan");
        if (typeof this.customer === "object") {
          console.log("Code Testing");
          var cash_customer = [1, 395, 396];
          var value = cash_customer.includes(this.customer.customer_id);
          if (value === false) {
            this.salesService
              .getSalesTransactionByCustomerID(
                this.customer.customer_id,
                element.product_id
              )
              .toPromise()
              .then((result_dp) => {
                this.promotionsService
                  .getPromotionByProductID(element.product_id)
                  .toPromise()
                  //output: 'First Example'
                  .then((result) => {
                    let pro: PromotionsElement = {
                      promotion_id: "",
                      product_id: temp["product"].product_id,
                      sku: temp["product"].sku,
                      alternate_sku: temp["product"].alternate_sku,
                      product_name: temp["product"].product_name,
                      product_description: temp["product"].product_description,
                      category: temp["product"].category_id,
                      brand: temp["product"].brand,
                      price_per_unit: <number>temp["product"].price_per_unit,
                      start_date: Date,
                      end_date: Date,
                      discount: 0,
                      comments: "",
                    };
                    let resu = result[0] ? result[0] : pro;
                    temp["promotion"] = resu;
                    temp["quantity"] = 1;
                    temp["net_total"] =
                      <number>temp["quantity"] *
                      (temp["price_on_promotion"]
                        ? <number>temp["price_on_promotion"]
                        : <number>temp["product"].price_per_unit);
                    temp["net_total"] =
                      temp["net_total"] + (temp["net_total"] * 20) / 100;
                    temp["price_per_unit"] = <number>(
                      temp["product"].price_per_unit
                    );
                    temp["default_price"] = <number>(
                      temp["product"].default_price
                    );
                    temp["best_price"] = <number>temp["product"].best_price;
                    console.log("result_dp");
                    console.log(result_dp);
                    temp["display_price"] = result_dp[0];
                    temp["prev_prices"] = result_dp[1];
                    if (result_dp[0] == 0)
                      temp["display_price"] = <number>(
                        temp["product"][
                          this.customer && this.customer.price_band
                            ? this.customer.price_band
                            : temp["product"]["price_per_unit"]
                            ? "price_per_unit"
                            : temp["product"]["best_price"]
                            ? "best_price"
                            : temp["product"]["default_price"]
                            ? "default_price"
                            : 0.0
                        ]
                      );
                    //  temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
                    temp["price_on_promotion"] =
                      <number>temp["display_price"] +
                      (<number>temp["display_price"] * 20) / 100 -
                      <number>temp["promotion"]["discount"];
                    temp["display_vat_inc"] =
                      <number>temp["display_price"] +
                      (<number>temp["display_price"] * 20) / 100;
                    temp["display_total"] =
                      <number>temp["price_on_promotion"] *
                      <number>temp["quantity"];
                    temp["discounted_price"] =
                      <number>temp["display_price"] -
                      <number>temp["promotion"]["discount"] / 1.2;
                    temp["restocking_fee"] = 0;
                    temp["restock_percent"] = 0;
                    temp["collected"] = 0;
                    temp["quantity_delivered"] =
                      temp["quantity"] - temp["collected"];

                    if (this.next_item_is == undefined) {
                      this.saleProducts.push(temp);
                    } else {
                      this.saleProducts.splice(this.next_item_is, 0, temp);
                      this.next_item_is = undefined;
                    }

                    this.net_total = 0;
                    for (let i = 0; i < this.saleProducts.length; i++) {
                      this.net_total =
                        this.net_total +
                        <number>this.saleProducts[i]["discounted_price"] *
                          <number>this.saleProducts[i]["quantity"];
                    }

                    this.vat_amount = <number>((this.net_total * 20) / 100);
                    this.total_after_VAT = <number>(
                      (this.net_total + this.vat_amount)
                    );
                    this.amount_due = this.total_after_VAT;
                  });
              });
          } else {
            this.promotionsService
              .getPromotionByProductID(element.product_id)
              .toPromise()
              //output: 'First Example'
              .then((result) => {
                let pro: PromotionsElement = {
                  promotion_id: "",
                  product_id: temp["product"].product_id,
                  sku: temp["product"].sku,
                  alternate_sku: temp["product"].alternate_sku,
                  product_name: temp["product"].product_name,
                  product_description: temp["product"].product_description,
                  category: temp["product"].category_id,
                  brand: temp["product"].brand,
                  price_per_unit: <number>temp["product"].price_per_unit,
                  start_date: Date,
                  end_date: Date,
                  discount: 0,
                  comments: "",
                };
                let resu = result[0] ? result[0] : pro;
                temp["promotion"] = resu;
                temp["quantity"] = 1;
                temp["net_total"] =
                  <number>temp["quantity"] *
                  (temp["price_on_promotion"]
                    ? <number>temp["price_on_promotion"]
                    : <number>temp["product"].price_per_unit);
                temp["net_total"] =
                  temp["net_total"] + (temp["net_total"] * 20) / 100;
                temp["price_per_unit"] = <number>temp["product"].price_per_unit;
                temp["default_price"] = <number>temp["product"].default_price;
                temp["best_price"] = <number>temp["product"].best_price;
                temp["display_price"] = <number>(
                  temp["product"][
                    this.customer && this.customer.price_band
                      ? this.customer.price_band
                      : temp["product"]["price_per_unit"]
                      ? "price_per_unit"
                      : temp["product"]["best_price"]
                      ? "best_price"
                      : temp["product"]["default_price"]
                      ? "default_price"
                      : 0.0
                  ]
                );
                //  temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
                temp["price_on_promotion"] =
                  <number>temp["display_price"] +
                  (<number>temp["display_price"] * 20) / 100 -
                  <number>temp["promotion"]["discount"];
                temp["display_vat_inc"] =
                  <number>temp["display_price"] +
                  (<number>temp["display_price"] * 20) / 100;
                temp["display_total"] =
                  <number>temp["price_on_promotion"] * <number>temp["quantity"];
                temp["discounted_price"] =
                  <number>temp["display_price"] -
                  <number>temp["promotion"]["discount"] / 1.2;
                temp["restocking_fee"] = 0;
                temp["restock_percent"] = 0;
                temp["collected"] = 0;
                temp["quantity_delivered"] =
                  temp["quantity"] - temp["collected"];
                temp["prev_prices"] = [];
                if (this.next_item_is == undefined) {
                  this.saleProducts.push(temp);
                } else {
                  this.saleProducts.splice(this.next_item_is, 0, temp);
                  this.next_item_is = undefined;
                }
                this.net_total = 0;
                for (let i = 0; i < this.saleProducts.length; i++) {
                  this.net_total =
                    this.net_total +
                    <number>this.saleProducts[i]["discounted_price"] *
                      <number>this.saleProducts[i]["quantity"];
                }

                this.vat_amount = <number>((this.net_total * 20) / 100);
                this.total_after_VAT = <number>(
                  (this.net_total + this.vat_amount)
                );
                this.amount_due = this.total_after_VAT;
              });
          }
        } else {
          this.promotionsService
            .getPromotionByProductID(element.product_id)
            .toPromise()
            //output: 'First Example'
            .then((result) => {
              let pro: PromotionsElement = {
                promotion_id: "",
                product_id: temp["product"].product_id,
                sku: temp["product"].sku,
                alternate_sku: temp["product"].alternate_sku,
                product_name: temp["product"].product_name,
                product_description: temp["product"].product_description,
                category: temp["product"].category_id,
                brand: temp["product"].brand,
                price_per_unit: <number>temp["product"].price_per_unit,
                start_date: Date,
                end_date: Date,
                discount: 0,
                comments: "",
              };
              let resu = result[0] ? result[0] : pro;
              temp["promotion"] = resu;
              temp["quantity"] = 1;
              temp["net_total"] =
                <number>temp["quantity"] *
                (temp["price_on_promotion"]
                  ? <number>temp["price_on_promotion"]
                  : <number>temp["product"].price_per_unit);
              temp["net_total"] =
                temp["net_total"] + (temp["net_total"] * 20) / 100;
              temp["price_per_unit"] = <number>temp["product"].price_per_unit;
              temp["default_price"] = <number>temp["product"].default_price;
              temp["best_price"] = <number>temp["product"].best_price;
              temp["display_price"] = <number>(
                temp["product"][
                  this.customer && this.customer.price_band
                    ? this.customer.price_band
                    : temp["product"]["price_per_unit"]
                    ? "price_per_unit"
                    : temp["product"]["best_price"]
                    ? "best_price"
                    : temp["product"]["default_price"]
                    ? "default_price"
                    : 0.0
                ]
              );
              //  temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
              temp["price_on_promotion"] =
                <number>temp["display_price"] +
                (<number>temp["display_price"] * 20) / 100 -
                <number>temp["promotion"]["discount"];
              temp["display_vat_inc"] =
                <number>temp["display_price"] +
                (<number>temp["display_price"] * 20) / 100;
              temp["display_total"] =
                <number>temp["price_on_promotion"] * <number>temp["quantity"];
              temp["discounted_price"] =
                <number>temp["display_price"] -
                <number>temp["promotion"]["discount"] / 1.2;
              temp["restocking_fee"] = 0;
              temp["restock_percent"] = 0;
              temp["collected"] = 0;
              temp["quantity_delivered"] = temp["quantity"] - temp["collected"];
              temp["prev_prices"] = [];

              if (this.next_item_is == undefined) {
                this.saleProducts.push(temp);
              } else {
                this.saleProducts.splice(this.next_item_is, 0, temp);
                this.next_item_is = undefined;
              }
              this.net_total = 0;
              for (let i = 0; i < this.saleProducts.length; i++) {
                this.net_total =
                  this.net_total +
                  <number>this.saleProducts[i]["discounted_price"] *
                    <number>this.saleProducts[i]["quantity"];
              }

              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            });
        }
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      } else {
        console.log("Inside Else");
        for (let i = 0; i < this.saleProducts.length; i++) {
          if (
            this.saleProducts[i]["product"].product_id ==
            event.source.value.product_id
          ) {
            this.productMultiCtrl.value.splice(i, 1);
            this.saleProducts.splice(i, 1);
            this.net_total = 0;
            this.amount_due = 0;
            for (let p of this.saleProducts) {
              this.net_total =
                this.net_total +
                <number>p["discounted_price"] * <number>p["quantity"];
            }
            this.vat_amount = <number>((this.net_total * 20) / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
            this.amount_due = this.total_after_VAT;
          }
        }
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      }
    } else {
      if (already_added) {
        console.log("Test Again");
        this.next_item_is = undefined;
        this.toasterService.success("Quantity Updated", "Updated!", {
          timeOut: 5000,
        });
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.saleProducts) {
          p["quantity_delivered"] = p["quantity"] - p["collected"];
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
          console.log(p);
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      }
    }
    if (this.saleProducts.length > 16) {
      // console.log(this.saleProducts.length,  document.getElementById("register").children);
      // var elmnt = document.getElementById("register").children[1].children[this.saleProducts.length-12];
      // elmnt.scrollIntoView();
      window.scrollTo(0, 150 + (this.saleProducts.length - 16) * 36);
    } else {
      window.scrollTo(0, 150);
    }
  }

  nextAddAfter(i, event) {
    event.preventDefault();
    this.next_item_is = i;
    this.goToEnterItemField();
  }

  openedChange(event) {
    if (!event) {
      this.productMultiFilterCtrl = new FormControl();
      this.filteredProductsMulti = new ReplaySubject<any>(1);
      this.productMultiFilterCtrl.valueChanges
        .pipe(
          filter((search) => !!search),
          tap(() => (this.searching = true)),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap((search) =>
            this._searchService.searchProduct(
              search,
              this.local.get("store_id"),
              this.in_stock_filter
            )
          )
        )
        .subscribe(
          (filteredProducts) => {
            this.searching = false;
            this.filteredProductsMulti.next(filteredProducts);
          },
          (error) => {
            this.searching = false;
          }
        );

      setTimeout(() => {
        let id = "price_" + this.last_added_product;
        // document.getElementById(id).click();
        this.cdr.detectChanges();
        let vid = "price_popover_" + this.last_added_product;
        // document.getElementById(vid).click();
      }, 550);
    }
  }
  openedTransactionChange(event) {
    if (!event) {
      this.transactionMultiFilterCtrl = new FormControl();
      this.filteredTransactionsMulti = new ReplaySubject<any>(1);
      this.transactionMultiFilterCtrl.valueChanges
        .pipe(
          filter((search) => !!search),
          tap(() => (this.searching = true)),
          takeUntil(this._onDestroy),
          debounceTime(200),
          switchMap((search) =>
            this._searchService.searchTransactionSales(
              search,
              this.local.get("store_id")
            )
          )
        )
        .subscribe(
          (filteredTransactions) => {
            this.searching = false;
            this.filteredTransactionsMulti.next(filteredTransactions);
          },
          (error) => {
            this.searching = false;
          }
        );
    }
  }

  editSale() {
    this.edit = true;
    this.return = false;
    this.receipt = false;
    this.cancel();
  }

  returnSale() {
    this.return = true;
    this.receipt = false;
    this.edit = false;
    this.cancel();
  }
  receiptSale() {
    console.log(this.customer);
    var customer = this.customer;
    this.receipt = true;
    this.return = false;
    this.edit = false;
    this.cancel();
    this.customer = customer;
    console.log(this.customer);
    if (
      this.customer != null &&
      this.customer != "" &&
      this.customer != false
    ) {
      this.salesService
        .getCustomerOutstanding(this.customer)
        .subscribe((data) => {
          this.transactions = data;
        });
    }
  }
  SaleType() {
    this.receipt = false;
    this.return = false;
    this.edit = false;
    console.log(this.edit);
    this.cancel();
  }

  InStockToggle() {
    this.in_stock_filter = !this.in_stock_filter;
    if (this.in_stock_filter) {
      (
        document.getElementById("in-stock-btn") as HTMLElement
      ).style.backgroundColor = "#009bce";
      (document.getElementById("in-stock-btn") as HTMLElement).style.color =
        "white";
    } else {
      (
        document.getElementById("in-stock-btn") as HTMLElement
      ).style.backgroundColor = "white";
      (document.getElementById("in-stock-btn") as HTMLElement).style.color =
        "black";
    }
  }
  mouseEnter(event) {
    event.fromElement.disabled = true;
  }
  mouseLeave(event) {}
  confirmReceipt() {
    console.log(this.selected_transactions);
    console.log(this.total_after_VAT);
    this.router.navigate["/sales"];
    // this.amount_due = this.total_after_VAT - this.payment;
    if (!this.customer) {
      this.toasterService.error(
        "Please make sure to select a customer.",
        "Customer not entered!",
        {
          timeOut: 5000,
        }
      );
      this.transactions = [];
    }
    if (this.selected_transactions.length == 0 && this.payment) {
      this.toasterService.error(
        "Please make sure you have selected an invoice.",
        "No invoice selected!",
        {
          timeOut: 5000,
        }
      );
    }
    if (!this.payment && this.customer) {
      this.toasterService.error(
        "Please make sure to enter a payment amount.",
        "No payment entered!",
        {
          timeOut: 5000,
        }
      );
    }
    if (
      this.customer &&
      this.payment &&
      this.selected_transactions.length != 0
    ) {
      let data = {
        transactions: this.selected_transactions,
        customer: this.customer,
        type: this.payment_type,
        payment: this.payment,
        payment_type: this.payment_type,
        payment_nominal_account: this.payment_nominal_account,
        store_id: this.store_id,
        user: this.currentUser,
        comments: this.comments ? this.comments : "",
        payment_mode: "receipt",
        card: this.card,
        cash: this.cash,
        customer_ref: this.customer_ref,
        net_nominal_account: this.net_nominal_account,
        returns: this.selected_returns,
      };
      this.confirmationDialogService
        .confirm(
          "Submit Receipt",
          `Are you sure to make a payment of £` + this.payment + `?`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.salesService.submitReceipt(data).subscribe((res) => {
              if (res == "Ok") {
                this.cancel();
                this.receipt = false;
                this.toasterService.success(
                  "Receiving completed against customer!",
                  "Success!",
                  {
                    timeOut: 5000,
                  }
                );
                this.receiptSale();
                this.transactions = [];
              }
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }
  changeCustomer(event) {
    if (event.isUserInput) {
      var element = event.source.value;
      if (event.source.selected) {
        this.customer = element;
        console.log("First If");
        if (!this.receipt && !this.return) {
          this.checkOverLimit(this.customer);
        }
      }
    } else {
      var element = event.source.value;
      if (event.source.selected) {
        this.customer = element;
        console.log("First Else");
      }
    }
    this.net_total = 0;
    this.amount_due = 0;

    if (this.receipt && this.customer != "" && this.customer != false) {
      this.salesService
        .getCustomerOutstanding(this.customer)
        .subscribe((data) => {
          this.transactions = data["transactions"];
          console.log(this.transactions);
          this.returns = data["returns"];
        });
    } else {
      if (
        !this.customer.first_name.includes("Cash" && "Customer") &&
        this.customer
      ) {
        for (let p of this.saleProducts) {
          // p["display_price"] = <number>(p["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
          p["display_price"] = <number>(
            p["product"][
              this.customer && this.customer.price_band
                ? this.customer.price_band
                : p["product"]["price_per_unit"]
                ? "price_per_unit"
                : p["product"]["best_price"]
                ? "best_price"
                : p["product"]["default_price"]
                ? "default_price"
                : 0.0
            ]
          );
          p["price_on_promotion"] =
            <number>p["display_price"] +
            (<number>p["display_price"] * 20) / 100 -
            <number>p["promotion"]["discount"];
          p["display_vat_inc"] =
            <number>p["display_price"] +
            (<number>p["display_price"] * 20) / 100;
          p["display_total"] =
            <number>p["price_on_promotion"] * <number>p["quantity"];
          p["discounted_price"] =
            <number>p["display_price"] -
            <number>p["promotion"]["discount"] / 1.2;
          p["net_total"] =
            <number>p["quantity"] * <number>p["price_on_promotion"] +
            (<number>p["quantity"] * <number>p["price_on_promotion"] * 20) /
              100;
          p["net_total"] = p["net_total"];
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        for (let p of this.saleProducts) {
          p["price_on_promotion"] =
            <number>p["product"]["price_per_unit"] -
            (<number>p["product"]["price_per_unit"] / 100) *
              <number>p["promotion"]["discount"];
          p["net_total"] =
            <number>p["quantity"] * <number>p["price_on_promotion"] +
            (<number>(p["quantity"] * p["price_on_promotion"]) * 20) / 100;
          p["net_total"] = p["net_total"];
          p["display_price"] = <number>(
            p["product"][
              this.customer && this.customer.price_band
                ? this.customer.price_band
                : p["product"]["price_per_unit"]
                ? "price_per_unit"
                : p["product"]["best_price"]
                ? "best_price"
                : p["product"]["default_price"]
                ? "default_price"
                : 0.0
            ]
          );
          // p["display_price"] = <number>(p["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
          p["price_on_promotion"] =
            <number>p["display_price"] +
            (<number>p["display_price"] * 20) / 100 -
            <number>p["promotion"]["discount"];
          p["display_vat_inc"] =
            <number>p["display_price"] +
            (<number>p["display_price"] * 20) / 100;
          p["display_total"] =
            <number>p["price_on_promotion"] * <number>p["quantity"];
          p["discounted_price"] =
            <number>p["display_price"] -
            <number>p["promotion"]["discount"] / 1.2;
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      }
    }
  }
  quantityChange(event, i) {
    this.returnSaleProducts.forEach((element) => {
      if (element.quantity > 0) {
        this.quantityToReturn = true;
      } else {
        this.quantityToReturn = false;
      }
    });
    if (this.return) {
      if (
        <number>event <=
        this.saleProducts[i]["quantity_sold"] -
          this.saleProducts[i]["quantity_returned"]
      ) {
        this.saleProducts[i]["quantity"] = <number>event * 1;
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] * 1 +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"] * 1;
        this.saleProducts[i]["display_vat_inc"] =
          <number>this.saleProducts[i]["display_price"] * 1 +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100;
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] * 1 -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["net_total"] =
          <number>event *
          (this.saleProducts[i]["price_on_promotion"]
            ? <number>this.saleProducts[i]["price_on_promotion"]
            : <number>this.saleProducts[i]["product"]["price_per_unit"]);
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["net_total"] +
          (<number>this.saleProducts[i]["net_total"] * 20) / 100;
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.returnSaleProducts) {
          this.net_total =
            this.net_total * 1 +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        console.log("Test here now");
        console.log(this.restocking_fee);
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(
          (this.net_total * 1 + this.vat_amount * 1 - this.restocking_fee)
        );
        this.amount_due = this.total_after_VAT;
        this.payment = 0;
      } else {
        console.log("Inside Else");
        console.log(<number>event);
        console.log(
          this.saleProducts[i]["quantity_sold"] -
            this.saleProducts[i]["quantity_returned"]
        );

        this.saleProducts[i]["quantity"] =
          this.saleProducts[i]["quantity_sold"] -
          this.saleProducts[i]["quantity_returned"];

        this.toasterService.error(
          "Return quantity either returned already or more than product sold.",
          "Return quantity Incorrect",
          {
            timeOut: 5000,
          }
        );
        // this.saleProducts[i]["quantity"] = <number>(event);
        // console.log(this.saleProducts[i]["product"]["product_id"]);
        // // console.log(this.saleProducts[i]["quantity"]);
        // var k = "quantity_" + this.saleProducts[i]["product"]["product_id"];
        // console.log(document.getElementById(k));
      }
    } else {
      console.log("Here");
      this.saleProducts[i]["quantity"] = <number>event * 1;
      // this.saleProducts[i]["collected"] = <number>(event) * 1;
      this.saleProducts[i]["quantity_delivered"] = <number>(
        (this.saleProducts[i]["quantity"] - this.saleProducts[i]["collected"])
      );
      this.saleProducts[i]["price_on_promotion"] =
        <number>this.saleProducts[i]["display_price"] * 1 +
        (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
        <number>this.saleProducts[i]["promotion"]["discount"] * 1;
      this.saleProducts[i]["display_vat_inc"] =
        <number>this.saleProducts[i]["display_price"] * 1 +
        (<number>this.saleProducts[i]["display_price"] * 20) / 100;
      this.saleProducts[i]["display_total"] =
        <number>this.saleProducts[i]["price_on_promotion"] *
        <number>this.saleProducts[i]["quantity"];
      this.saleProducts[i]["discounted_price"] =
        <number>this.saleProducts[i]["display_price"] * 1 -
        <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
      this.saleProducts[i]["net_total"] =
        <number>event *
        (this.saleProducts[i]["price_on_promotion"]
          ? <number>this.saleProducts[i]["price_on_promotion"]
          : <number>this.saleProducts[i]["product"]["price_per_unit"]);
      this.saleProducts[i]["net_total"] =
        <number>this.saleProducts[i]["net_total"] +
        (<number>this.saleProducts[i]["net_total"] * 20) / 100;
      this.net_total = 0;
      this.amount_due = 0;
      for (let p of this.saleProducts) {
        this.net_total =
          this.net_total +
          <number>p["discounted_price"] * <number>p["quantity"];
      }
      this.vat_amount = <number>((this.net_total * 20) / 100);
      this.total_after_VAT = <number>(this.net_total * 1 + this.vat_amount);
      this.amount_due = this.total_after_VAT;
      this.payment = 0;
      console.log(this.saleProducts[i]);
    }
  }
  vatChange(event, i) {}
  priceChange(event, i) {
    console.log("Return Value");
    console.log(this.return);
    if (this.return) {
      let tempPrice =
        <number>this.returnSaleProducts[i]["product"]["price_per_unit"] * 1;
      if (
        this.returnSaleProducts[i]["product"]["cost"] +
          (this.returnSaleProducts[i]["product"]["cost"] * 20) / 100 <
        event.target.valueAsNumber
      ) {
        // - (<number>(this.returnSaleProducts[i]["default_price"]) * <number>(this.returnSaleProducts[i]["promotion"]["discount"]) / 100)
        this.returnSaleProducts[i]["display_vat_inc"] = <number>(
          event.target.valueAsNumber
        );
        this.returnSaleProducts[i]["price_on_promotion"] =
          <number>this.returnSaleProducts[i]["display_price"] +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
          <number>this.returnSaleProducts[i]["promotion"]["discount"];
        this.returnSaleProducts[i]["display_price"] =
          <number>this.returnSaleProducts[i]["display_vat_inc"] / 1.2;
        console.log("Huehuehue");
        console.log(this.returnSaleProducts[i]["product"]);
        console.log(this.returnSaleProducts[i]["display_vat_inc"]);
        this.returnSaleProducts[i]["display_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["discounted_price"] =
          <number>this.returnSaleProducts[i]["display_price"] -
          <number>this.returnSaleProducts[i]["promotion"]["discount"] / 1.2;
        this.returnSaleProducts[i]["product"]["price_per_unit"] = <number>(
          event.target.valueAsNumber
        );
        this.returnSaleProducts[i]["net_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["net_total"] = <number>(
          this.returnSaleProducts[i]["net_total"]
        );
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.returnSaleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.returnSaleProducts[i]["display_vat_inc"] = <number>(
                event.target.valueAsNumber
              ); //  Code Added
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_vat_inc"] / 1.2; //  Code Added
              this.returnSaleProducts[i]["display_price"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              console.log(this.returnSaleProducts[i]["display_vat_inc"]);
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] = <number>(
                this.returnSaleProducts[i]["net_total"]
              );
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            } else {
              console.log("More Testing Here!!!");
              this.returnSaleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.returnSaleProducts[i]["product"].best_price
                  : this.returnSaleProducts[i]["product"].price_per_unit;
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"];
              this.returnSaleProducts[i]["display_vat_inc"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] =
                this.returnSaleProducts[i]["net_total"];
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      console.log("Another Testing in PriceChange!!!");
      let tempPrice =
        <number>this.saleProducts[i]["product"]["price_per_unit"] * 1;
      if (
        this.saleProducts[i]["product"]["cost"] +
          (this.saleProducts[i]["product"]["cost"] * 20) / 100 <
        event.target.valueAsNumber
      ) {
        // - (<number>(this.saleProducts[i]["default_price"]) * <number>(this.saleProducts[i]["promotion"]["discount"]) / 100)
        console.log(this.saleProducts[i]["product"]["default_price"]);
        console.log("Event Value");
        console.log(event.target.valueAsNumber);
        console.log("Product");
        console.log(this.saleProducts[i]["product"]);
        this.saleProducts[i]["display_price"] = event.target.valueAsNumber; // Code Added
        this.saleProducts[i]["display_vat_inc"] =
          <number>this.saleProducts[i]["display_price"] * 1.2; //  Code Added
        // this.saleProducts[i]["display_price"] = <number>(this.saleProducts[i]["display_vat_inc"]) / 1.2;                                  //  Code Added
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"];
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["product"]["price_per_unit"] = <number>(
          event.target.valueAsNumber
        );
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["net_total"] = <number>(
          this.saleProducts[i]["net_total"]
        );

        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.saleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.saleProducts[i]["display_price"] = <number>(
                event.target.valueAsNumber
              ); //  Code Added
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"];
              this.saleProducts[i]["display_vat_inc"] =
                <number>this.saleProducts[i]["display_price"] * 1.2; //  Code Added
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] = <number>(
                this.saleProducts[i]["net_total"]
              );
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            } else {
              this.saleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.saleProducts[i]["product"].best_price
                  : this.saleProducts[i]["product"].price_per_unit;
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"];
              this.saleProducts[i]["display_vat_inc"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100;
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] =
                this.saleProducts[i]["net_total"];
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
  vatPriceChange(event, i) {
    console.log("Return Value");
    console.log(this.return);
    if (this.return) {
      let tempPrice =
        <number>this.returnSaleProducts[i]["product"]["price_per_unit"] * 1;
      if (
        this.returnSaleProducts[i]["product"]["cost"] +
          (this.returnSaleProducts[i]["product"]["cost"] * 20) / 100 <
        event.target.valueAsNumber
      ) {
        // - (<number>(this.returnSaleProducts[i]["default_price"]) * <number>(this.returnSaleProducts[i]["promotion"]["discount"]) / 100)
        this.returnSaleProducts[i]["display_vat_inc"] = <number>(
          event.target.valueAsNumber
        );
        this.returnSaleProducts[i]["price_on_promotion"] =
          <number>this.returnSaleProducts[i]["display_price"] +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
          <number>this.returnSaleProducts[i]["promotion"]["discount"];
        this.returnSaleProducts[i]["display_price"] =
          <number>this.returnSaleProducts[i]["display_vat_inc"] / 1.2;
        console.log("Huehuehue");
        console.log(this.returnSaleProducts[i]["product"]);
        console.log(this.returnSaleProducts[i]["display_vat_inc"]);
        this.returnSaleProducts[i]["display_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["discounted_price"] =
          <number>this.returnSaleProducts[i]["display_price"] -
          <number>this.returnSaleProducts[i]["promotion"]["discount"] / 1.2;
        this.returnSaleProducts[i]["product"]["price_per_unit"] = <number>(
          event.target.valueAsNumber
        );
        this.returnSaleProducts[i]["net_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["net_total"] = <number>(
          this.returnSaleProducts[i]["net_total"]
        );
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.returnSaleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.returnSaleProducts[i]["display_vat_inc"] = <number>(
                event.target.valueAsNumber
              ); //  Code Added
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_vat_inc"] / 1.2; //  Code Added
              this.returnSaleProducts[i]["display_price"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              console.log(this.returnSaleProducts[i]["display_vat_inc"]);
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] = <number>(
                this.returnSaleProducts[i]["net_total"]
              );
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            } else {
              console.log("Some More Testing Here!!!");
              this.returnSaleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.returnSaleProducts[i]["product"].best_price
                  : this.returnSaleProducts[i]["product"].price_per_unit;
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"];
              this.returnSaleProducts[i]["display_vat_inc"] =
                <number>this.returnSaleProducts[i]["display_price"] +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] =
                this.returnSaleProducts[i]["net_total"];
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      console.log("Another Testing Here!!!");
      let tempPrice =
        <number>this.saleProducts[i]["product"]["price_per_unit"] * 1;
      if (
        this.saleProducts[i]["product"]["cost"] +
          (this.saleProducts[i]["product"]["cost"] * 20) / 100 <
        event.target.valueAsNumber
      ) {
        // - (<number>(this.saleProducts[i]["default_price"]) * <number>(this.saleProducts[i]["promotion"]["discount"]) / 100)
        console.log(this.saleProducts[i]["product"]["default_price"]);
        console.log("Event Value");
        console.log(event.target.valueAsNumber);
        console.log("Product");
        console.log(this.saleProducts[i]["product"]);
        this.saleProducts[i]["display_vat_inc"] = event.target.valueAsNumber; //  Code Added
        this.saleProducts[i]["display_price"] =
          <number>this.saleProducts[i]["display_vat_inc"] / 1.2; //  Code Added
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"];
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["product"]["price_per_unit"] = <number>(
          event.target.valueAsNumber
        );
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["net_total"] = <number>(
          this.saleProducts[i]["net_total"]
        );

        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.saleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.saleProducts[i]["display_vat_inc"] = <number>(
                event.target.valueAsNumber
              ); //  Code Added
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"];
              this.saleProducts[i]["display_price"] =
                <number>this.saleProducts[i]["display_vat_inc"] / 1.2; //  Code Added
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] = <number>(
                this.saleProducts[i]["net_total"]
              );
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
              console.log("Test here");
              console.log(this.amount_due);
            } else {
              this.saleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.saleProducts[i]["product"].best_price
                  : this.saleProducts[i]["product"].price_per_unit;
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"];
              this.saleProducts[i]["display_vat_inc"] =
                <number>this.saleProducts[i]["display_price"] +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100;
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] =
                this.saleProducts[i]["net_total"];
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(this.net_total + this.vat_amount);
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
  discountChange(event, i) {
    if (this.return) {
      let tempdiscount = <number>(
        this.returnSaleProducts[i]["promotion"]["discount"]
      );
      let tempDiscountedPrice =
        <number>this.returnSaleProducts[i]["display_price"] +
        (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
        event.target.valueAsNumber;
      if (
        <number>this.returnSaleProducts[i]["product"]["default_price"] <
        tempDiscountedPrice
      ) {
        this.returnSaleProducts[i]["promotion"]["discount"] =
          event.target.valueAsNumber;
        this.returnSaleProducts[i]["price_on_promotion"] =
          <number>this.returnSaleProducts[i]["display_price"] +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
          <number>this.returnSaleProducts[i]["promotion"]["discount"];
        this.returnSaleProducts[i]["display_vat_inc"] =
          <number>this.returnSaleProducts[i]["display_price"] +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100;
        this.returnSaleProducts[i]["display_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["discounted_price"] =
          <number>this.returnSaleProducts[i]["display_price"] -
          <number>this.returnSaleProducts[i]["promotion"]["discount"] / 1.2;
        this.returnSaleProducts[i]["net_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["net_total"] = <number>(
          this.returnSaleProducts[i]["net_total"]
        );
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.returnSaleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result.authentication_state == "successful") {
            this.verified_by = result.user.user_id;
            this.returnSaleProducts[i]["promotion"]["discount"] =
              event.target.valueAsNumber;
            this.returnSaleProducts[i]["price_on_promotion"] =
              <number>this.returnSaleProducts[i]["display_price"] +
              (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
              <number>this.returnSaleProducts[i]["promotion"]["discount"];
            this.returnSaleProducts[i]["display_vat_inc"] =
              <number>this.returnSaleProducts[i]["display_price"] +
              (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100;
            this.returnSaleProducts[i]["display_total"] =
              <number>this.returnSaleProducts[i]["price_on_promotion"] *
              <number>this.returnSaleProducts[i]["quantity"];
            this.returnSaleProducts[i]["discounted_price"] =
              <number>this.returnSaleProducts[i]["display_price"] -
              <number>this.returnSaleProducts[i]["promotion"]["discount"] / 1.2;
            this.returnSaleProducts[i]["net_total"] =
              <number>this.returnSaleProducts[i]["price_on_promotion"] *
              <number>this.returnSaleProducts[i]["quantity"];
            this.returnSaleProducts[i]["net_total"] = <number>(
              this.returnSaleProducts[i]["net_total"]
            );
            this.net_total = 0;
            this.amount_due = 0;
            for (let p of this.returnSaleProducts) {
              this.net_total =
                this.net_total +
                <number>p["discounted_price"] * <number>p["quantity"];
            }
            this.vat_amount = <number>((this.net_total * 20) / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
            this.amount_due = this.total_after_VAT;
          } else {
            this.returnSaleProducts[i]["promotion"]["discount"] = <number>(
              tempdiscount
            );
          }
        });
      }
    } else {
      let tempdiscount = <number>this.saleProducts[i]["promotion"]["discount"];
      let tempDiscountedPrice =
        <number>this.saleProducts[i]["display_price"] +
        (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
        event.target.valueAsNumber;
      if (
        <number>this.saleProducts[i]["product"]["default_price"] <
        tempDiscountedPrice
      ) {
        this.saleProducts[i]["promotion"]["discount"] =
          event.target.valueAsNumber;
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"];
        this.saleProducts[i]["display_vat_inc"] =
          <number>this.saleProducts[i]["display_price"] +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100;
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["net_total"] = <number>(
          this.saleProducts[i]["net_total"]
        );
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.saleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100);
        this.total_after_VAT = <number>(this.net_total + this.vat_amount);
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result.authentication_state == "successful") {
            this.verified_by = result.user.user_id;
            this.saleProducts[i]["promotion"]["discount"] =
              event.target.valueAsNumber;
            this.saleProducts[i]["price_on_promotion"] =
              <number>this.saleProducts[i]["display_price"] +
              (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
              <number>this.saleProducts[i]["promotion"]["discount"];
            this.saleProducts[i]["display_vat_inc"] =
              <number>this.saleProducts[i]["display_price"] +
              (<number>this.saleProducts[i]["display_price"] * 20) / 100;
            this.saleProducts[i]["display_total"] =
              <number>this.saleProducts[i]["price_on_promotion"] *
              <number>this.saleProducts[i]["quantity"];
            this.saleProducts[i]["discounted_price"] =
              <number>this.saleProducts[i]["display_price"] -
              <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
            this.saleProducts[i]["net_total"] =
              <number>this.saleProducts[i]["price_on_promotion"] *
              <number>this.saleProducts[i]["quantity"];
            this.saleProducts[i]["net_total"] = <number>(
              this.saleProducts[i]["net_total"]
            );
            this.net_total = 0;
            this.amount_due = 0;
            for (let p of this.saleProducts) {
              this.net_total =
                this.net_total +
                <number>p["discounted_price"] * <number>p["quantity"];
            }
            this.vat_amount = <number>((this.net_total * 20) / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
            this.amount_due = this.total_after_VAT;
          } else {
            this.saleProducts[i]["promotion"]["discount"] = <number>(
              tempdiscount
            );
          }
        });
      }
    }
  }
  twentyPercent(amount) {
    return Number(amount) + Number((amount * 20) / 100);
  }
  RoundOff(amount) {
    return Number(amount);
  }
  delete(i) {
    this.cdr.detectChanges();
    // this.multiSelect._selectionModel.selected[i].deselect();
    this.net_total =
      this.net_total -
      (this.saleProducts[i]["discounted_price"]
        ? <number>this.saleProducts[i]["discounted_price"]
        : <number>this.saleProducts[i]["product"]["price_per_unit"]) *
        <number>this.saleProducts[i]["quantity"];
    // this.multiSelect._onChange( this.saleProducts[i]);
    //this.multiSelect._selectionModel.deselect(this.saleProducts[i]);
    this.vat_amount = <number>((this.net_total * 20) / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);
    this.amount_due = this.total_after_VAT;
    this.saleProducts.splice(i, 1);
    // this.productMultiCtrl.value.splice(i, 1);
    // this.multiSelect._selectionModel.selected.splice(i, 1);
    this.selectedProducts = this.productMultiCtrl.value;
    this.net_total = 0;
    this.amount_due = 0;
    this.vat_amount = 0;
    this.total_after_VAT = 0;
    for (let p of this.saleProducts) {
      this.net_total =
        this.net_total + <number>p["discounted_price"] * <number>p["quantity"];
    }
    this.vat_amount = <number>((this.net_total * 20) / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);
    this.amount_due = this.total_after_VAT;
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.currentUserSubscription.unsubscribe();
  }
  toggleDropdown = function () {
    this.dd = !this.dd;
  };
  openSuspendedSalesDialog = function (suspendedSales) {
    const dialogRef = this.dialog.open(SuspendedDialog, {
      data: { suspendedSales: suspendedSales },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("hurrrrr");
      console.log(result);
      if (result && result.get) {
        // console.log("Inside 1st If");
        this.get(result.get);
        this.customer = result.get.customer;
        console.log(result.get);
        this.removeSuspendedSaleOnUnsuspend(result.get);
      }
      if (result && result.delete) {
        console.log("Inside 2nd If");
        console.log(result.delete);
        this.deleteSuspendedSale(result.delete);
      }
    });
  };
  loadSuspendedSales = function () {
    let suspendedSales = this.getAll();
    this.openSuspendedSalesDialog(suspendedSales);
  };

  /* checkOverLimit = function (customer) {
    if (customer) {
      console.log("Inside CheckOverLimit");
      if (customer && customer.first_name !== "Cash") {
        console.log(customer);
        let available = customer.credit_limit - customer.current_credit;
        console.log(available);
        if (available <= 0) {
          console.log("Over Credit Limit");
          this.confirmationDialogService.confirm('Customer Credit Limit Exceeded!..', 'Do you want to go to customer payments?')
          .then((confirmed) => {
            if (confirmed) {
              console.log("Routing!!!");
              this.router.navigate(['/customers/customer-statement/' + customer.customer_id + '/']);
              this.cancel();
            }
          })
        } else {
          console.log("Under Credit Limit");
        }
      }
    }
  } */

  getInvoiceDeadline = function (year, month) {
    return new Date(year, month + 1, 7);
  };

  checkOverLimit = function (customer) {
    if (customer) {
      console.log("Inside CheckOverLimit");
      if (customer && !this.customer.first_name.includes("Cash")) {
        console.log(customer);
        let available = customer.credit_limit - customer.current_credit;
        console.log(available);
        if (available < 0) {
          console.log("Credit Limit Exceeded");
          this.confirmationDialogService
            .confirm(
              "Customer Credit Limit Exceeded/Expired!..",
              "Do you want to go to customer payments?"
            )
            .then((confirmed) => {
              if (confirmed) {
                console.log("Routing!!!");
                this.receiptSale();
                //this.cancel();
              } else {
                return;
              }
            });
        } else {
          console.log(
            "Credit Limit Not Exceeded, checking if unpaid amounts overdue"
          );
          this.salesService
            .getCustomerOutstanding(customer)
            .subscribe((data) => {
              if (data.transactions.length > 0) {
                let data_of_first_transaction = data.transactions[0];
                console.log(data_of_first_transaction);
                let truncatedDate =
                  data_of_first_transaction.transaction_date.split("T");
                let transactionDate = truncatedDate[0];
                // console.log(transactionDate);
                let date = Date.parse(transactionDate);
                let transactionDateObject = new Date(date);
                console.log("Transaction Date Object: ");
                console.log(transactionDateObject);
                let transactionYear = transactionDateObject.getFullYear();
                // console.log(transactionYear);
                let transactionMonth = transactionDateObject.getMonth() + 1;
                // console.log(transactionMonth);
                let invoiceDeadline = this.getInvoiceDeadline(
                  transactionYear,
                  transactionMonth
                );
                console.log("DeadLine:");
                console.log(invoiceDeadline);
                const today = new Date();
                console.log("Today: ");
                console.log(today);

                if (today > invoiceDeadline) {
                  console.log("Credit Limit Exceeded");
                  this.confirmationDialogService
                    .confirm(
                      "Customer Credit Limit Exceeded/Expired!..",
                      "Do you want to go to customer payments?"
                    )
                    .then((confirmed) => {
                      if (confirmed) {
                        console.log("Routing!!!");
                        this.receiptSale();
                        //this.cancel();
                      } else {
                        return;
                      }
                    });
                }
              } else {
                console.log("All transactions are clear");
              }
            });
        }
      }
    }
  };

  suspend = function (sale?) {
    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    if (sale) {
      this.set(
        0,
        <string>sale.customer.first_name + <string>sale.customer.last_name,
        sale
      );
    } else {
      this.confirmationDialogService
        .confirm("Please confirm..", "Do you really want to Suspend Sale?")
        .then((confirmed) => {
          if (confirmed) {
            let saleToSuspend = {
              saleProducts: this.saleProducts,
              returnSaleProduct: this.returnSaleProducts,
              customer: this.customer,
              net_total: this.net_total,
              amount_due: this.amount_due,
              vat_amount: this.vat_amount,
              total_after_VAT: this.total_after_VAT,
              payment_type: this.payment_type,
              payment_nominal_account: this.payment_nominal_account,
              currentUser: this.currentUser,
              payment: this.payment,
              customer_ref: this.customer_ref,
              return_id: this.return_id,
              extra_note: this.extra_note,
              comments: this.comments,
              net_nominal_account: this.net_nominal_account,
              card: this.card,
              cash: this.cash,
              shipment: this.shipment,
              customer_address: this.customer_address,
              discount: this.total_discount,
              quotation_number: this.quotation_number,
            };
            this.set(
              0,
              <string>this.customer.first_name +
                <string>this.customer.last_name,
              saleToSuspend
            );
            // this.receiptSale();
            this.cancel();
          }
        })

        .catch(() => {
          this.toasterService.error("Unable to Suspend", "Failed!", {
            timeOut: 5000,
          });
        });
    }
  };
  category = function (cat_id) {
    return this.productsService
      .getProductCategoryName(cat_id)
      .subscribe((data) => {});
  };
  editCustomer = function () {
    this.customer_dropdown = false;
    this.openCustomerDialog();
  };
  openCustomerDialog = function (): void {
    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    const dialogRef = this.dialog.open(CustomerDialog, {
      height: "fit-content",
      width: "fit-content",
      borderRadius: "10px",
      backgroundColor: "none",
      data: { customer: this.customer, store_id: this.store_id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.customer = result ? result : null;
    });
  };
  openAddRawProductDialog = function (): void {
    const dialogRef = this.dialog.open(AddRawProductDialog, {
      height: "100%",
      width: "70%",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.productsService.getProduct(result.product_id).subscribe((data) => {
        let temp = {};
        temp["product"] = data[0];
        temp["product"]["best_price"] = <number>temp["product"]["best_price"];
        temp["product"]["default_price"] = <number>(
          temp["product"]["default_price"]
        );
        temp["product"]["price_per_unit"] = <number>(
          temp["product"]["price_per_unit"]
        );
        this.productsService
          .getProductCategoryName(data[0].category_id)
          .toPromise()
          .then((result) => {
            temp["category"] = result[0];
          });
        this.promotionsService
          .getPromotionByProductID(data[0].product_id)
          .toPromise()
          .then((result) => {
            let pro: PromotionsElement = {
              promotion_id: "",
              product_id: temp["product"].product_id,
              sku: temp["product"].sku,
              alternate_sku: temp["product"].alternate_sku,
              product_name: temp["product"].product_name,
              product_description: temp["product"].product_description,
              category: temp["product"].category_id,
              brand: temp["product"].brand,
              price_per_unit: <number>temp["product"].price_per_unit,
              start_date: Date,
              end_date: Date,
              discount: 0,
              comments: "",
            };
            let resu = result[0] ? result[0] : pro;
            temp["promotion"] = resu;
            temp["quantity"] = 1;
            temp["net_total"] =
              <number>temp["quantity"] *
              (temp["price_on_promotion"]
                ? <number>temp["price_on_promotion"]
                : <number>temp["product"].price_per_unit);
            temp["net_total"] =
              temp["net_total"] + (temp["net_total"] * 20) / 100;
            temp["price_per_unit"] = <number>temp["product"].price_per_unit;
            temp["default_price"] = <number>temp["product"].default_price;
            temp["best_price"] = <number>temp["product"].best_price;
            temp["display_price"] = <number>(
              temp["product"][
                this.customer && this.customer.price_band
                  ? this.customer.price_band
                  : temp["product"]["price_per_unit"]
                  ? "price_per_unit"
                  : temp["product"]["best_price"]
                  ? "best_price"
                  : temp["product"]["default_price"]
                  ? "default_price"
                  : 0.0
              ]
            );
            // temp["display_price"] = <number>(temp["product"][(this.customer && this.customer.price_band) ? this.customer.price_band : "price_per_unit"]);
            temp["price_on_promotion"] =
              <number>temp["display_price"] +
              (<number>temp["display_price"] * 20) / 100 -
              <number>temp["promotion"]["discount"];
            temp["display_vat_inc"] =
              <number>temp["display_price"] +
              (<number>temp["display_price"] * 20) / 100;
            temp["display_total"] =
              <number>temp["price_on_promotion"] * <number>temp["quantity"];
            temp["discounted_price"] =
              <number>temp["display_price"] -
              <number>temp["promotion"]["discount"] / 1.2;
            temp["restocking_fee"] = 0;
            temp["restock_percent"] = 0;
            this.saleProducts.push(temp);
            this.net_total = 0;
            for (let i = 0; i < this.saleProducts.length; i++) {
              this.net_total =
                this.net_total +
                <number>this.saleProducts[i]["discounted_price"] *
                  <number>this.saleProducts[i]["quantity"];
            }
            this.vat_amount = <number>((this.net_total * 20) / 100);
            this.total_after_VAT = <number>(this.net_total + this.vat_amount);
            this.amount_due = this.total_after_VAT;
          });
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      });
    });
  };
  showCustomerTransactions = function () {};
  fee_percentChange(event, product, i) {
    this.saleProducts[i].restocking_fee =
      ((<number>this.saleProducts[i].display_vat_inc * event) / 100) *
      <number>this.saleProducts[i].quantity;
    this.saleProducts[i].display_total =
      <number>(
        (<number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"])
      ) - <number>this.saleProducts[i].restocking_fee;
    this.net_total = 0;
    this.amount_due = 0;
    this.vat_amount = 0;
    this.restocking_fee = 0;
    this.total_after_VAT = 0;
    this.returnSaleProducts = new Array<any>();
    for (var ind = 0; ind < this.saleProducts.length; ind++) {
      if (this.checked[ind]) {
        this.returnSaleProducts.push(this.saleProducts[ind]);
        this.net_total =
          this.net_total +
          <number>this.saleProducts[ind]["discounted_price"] *
            <number>this.saleProducts[ind]["quantity"];
        // this.total_after_VAT = this.total_after_VAT - this.saleProducts[ind].restocking_fee + this.saleProducts[ind].display_total;
        this.restocking_fee =
          this.restocking_fee + this.saleProducts[ind].restocking_fee;
      }
    }
    this.vat_amount = <number>((this.net_total * 20) / 100);
    this.total_after_VAT =
      this.net_total + this.vat_amount - this.restocking_fee;
    this.amount_due = this.total_after_VAT;
    console.log("Testing");
    console.log(this.amount_due);
  }
  fee_poundChange(event, product, i) {
    console.log("YO");
    this.saleProducts[i].restocking_fee = event;
    this.saleProducts[i].display_total =
      <number>(
        (<number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"])
      ) -
      <number>(
        (this.saleProducts[i].restocking_fee * this.saleProducts[i].quantity)
      );
    this.net_total = 0;
    this.amount_due = 0;
    this.vat_amount = 0;
    this.total_after_VAT = 0;
    this.restocking_fee = 0;
    this.returnSaleProducts = new Array<any>();
    for (var ind = 0; ind < this.saleProducts.length; ind++) {
      if (this.checked[ind]) {
        this.returnSaleProducts.push(this.saleProducts[ind]);
        this.net_total =
          this.net_total +
          <number>this.saleProducts[ind]["discounted_price"] *
            <number>this.saleProducts[ind]["quantity"];
        // this.total_after_VAT = this.total_after_VAT - this.saleProducts[ind].restocking_fee + this.saleProducts[ind].display_total;
        this.restocking_fee =
          this.restocking_fee + this.saleProducts[ind].restocking_fee;
      }
    }
    this.vat_amount = <number>((this.net_total * 20) / 100);
    this.total_after_VAT =
      this.net_total + this.vat_amount - this.restocking_fee;
    this.amount_due = this.total_after_VAT;
  }
  setPrice(event, i) {
    if (this.return) {
      console.log("Testing");
      this.returnSaleProducts[i]["price_on_promotion"] = this.selectedValue * 1;
      let tempPrice = <number>(
        this.returnSaleProducts[i]["product"]["price_per_unit"]
      );
      if (
        <number>this.returnSaleProducts[i]["default_price"] * 1 <=
        <number>event * 1
      ) {
        // - (<number>(this.returnSaleProducts[i]["default_price"]) * <number>(this.returnSaleProducts[i]["promotion"]["discount"]) / 100)
        this.returnSaleProducts[i]["display_price"] = <number>event * 1;
        this.returnSaleProducts[i]["price_on_promotion"] =
          <number>this.returnSaleProducts[i]["display_price"] * 1 +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100 -
          <number>this.returnSaleProducts[i]["promotion"]["discount"] * 1;
        this.returnSaleProducts[i]["display_vat_inc"] =
          <number>this.returnSaleProducts[i]["display_price"] * 1 +
          (<number>this.returnSaleProducts[i]["display_price"] * 20) / 100;
        this.returnSaleProducts[i]["display_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["discounted_price"] =
          <number>this.returnSaleProducts[i]["display_price"] * 1 -
          <number>this.returnSaleProducts[i]["promotion"]["discount"] / 1.2;
        this.returnSaleProducts[i]["net_total"] =
          <number>this.returnSaleProducts[i]["price_on_promotion"] *
          <number>this.returnSaleProducts[i]["quantity"];
        this.returnSaleProducts[i]["net_total"] =
          <number>this.returnSaleProducts[i]["net_total"] * 1;
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.returnSaleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100) * 1;
        this.total_after_VAT = <number>(this.net_total + this.vat_amount) * 1;
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.returnSaleProducts[i]["product"]["price_per_unit"] =
                <number>event * 1;
              this.returnSaleProducts[i]["display_price"] = <number>event * 1;
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] * 1;
              this.returnSaleProducts[i]["display_vat_inc"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["net_total"] * 1;
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total * 1 +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 1 * 20) / 100);
              this.total_after_VAT = <number>(
                (this.net_total * 1 + this.vat_amount)
              );
              this.amount_due = this.total_after_VAT * 1;
            } else {
              this.returnSaleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.returnSaleProducts[i]["product"].best_price * 1
                  : this.returnSaleProducts[i]["product"].price_per_unit * 1;
              this.returnSaleProducts[i]["price_on_promotion"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] * 1;
              this.returnSaleProducts[i]["display_vat_inc"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 +
                (<number>this.returnSaleProducts[i]["display_price"] * 20) /
                  100;
              this.returnSaleProducts[i]["display_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["discounted_price"] =
                <number>this.returnSaleProducts[i]["display_price"] * 1 -
                <number>this.returnSaleProducts[i]["promotion"]["discount"] /
                  1.2;
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["price_on_promotion"] *
                <number>this.returnSaleProducts[i]["quantity"];
              this.returnSaleProducts[i]["net_total"] =
                <number>this.returnSaleProducts[i]["net_total"] * 1;
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.returnSaleProducts) {
                this.net_total =
                  this.net_total * 1 +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(
                (this.net_total * 1 + this.vat_amount * 1)
              );
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      this.saleProducts[i]["price_on_promotion"] = this.selectedValue * 1;
      console.log("My New Test");
      console.log(this.selectedValue);
      let tempPrice = <number>this.saleProducts[i]["product"]["price_per_unit"];
      if (
        <number>this.saleProducts[i]["default_price"] * 1 <=
        <number>event * 1
      ) {
        // - (<number>(this.saleProducts[i]["default_price"]) * <number>(this.saleProducts[i]["promotion"]["discount"]) / 100)
        this.saleProducts[i]["display_price"] = <number>event * 1;
        this.saleProducts[i]["price_on_promotion"] =
          <number>this.saleProducts[i]["display_price"] * 1 +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
          <number>this.saleProducts[i]["promotion"]["discount"] * 1;
        this.saleProducts[i]["display_vat_inc"] =
          <number>this.saleProducts[i]["display_price"] * 1 +
          (<number>this.saleProducts[i]["display_price"] * 20) / 100;
        this.saleProducts[i]["display_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["discounted_price"] =
          <number>this.saleProducts[i]["display_price"] * 1 -
          <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["price_on_promotion"] *
          <number>this.saleProducts[i]["quantity"];
        this.saleProducts[i]["net_total"] =
          <number>this.saleProducts[i]["net_total"] * 1;
        this.net_total = 0;
        this.amount_due = 0;
        for (let p of this.saleProducts) {
          this.net_total =
            this.net_total +
            <number>p["discounted_price"] * <number>p["quantity"];
        }
        this.vat_amount = <number>((this.net_total * 20) / 100) * 1;
        this.total_after_VAT = <number>(this.net_total + this.vat_amount) * 1;
        this.amount_due = this.total_after_VAT;
      } else {
        const dialogRef = this.dialog.open(AuthenticationDialog, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            if (result.authentication_state == "successful") {
              this.verified_by = result["user"].user_id;
              this.saleProducts[i]["product"]["price_per_unit"] =
                <number>event * 1;
              this.saleProducts[i]["display_price"] = <number>event * 1;
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] * 1 +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"] * 1;
              this.saleProducts[i]["display_vat_inc"] =
                <number>this.saleProducts[i]["display_price"] * 1 +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100;
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] * 1 -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["net_total"] * 1;
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total * 1 +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 1 * 20) / 100);
              this.total_after_VAT = <number>(
                (this.net_total * 1 + this.vat_amount)
              );
              this.amount_due = this.total_after_VAT * 1;
            } else {
              this.saleProducts[i]["display_price"] =
                this.customer && !this.customer.first_name.includes("Cash")
                  ? this.saleProducts[i]["product"].best_price * 1
                  : this.saleProducts[i]["product"].price_per_unit * 1;
              this.saleProducts[i]["price_on_promotion"] =
                <number>this.saleProducts[i]["display_price"] * 1 +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100 -
                <number>this.saleProducts[i]["promotion"]["discount"] * 1;
              this.saleProducts[i]["display_vat_inc"] =
                <number>this.saleProducts[i]["display_price"] * 1 +
                (<number>this.saleProducts[i]["display_price"] * 20) / 100;
              this.saleProducts[i]["display_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["discounted_price"] =
                <number>this.saleProducts[i]["display_price"] * 1 -
                <number>this.saleProducts[i]["promotion"]["discount"] / 1.2;
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["price_on_promotion"] *
                <number>this.saleProducts[i]["quantity"];
              this.saleProducts[i]["net_total"] =
                <number>this.saleProducts[i]["net_total"] * 1;
              this.net_total = 0;
              this.amount_due = 0;
              for (let p of this.saleProducts) {
                this.net_total =
                  this.net_total * 1 +
                  <number>p["discounted_price"] * <number>p["quantity"];
              }
              this.vat_amount = <number>((this.net_total * 20) / 100);
              this.total_after_VAT = <number>(
                (this.net_total * 1 + this.vat_amount * 1)
              );
              this.amount_due = this.total_after_VAT;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
  confirmSalesSubmit() {
    console.log("Hey");
    if (this.payment_type === "cash" || this.payment_type === "Cash") {
      if (this.payment === null || this.payment === 0 || this.payment === "") {
        this.payment_type = "To Pay";
        this.set_payment("To Pay");
      }
    }
    if (this.payment_type == "To Pay") {
      if (this.payment > 0) {
        // this.toasterService.error()
        this.toasterService.error(
          "Setting Payment amount to 0. To Pay cannot have a payment.",
          "Payment Mehod!",
          {
            timeOut: 5000,
          }
        );
        this.payment = 0;
        //this.amount_due = this.total_after_VAT - this.total_discount - this.payment;
      }
    }
    for (let i = 0; i < this.saleProducts.length; i++) {
      this.saleProducts[i]["index"] = i;
    }
    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    if (!this.customer) {
      this.salesService
        .getDefaultCustomer()
        .toPromise()
        .then((data) => {
          this.customer = data[0];
        });
    }
    this.companiesService.getCompany(this.store_id).subscribe((data) => {
      this.company = data ? data[0] : false;
    });
    this.storesService
      .getStore(this.store_id)
      .toPromise()
      .then((data) => {
        this.store = data[0];
      });
    if (this.customer.first_name.includes("Cash")) {
      if (this.amount_due > 0.09) {
        this.confirmationDialogService
          .confirm(
            "Cash Customer can not credit here",
            `Do you still want to continue?`
          )
          .then((confirmed) => {
            if (confirmed) {
              this.openReceiptDialog();
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.openReceiptDialog();
      }
    } else {
      this.openReceiptDialog();
    }
    // else {
    //   console.log(this.customer.current_credit);
    //   console.log(this.amount_due);
    //   console.log(this.payment);
    //   let available = this.customer.credit_limit - this.customer.current_credit;
    //   if (available > 0 && (this.amount_due - this.payment) > available) {
    //     console.log("here");
    //     // New Code Here
    //     this.confirmationDialogService.confirm('Credit Limit Exceeded (Testing)..', 'Intended Sales Exceed the Available Credit, Do you want to Suspend Sale?')
    //       .then((confirmed) => {
    //         console.log(confirmed);
    //         if (confirmed) {
    //           let saleToSuspend = {
    //             "saleProducts": this.saleProducts,
    //             "returnSaleProduct": this.returnSaleProducts,
    //             "customer": this.customer,
    //             "net_total": this.net_total,
    //             "amount_due": this.amount_due,
    //             "vat_amount": this.vat_amount,
    //             "total_after_VAT": this.total_after_VAT,
    //             "payment_type": this.payment_type,
    //             "payment_nominal_account": this.payment_nominal_account,
    //             "currentUser": this.currentUser,
    //             "payment": this.payment,
    //             "customer_ref": this.customer_ref,
    //             "return_id": this.return_id,
    //             "extra_note": this.extra_note,
    //             "comments": this.comments,
    //             "net_nominal_account": this.net_nominal_account,
    //             "card": this.card, "cash": this.cash,
    //             "shipment": this.shipment, "customer_address": this.customer_address, "discount": this.total_discount,
    //             "quotation_number": this.quotation_number
    //           }
    //           this.set(0, <string>(this.customer.first_name) + <string>(this.customer.last_name), saleToSuspend);
    //           this.cancel();
    //         } else if (!confirmed) {
    //           this.confirmationDialogService.confirm('Please confirm..', `Do you really want to Complete Sale? You won't be able to make changes after this!`).then((confirmed) => {

    //           if (confirmed) {
    //             this.openReceiptDialog();
    //           }
    //         }).catch((error) => console.log(error));
    //       }
    //     })

    //     .catch(() => {
    //       this.toasterService.error('Unable to Suspend', 'Failed!', {
    //         timeOut: 5000
    //       });
    //     })

    //     // this.suspend();
    //   } else {
    //     this.confirmationDialogService.confirm('Please confirm..', `Do you really want to Complete Sale? You won't be able to make changes after this!`).then((confirmed) => {

    //       if (confirmed) {
    //         this.openReceiptDialog();

    //       }
    //     }).catch((error) => console.log(error));
    //   }
    // }
  }
  total_discountChange(event) {
    this.total_discount = event;
    console.log("Testing in Total Discount Change");
    console.log(this.selected_transactions);
    if (this.receipt) {
      console.log("Inside If");
      // this.total_after_VAT = <number>(this.net_total + this.vat_amount - this.total_discount)
      this.amount_due =
        this.total_after_VAT - this.payment - this.total_discount;
    } else {
      console.log("Inside Else");
      // this.total_discount = event;
      this.net_total = 0;
      for (let i = 0; i < this.saleProducts.length; i++) {
        this.net_total =
          this.net_total +
          <number>this.saleProducts[i]["discounted_price"] *
            <number>this.saleProducts[i]["quantity"];
      }
      this.vat_amount = <number>((this.net_total * 20) / 100);
      this.total_after_VAT = <number>(
        (this.net_total + this.vat_amount - this.total_discount)
      );
      // this.amount_due = this.total_after_VAT;
      // this.total_after_VAT = this.net_total * 1.2 ;
      // this.vat_amount = this.net_total * 20 / 100;
      this.amount_due = this.total_after_VAT - this.payment;
    }
  }
  /* total_discountChange(event) {
    this.total_discount = event;
    this.net_total = 0;
    for (let i = 0; i < this.saleProducts.length; i++) {
      this.net_total = this.net_total + <number>(this.saleProducts[i]["discounted_price"]) * <number>(this.saleProducts[i]["quantity"]);
    }
    this.vat_amount = <number>(this.net_total * 20 / 100);
    this.total_after_VAT = <number>(this.net_total + this.vat_amount);
    // this.amount_due = this.total_after_VAT;
    // this.total_after_VAT = this.net_total * 1.2 ;
    // this.vat_amount = this.net_total * 20 / 100;
    this.amount_due = this.total_after_VAT - this.payment - this.total_discount;
  } */
  openCashTransactionsDialog = function (): void {
    if (this.currentUser.user_type != "admin") {
      this.store_id = this.currentUser.store_id;
      this.local.set("store_id", this.store_id, 0, "s");
    } else {
      this.store_id =
        this.local.get("store_id") == "all"
          ? this.currentUser.store_id
          : this.local.get("store_id");
    }
    const dialogRef = this.dialog.open(CashTransactionsDialog, {
      disableClose: true,
      height: "fit-content",
      width: "fit-content",
      borderRadius: "10px",
      backgroundColor: "none",
      data: { customer: this.customer, store_id: this.store_id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.transactions_to_clear = result ? result : null;
      console.log(this.transactions_to_clear, "transactions........");
      this.confirmationDialogService
        .confirm(
          "Please confirm..",
          `Do you really want to Complete Return? You won't be able to make changes after this!`
        )
        .then((confirmed) => {
          if (confirmed) {
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                transaction_number: this.transaction_number,
                transaction: this.transaction,
                transactions_to_clear: this.transactions_to_clear,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
                transaction_id: this.transaction_id,
                restocking_fee: this.restocking_fee,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
          }
        })
        .catch((error) => console.log(error));
    });
  };
  openReceiptDialog = function (): void {
    if (this.saleProducts.length > 0) {
      if (this.customer.first_name.includes("Cash")) {
        //this.customer["current_credit"] = credit;
        console.log("aaa");
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }

        this.storesService
          .getStore(this.store_id)
          .toPromise()
          .then((data) => {
            this.store = data[0];
          });
        this.local.set(
          "tempSale",
          {
            saleProducts: this.saleProducts,
            returnSaleProducts: this.returnSaleProducts,
            customer: this.customer,
            total: this.total_after_VAT,
            net_total: this.net_total,
            transaction_id: this.transaction_id,
            sold_by:
              this.currentUser.first_name.charAt(0) +
              this.currentUser.last_name.charAt(0),
            amount_due: this.amount_due,
            payment_type: this.payment_type,
            payment_nominal_account: this.payment_nominal_account,
            payment: this.payment,
            store_id: this.store_id,
            verified_by: this.verified_by,
            company: this.company,
            store: this.store,
            vat_amount: this.vat_amount,
            is_return: this.return,
            currentUser: this.currentUser,
            customer_ref: this.customer_ref,
            return_id: this.return_id,
            quotation_number: this.quotation_number,
            extra_note: this.extra_note,
            comments: this.comments,
            net_nominal_account: this.net_nominal_account,
            card: this.card,
            cash: this.cash,
            shipment: this.shipment,
            customer_address: this.customer_address,
            discount: this.total_discount,
          },
          0,
          "s"
        );
        this.router.navigate(["/receipt"]);
      } else {
        //this.checkOverLimit(this.customer);
        //setTimeout("ss",1000)
        let available =
          this.customer["credit_limit"] - this.customer["current_credit"];
        if (available < 0) {
          console.log("If available < 0");
          console.log(this.amount_due);
          this.confirmationDialogServiceNew
            .confirm(
              "Payment is Due",
              "Clear previous payments or proceed with sales instead"
            )
            .then((confirmed) => {
              if (confirmed === "decline") {
                console.log("decline");
              } else if (confirmed === "payment") {
                console.log("payment");
                let saleToSuspend = {
                  saleProducts: this.saleProducts,
                  returnSaleProduct: this.returnSaleProducts,
                  customer: this.customer,
                  net_total: this.net_total,
                  amount_due: this.amount_due,
                  vat_amount: this.vat_amount,
                  total_after_VAT: this.total_after_VAT,
                  payment_type: this.payment_type,
                  payment_nominal_account: this.payment_nominal_account,
                  currentUser: this.currentUser,
                  payment: this.payment,
                  customer_ref: this.customer_ref,
                  return_id: this.return_id,
                  extra_note: this.extra_note,
                  comments: this.comments,
                  net_nominal_account: this.net_nominal_account,
                  card: this.card,
                  cash: this.cash,
                  shipment: this.shipment,
                  customer_address: this.customer_address,
                  discount: this.total_discount,
                  quotation_number: this.quotation_number,
                };
                this.set(
                  0,
                  <string>this.customer.first_name +
                    <string>this.customer.last_name,
                  saleToSuspend
                );
                var cust = this.customer;
                this.cancel();
                this.customer = cust;
                // console.log("New Test Start");
                this.receiptSale();
                // console.log("Receipt: " + this.receipt);
                // console.log("Edit: " + this.edit);
                // console.log("Return: " + this.return);
                // console.log("New Test End");
              } else if (confirmed === "proceed") {
                //this.customer["current_credit"] = credit;
                if (this.currentUser.user_type != "admin") {
                  this.store_id = this.currentUser.store_id;
                  this.local.set("store_id", this.store_id, 0, "s");
                } else {
                  this.store_id =
                    this.local.get("store_id") == "all"
                      ? this.currentUser.store_id
                      : this.local.get("store_id");
                }

                this.storesService
                  .getStore(this.store_id)
                  .toPromise()
                  .then((data) => {
                    this.store = data[0];
                  });
                this.local.set(
                  "tempSale",
                  {
                    saleProducts: this.saleProducts,
                    returnSaleProducts: this.returnSaleProducts,
                    customer: this.customer,
                    total: this.total_after_VAT,
                    net_total: this.net_total,
                    transaction_id: this.transaction_id,
                    sold_by:
                      this.currentUser.first_name.charAt(0) +
                      this.currentUser.last_name.charAt(0),
                    amount_due: this.amount_due,
                    payment_type: this.payment_type,
                    payment_nominal_account: this.payment_nominal_account,
                    payment: this.payment,
                    store_id: this.store_id,
                    verified_by: this.verified_by,
                    company: this.company,
                    store: this.store,
                    vat_amount: this.vat_amount,
                    is_return: this.return,
                    currentUser: this.currentUser,
                    customer_ref: this.customer_ref,
                    return_id: this.return_id,
                    quotation_number: this.quotation_number,
                    extra_note: this.extra_note,
                    comments: this.comments,
                    net_nominal_account: this.net_nominal_account,
                    card: this.card,
                    cash: this.cash,
                    shipment: this.shipment,
                    customer_address: this.customer_address,
                    discount: this.total_discount,
                  },
                  0,
                  "s"
                );
                this.router.navigate(["/receipt"]);
              }
            })
            .catch(() => {
              console.log(
                "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
              );
            });
        } else if (available >= 0) {
          console.log("If available < 0");
          console.log(this.amount_due);
          if (available < this.amount_due) {
            this.confirmationDialogServiceNew
              .confirm(
                "Payment is Due",
                "Clear previous payments or proceed with sales instead"
              )
              .then((confirmed) => {
                if (confirmed === "decline") {
                  console.log("decline");
                } else if (confirmed === "payment") {
                  console.log("payment");
                  let saleToSuspend = {
                    saleProducts: this.saleProducts,
                    returnSaleProduct: this.returnSaleProducts,
                    customer: this.customer,
                    net_total: this.net_total,
                    amount_due: this.amount_due,
                    vat_amount: this.vat_amount,
                    total_after_VAT: this.total_after_VAT,
                    payment_type: this.payment_type,
                    payment_nominal_account: this.payment_nominal_account,
                    currentUser: this.currentUser,
                    payment: this.payment,
                    customer_ref: this.customer_ref,
                    return_id: this.return_id,
                    extra_note: this.extra_note,
                    comments: this.comments,
                    net_nominal_account: this.net_nominal_account,
                    card: this.card,
                    cash: this.cash,
                    shipment: this.shipment,
                    customer_address: this.customer_address,
                    discount: this.total_discount,
                    quotation_number: this.quotation_number,
                  };
                  this.set(
                    0,
                    <string>this.customer.first_name +
                      <string>this.customer.last_name,
                    saleToSuspend
                  );
                  var cust = this.customer;
                  this.cancel();
                  this.customer = cust;
                  // console.log("Another New Testing Start");
                  this.receiptSale();
                  // console.log("Receipt: " + this.receipt);
                  // console.log("Edit: " + this.edit);
                  // console.log("Return: " + this.return);
                  // console.log("Another New Testing End");
                } else if (confirmed === "proceed") {
                  //this.customer["current_credit"] = credit;
                  if (this.currentUser.user_type != "admin") {
                    this.store_id = this.currentUser.store_id;
                    this.local.set("store_id", this.store_id, 0, "s");
                  } else {
                    this.store_id =
                      this.local.get("store_id") == "all"
                        ? this.currentUser.store_id
                        : this.local.get("store_id");
                  }

                  this.storesService
                    .getStore(this.store_id)
                    .toPromise()
                    .then((data) => {
                      this.store = data[0];
                    });
                  this.local.set(
                    "tempSale",
                    {
                      saleProducts: this.saleProducts,
                      returnSaleProducts: this.returnSaleProducts,
                      customer: this.customer,
                      total: this.total_after_VAT,
                      net_total: this.net_total,
                      transaction_id: this.transaction_id,
                      sold_by:
                        this.currentUser.first_name.charAt(0) +
                        this.currentUser.last_name.charAt(0),
                      amount_due: this.amount_due,
                      payment_type: this.payment_type,
                      payment_nominal_account: this.payment_nominal_account,
                      payment: this.payment,
                      store_id: this.store_id,
                      verified_by: this.verified_by,
                      company: this.company,
                      store: this.store,
                      vat_amount: this.vat_amount,
                      is_return: this.return,
                      currentUser: this.currentUser,
                      customer_ref: this.customer_ref,
                      return_id: this.return_id,
                      quotation_number: this.quotation_number,
                      extra_note: this.extra_note,
                      comments: this.comments,
                      net_nominal_account: this.net_nominal_account,
                      card: this.card,
                      cash: this.cash,
                      shipment: this.shipment,
                      customer_address: this.customer_address,
                      discount: this.total_discount,
                    },
                    0,
                    "s"
                  );
                  this.router.navigate(["/receipt"]);
                }
              })
              .catch(() => {
                console.log(
                  "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
                );
              });
          } else {
            //this.customer["current_credit"] = credit;
            if (this.currentUser.user_type != "admin") {
              this.store_id = this.currentUser.store_id;
              this.local.set("store_id", this.store_id, 0, "s");
            } else {
              this.store_id =
                this.local.get("store_id") == "all"
                  ? this.currentUser.store_id
                  : this.local.get("store_id");
            }

            this.storesService
              .getStore(this.store_id)
              .toPromise()
              .then((data) => {
                this.store = data[0];
              });
            this.local.set(
              "tempSale",
              {
                saleProducts: this.saleProducts,
                returnSaleProducts: this.returnSaleProducts,
                customer: this.customer,
                total: this.total_after_VAT,
                net_total: this.net_total,
                transaction_id: this.transaction_id,
                sold_by:
                  this.currentUser.first_name.charAt(0) +
                  this.currentUser.last_name.charAt(0),
                amount_due: this.amount_due,
                payment_type: this.payment_type,
                payment_nominal_account: this.payment_nominal_account,
                payment: this.payment,
                store_id: this.store_id,
                verified_by: this.verified_by,
                company: this.company,
                store: this.store,
                vat_amount: this.vat_amount,
                is_return: this.return,
                currentUser: this.currentUser,
                customer_ref: this.customer_ref,
                return_id: this.return_id,
                quotation_number: this.quotation_number,
                extra_note: this.extra_note,
                comments: this.comments,
                net_nominal_account: this.net_nominal_account,
                card: this.card,
                cash: this.cash,
                shipment: this.shipment,
                customer_address: this.customer_address,
                discount: this.total_discount,
              },
              0,
              "s"
            );
            this.router.navigate(["/receipt"]);
          }
        }
      }
    } else {
      this.toasterService.error(
        "No products Selected. Select receipt for payment!",
        "Error!",
        {
          timeOut: 5000,
        }
      );
    }
    // this.payment = this.payment ? this.payment : 0;
    // let credit = this.customer["current_credit"] + ((this.payment - this.total_after_VAT < 0) ?
    //   this.total_after_VAT - this.payment : 0);
    // credit = (credit >= 0.01) ? credit : 0;
    // this.amount_due = (this.amount_due >= 0.01) ? this.amount_due : 0;
    // console.log("hassan")
    // console.log(credit);
    // console.log(this.customer["credit_limit"]);
    // if (credit >= this.customer["credit_limit"]) {
    //   this.customer["current_credit"] = credit;
    //   if (this.currentUser.user_type != 'admin') {
    //     this.store_id = this.currentUser.store_id;
    //     this.local.set("store_id", this.store_id, 0, 's');
    //   }
    //   else {
    //     this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    //   }

    //   this.storesService.getStore(this.store_id).toPromise().then(data => {
    //     this.store = data[0];
    //   })
    //   this.local.set("tempSale", {
    //     "saleProducts": this.saleProducts, "returnSaleProducts": this.returnSaleProducts, "customer": this.customer,
    //     "total": this.total_after_VAT,
    //     "net_total": this.net_total, "transaction_id": this.transaction_id,
    //     "sold_by": this.currentUser.first_name.charAt(0) + this.currentUser.last_name.charAt(0),
    //     "amount_due": this.amount_due, "payment_type": this.payment_type,
    //     "payment_nominal_account": this.payment_nominal_account, "payment": this.payment,
    //     "store_id": this.store_id, "verified_by": this.verified_by,
    //     "company": this.company, "store": this.store, "vat_amount": this.vat_amount,
    //     "is_return": this.return, "currentUser": this.currentUser, "customer_ref": this.customer_ref,
    //     "return_id": this.return_id, "quotation_number": this.quotation_number,
    //     "extra_note": this.extra_note, "comments": this.comments,
    //     "net_nominal_account": this.net_nominal_account,
    //     "card": this.card, "cash": this.cash, "shipment": this.shipment, "customer_address": this.customer_address,
    //     "discount": this.total_discount
    //   }, 0, 's');
    //   this.router.navigate(['/receipt']);

    //  }
    //   else {
    //     this.confirmationDialogService.confirm('Credit limit exceeded..', `Do you want to proceed Partial Payment?`).then((confirmed) => {
    //       if (confirmed) {
    //         this.customer["current_credit"] = credit;
    //         if (this.currentUser.user_type != 'admin') {
    //           this.store_id = this.currentUser.store_id;
    //           this.local.set("store_id", this.store_id, 0, 's');
    //         }
    //         else {
    //           this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    //         }

    //         this.storesService.getStore(this.store_id).toPromise().then(data => {
    //           this.store = data[0];
    //         });
    //         this.local.set("tempSale", {
    //           "saleProducts": this.saleProducts, "returnSaleProducts": this.returnSaleProducts, "customer": this.customer,
    //           "total": this.total_after_VAT, "net_total": this.net_total,
    //           "sold_by": this.currentUser.first_name.charAt(0) + this.currentUser.last_name.charAt(0),
    //           "amount_due": this.amount_due, "payment_type": this.payment_type,
    //           "payment_nominal_account": this.payment_nominal_account, "payment": this.payment, "store_id": this.store_id,
    //           "verified_by": this.verified_by,
    //           "company": this.company, "store": this.store, "vat_amount": this.vat_amount,
    //           "is_return": this.return, "currentUser": this.currentUser, "customer_ref": this.customer_ref,
    //           "return_id": this.return_id, "quotation_number": this.quotation_number, "transaction_id": this.transaction_id,
    //           "extra_note": this.extra_note, "comments": this.comments, "net_nominal_account": this.net_nominal_account,
    //           "card": this.card, "cash": this.cash, "shipment": this.shipment, "customer_address": this.customer_address, "discount": this.total_discount
    //         }, 0, 's');
    //         this.router.navigate(['/receipt']);
    //       }
    //     }).catch(() => {
    //       console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
    //     });
    //   }
    // }
  };
  addProduct(a, i, product) {
    console.log("Inside addProduct");
    if (a) {
      console.log("Inside If", a);
      this.returnSaleProducts.push(product);
      this.net_total = 0;
      this.vat_amount = 0;
      this.total_after_VAT = 0;
      this.amount_due = this.total_after_VAT;
      for (let p of this.returnSaleProducts) {
        this.net_total =
          this.net_total * 1 +
          <number>p["discounted_price"] * <number>p["quantity"];
      }
      this.vat_amount = <number>((this.net_total * 20) / 100);
      if (this.return) {
        console.log("If Return True Hello");
        // console.log(<number>(this.restocking_fee * 1));
        // console.log(this.net_total);
        // console.log(this.vat_amount);
        if (this.restocking_fee) {
          this.total_after_VAT =
            <number>(this.net_total * 1 + this.vat_amount * 1) -
            <number>this.restocking_fee;
        } else {
          this.total_after_VAT = <number>(
            (this.net_total * 1 + this.vat_amount * 1)
          );
        }

        console.log(Math.round(this.total_after_VAT * 100) / 100);
        console.log(this.transaction["payment"], this.amount_due);
        
        if((Math.round(this.total_after_VAT * 100) / 100) < this.transaction["payment"]){
          this.amount_due = Math.round(this.total_after_VAT * 100) / 100;
          console.log("Hello from there", this.amount_due)
        }else{

          this.amount_due = this.transaction["payment"];
          console.log("Hello from here", this.amount_due)
        }
        
        if(this.transaction.total - this.transaction.payable > 0){
          // this.amount_due = this.transaction.total - this.transaction.payable;
          if((Math.round(this.total_after_VAT * 100) / 100) < this.transaction["payment"]){

            this.amount_due = this.total_after_VAT;
          }

          console.log("bye bye",this.amount_due)
        }

        
        this.save_original_payment(this.amount_due);
        
      } else {
        console.log("If Return False");
        this.total_after_VAT = <number>(
          (this.net_total * 1 + this.vat_amount * 1)
        );
        this.amount_due =
          this.total_after_VAT - this.total_discount - this.payment;
      }

      
    } else {
      console.log("Inside Else");
      var tempretSp = this.returnSaleProducts;
      this.returnSaleProducts = new Array<any>();
      tempretSp.forEach((element) => {
        if (product.product["product_id"] != element.product["product_id"]) {
          this.returnSaleProducts.push(element);
        }
      });
      this.net_total = 0;
      this.vat_amount = 0;
      this.total_after_VAT = 0;
      this.amount_due = this.total_after_VAT;
      for (let p of this.returnSaleProducts) {
        this.net_total =
          this.net_total * 1 +
          <number>p["discounted_price"] * <number>p["quantity"];
      }
      this.vat_amount = <number>((this.net_total * 20) / 100);

      if (this.return) {
        this.total_after_VAT =
          <number>(this.net_total * 1 + this.vat_amount * 1) -
          <number>this.restocking_fee;
        // this.amount_due = this.total_after_VAT - this.payment;
        if((Math.round(this.total_after_VAT * 100) / 100) < this.transaction["payment"]){
          this.amount_due = Math.round(this.total_after_VAT * 100) / 100;
        }else{

          this.amount_due = this.transaction["payment"];
        }
        console.log("amount due when unticked: ", this.amount_due)
      } else {
        this.total_after_VAT = <number>(
          (this.net_total * 1 + this.vat_amount * 1)
        );
        this.amount_due =
          this.total_after_VAT - this.total_discount - this.payment;
      }
      
    }
  }
  cancel(clicked?) {
    this.checked = [];
    this.quotation_heading = false;
    this.transaction_loaded = false;
    this.quotation_number = "";
    this.selected_transactions = [];
    this.returnSaleProducts = new Array<any>();
    this.saleProducts = new Array<any>();
    this.customer = "";
    this.verified_by = null;
    this.selectedProducts = "";
    this.payment = "";
    this.results = "";
    this.pro = "";
    this.lengthVar = "";
    this.queryField = new FormControl();
    this.net_total = 0;
    this.amount_due = 0;
    this.payment_type = "Cash";
    this.payment_nominal_account = "780";
    this.vat_amount = 0;
    this.total_after_VAT = 0;
    this.restocking_fee = 0;
    this.products = [];
    this.returns = [];
    this.quantityControl = new FormControl();
    this.productMultiCtrl = new FormControl();
    this.suspendedSaleLoaded = false;
    this.productMultiFilterCtrl = new FormControl();
    this.searching = false;
    this.filteredProductsMulti = new ReplaySubject<any>(1);
    this.customerMultiCtrl = new FormControl();
    this.customerMultiFilterCtrl = new FormControl();
    this.filteredCustomersMulti = new ReplaySubject<any>(1);
    this.payment_type = "Cash";
    this.payment_nominal_account = "780";
    this.customer_ref = "";
    this.shipment = "";
    this.customer_address = "";
    this.total_discount = 0;
    this.cash = 0;
    this.card = 0;
    this.customer_ref = "";
    this.shipment = "";
    this.customer_address = "";
    this.comments = "";
    this.extra_note = "";
    this.net_nominal_account = "4000";
    // set initial selection
    this.productMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchProduct(
            search,
            this.local.get("store_id"),
            this.in_stock_filter
          )
        )
      )
      .subscribe(
        (filteredProducts) => {
          this.searching = false;
          this.filteredProductsMulti.next(filteredProducts);
        },
        (error) => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
    this.customerMultiFilterCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap((search) =>
          this._searchService.searchCustomerSales(
            search,
            this.local.get("store_id")
          )
        )
      )
      .subscribe(
        (filteredCustomers) => {
          console.log("3");
          this.searching = false;
          this.filteredCustomersMulti.next(filteredCustomers);
        },
        (error) => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
    if (clicked) {
      this.local.remove("tempSale");
      this.local.remove("tempQuotationSale");
    }
    // this.toasterService.warning('Sale Cancelled Successfully', 'Cancelled!', {
    //   timeOut: 5000
    // });
  }
  deleteCustomer = function () {
    this.customer = false;
    if (this.receipt) {
      this.transactions = [];
      this.cancel();
    }

    this.customerMultiCtrl = new FormControl();
  };
  enableOtherPayment() {
    this.setOther = false;
    this.nominalService
      .getAllSalesNominalAccounts()
      .toPromise()
      .then((data) => {
        this.nominal_accounts = data;
        this.selectedAccounts = this.nominal_accounts;
        console.log(this.selectedAccounts);
      });
  }

  set_payment(paymentType) {
    console.log("Hassan");
    // this.cash = null;
    //this.card = null;
    //this.amount_due = this.total_after_VAT - this.total_discount;
    this.payment_type = paymentType.description
      ? paymentType.description
      : paymentType;
    this.payment_nominal_account = paymentType.code_number
      ? paymentType.code_number
      : { cash: "780", card: "782" };
    if (paymentType == "To Pay") {
      if (this.payment > 0) {
        // this.toasterService.error()
        this.toasterService.error(
          "Setting Payment amount to 0. To Pay cannot have a payment.",
          "Payment Mehod!",
          {
            timeOut: 5000,
          }
        );
        this.amount_due = this.total_after_VAT - this.total_discount;
        this.payment = 0;
      }
    }
  }
  setNetNominal = function (paymentType, event) {
    this.net_nominal_account = paymentType.code_number
      ? paymentType.code_number
      : paymentType;
  };
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@Component({
  selector: "add-raw-product-dialog",
  templateUrl: "../add-raw-product-dialog.html",
})
export class AddRawProductDialog {
  model;
  currentUser;
  sku;
  product_name;
  description;
  price;
  currentUserSubscription: Subscription;
  store_id;
  constructor(
    public local: LocalStorageService,
    public dialogRef: MatDialogRef<AddRawProductDialog>,
    private productsService: ProductsService,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToastrService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    window.scrollTo(0, 0);
    this.model = {};
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user[0];
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      });
  }

  onNoClick(data?): void {
    this.dialogRef.close(data);
  }
  submitrawProduct = function () {
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user[0];
        if (this.currentUser.user_type != "admin") {
          this.store_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, "s");
        } else {
          this.store_id =
            this.local.get("store_id") == "all"
              ? this.currentUser.store_id
              : this.local.get("store_id");
        }
      });
    this.confirmationDialogService
      .confirm("Please confirm..", `Are you sure to add Raw Product!`)
      .then((confirmed) => {
        if (confirmed) {
          this.model["store_id"] = this.store_id;
          this.productsService.submitrawProduct(this.model).subscribe(
            (data) => {
              this.toasterService.success(
                "Raw Product Added Successfully",
                "Congratulations!",
                {
                  timeOut: 5000,
                }
              );
              this.onNoClick(data);
            },
            (error) => {
              this.toasterService.error(
                "OOPS! Something went wrong!",
                "Failed!",
                {
                  timeOut: 5000,
                }
              );
            }
          );
        }
      })
      .catch(() => {
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        );
      });
  };
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@Component({
  selector: "customer-dialog",
  templateUrl: "../customer-dialog.html",
})
export class CustomerDialog {
  predefined = false;
  model;
  id;
  submitted = false;
  nominal_accounts;
  selectedAccounts;
  constructor(
    public dialogRef: MatDialogRef<CustomerDialog>,
    private customersService: CustomersService,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToastrService,
    private nominalService: NominalAccountsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.nominalService
      .getAllNominalAccounts()
      .toPromise()
      .then((data) => {
        this.nominal_accounts = data;
        this.selectedAccounts = this.nominal_accounts;
      });
    this.model = {};
    this.model["store_id"] = data.store_id;
    if (data.customer) {
      this.predefined = true;
      this.id = data.customer["customer_id"];
      this.model["customer_id"] = this.id;
      this.model["first_name"] = data.customer["first_name"];
      this.model["last_name"] = data.customer["last_name"];
      this.model["email_id"] = data.customer["email_id"];
      this.model["phone"] = data.customer["phone"];
      this.model["address"] = data.customer["address"];
      this.model["city"] = data.customer["city"];
      this.model["postcode"] = data.customer["postcode"];
      this.model["price_band"] = data.customer["price_band"];
      this.model["current_credit"] = data.customer["current_credit"];
      this.model["credit_limit"] = data.customer["credit_limit"];
      this.model["acc_id"] = data.customer["acc_id"];
      this.model["company_name"] = data.customer["company_name"];
    } else {
      this.id = "";
      this.model["customer_id"] = "";
      this.model["first_name"] = "";
      this.model["last_name"] = "";
      this.model["email_id"] = "";
      this.model["phone"] = "";
      this.model["address"] = "";
      this.model["city"] = "";
      this.model["postcode"] = "";
      this.model["price_band"] = "price_per_unit";
      this.model["current_credit"] = "";
      this.model["credit_limit"] = "";
      this.model["acc_id"] = "";
      this.model["company_name"] = "";
    }
  }

  onKey(value) {
    this.selectedAccounts = this.search(value);
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    let filter = value.toLowerCase();
    return this.nominal_accounts.filter(
      (option) =>
        option.code_number.toLowerCase().includes(filter) ||
        option.description.toLowerCase().includes(filter)
    );
  }
  onNoClick(): void {
    this.dialogRef.close(this.model);
  }
  onNoClick1(): void {
    this.dialogRef.close();
  }
  submit = function () {
    if (this.model["first_name"] === "") {
      this.toasterService.error("Account Name cannot be empty", "Failed!", {
        timeOut: 5000,
      });
      return;
    }
    this.submitted = true;
    if (this.submitted) {
      this.confirmationDialogService
        .confirm("Please confirm..", `Do you really want to submit customer?`)
        .then((confirmed) => {
          if (confirmed) {
            if (this.predefined) {
              this.customersService
                .updateCustomer(this.id, this.model, this.model["store_id"])
                .subscribe((data) => {
                  this.onNoClick();
                });
            } else {
              this.customersService
                .addCustomer(this.model, this.model["store_id"])
                .subscribe(
                  (data) => {
                    this.id = data.customer_id;
                    this.model["customer_id"] = this.id;
                    this.toasterService.success(
                      "Customer Added Successfully",
                      "Congratulations!",
                      {
                        timeOut: 5000,
                      }
                    );
                    this.onNoClick();
                  },
                  (error) => {
                    this.toasterService.error(
                      "OOPS! Something went wrong!",
                      "Failed!",
                      {
                        timeOut: 5000,
                      }
                    );
                  }
                );
            }
          }
        })
        .catch(() =>
          console.log(
            "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
          )
        );
    }
  };
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// this.salesService.submitSale(this.saleProducts, this.customer, this.net_total, this.amount_due, this.payment_type, this.currentUser, this.payment);
@Component({
  selector: "suspended-dialog",
  templateUrl: "../suspended-dialog.html",
})
export class SuspendedDialog {
  suspendedSales;
  constructor(
    public dialogRef: MatDialogRef<SuspendedDialog>,
    private productsService: ProductsService,
    public confirmationService: ConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.suspendedSales = data.suspendedSales;
  }
  get(suspendedSale) {
    this.dialogRef.close({ get: suspendedSale });
  }
  removeSuspendedSaleOnUnsuspend(sale) {
    this.dialogRef.close({ delete: sale });
  }
  deleteSuspendedSale(sale) {
    this.confirmationService
      .confirm("Please confirm..", `Are you sure to delete?`)
      .then((confirmed) => {
        if (confirmed) {
          this.dialogRef.close({ delete: sale });
        } else {
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// @Component({
//   selector: 'credit-limit-reached',
//   templateUrl: '../credit-limit-reached.html',
// })
// export class CreditLimitReachedDialog {
//   suspendedSales;
//   constructor(
//     public dialogRef: MatDialogRef<CreditLimitReachedDialog>,
//     private productsService: ProductsService, public confirmationService: ConfirmationDialogService,
//     @Inject(MAT_DIALOG_DATA) public data) {
//     this.suspendedSales = data.suspendedSales;
//   }
//   get(suspendedSale) {
//     this.dialogRef.close({ "get": suspendedSale });
//   }
//   deleteSuspendedSale(sale) {
//     this.confirmationService.confirm('Please confirm..', `Are you sure to delete?`).then((confirmed) => {
//       if (confirmed) {
//         this.dialogRef.close({ "delete": sale });
//       }
//       else {
//       }
//     }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
//   }
//   onNoClick(): void {
//     this.dialogRef.close();
//   }
// }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// this.salesService.submitSale(this.saleProducts, this.customer, this.net_total, this.amount_due, this.payment_type, this.currentUser, this.payment);
@Component({
  selector: "authentication-dialog",
  templateUrl: "../authentication-dialog.html",
})
export class AuthenticationDialog implements OnInit, AfterViewInit {
  verified_by: FormControl;
  submitted = false;
  myform: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AuthenticationDialog>,
    private salesService: SalesService,
    public confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  ngOnInit() {
    this.verified_by = new FormControl("", [
      Validators.minLength(6),
      Validators.maxLength(6),
    ]);
    this.myform = new FormGroup({
      verified_by: this.verified_by,
    });
    this.dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      this.dialogRef.close({ user: null, verification: "cancelled" });
    });
    let x = document.getElementById("verified_by");
    x.click();
    // console.log(x);
  }
  ngAfterViewInit() {
    let x = document.getElementById("verified_by");
    x.click();
    // console.log(x);
  }

  submit() {
    this.submitted = true;
    if (this.myform.status === "VALID") {
      this.confirmationDialogService
        .confirm("Please Confirm!", "Are you sure to submit?")
        .then((confirmed) => {
          if (confirmed) {
            if (this.verified_by.value != null) {
              this.salesService.authenticate(this.verified_by.value).subscribe(
                (data: Array<any>) => {
                  if (data.length != 1) {
                    this.toaster.error("Verification Failed", "Major Error", {
                      timeOut: 5000,
                    });
                    this.onNoClick(data, "failed");
                  } else {
                    this.toaster.success(
                      "Authentication Successful",
                      "Success",
                      {
                        timeOut: 5000,
                      }
                    );
                    this.onNoClick(data[0], "successful");
                  }
                },
                (error) => {
                  this.toaster.error("Verification Failed", "Major Error", {
                    timeOut: 5000,
                  });
                }
              );
            }
          }
        })
        .catch(() =>
          console.log(
            "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
          )
        );
    }
  }
  onNoClick(data, authentication_state): void {
    if (authentication_state == "cancelled") {
      this.toaster.warning("Verification Cancelled", "Major Error", {
        timeOut: 5000,
      });
    }
    this.dialogRef.close({
      user: data,
      authentication_state: authentication_state,
    });
  }
}
///////////////////////////////////////////////////////////////////////////////////////
@Component({
  selector: "cash-transactions-dialog",
  templateUrl: "../cash-transactions.html",
})
export class CashTransactionsDialog {
  customer;
  store_id;
  transactions;
  selected_transactions = [];
  checked = [];
  constructor(
    public dialogRef: MatDialogRef<CashTransactionsDialog>,
    private transactionsService: TransactionsService,
    private confirmationDialogService: ConfirmationDialogService,
    private salesService: SalesService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.salesService
      .getCustomerOutstanding(data.customer, data.store_id)
      .subscribe((data) => {
        // console.log(this.transactions)
        // console.log(typeof(this.transactions))
        this.transactions = data["transactions"];
        // console.log(this.transactions)
        // console.log(typeof(this.transactions))
      });
  }
  doneTransactions() {
    if (this.selected_transactions && this.selected_transactions.length > 0) {
      this.dialogRef.close(this.selected_transactions);
    } else {
      this.dialogRef.close();
    }
  }
  onKey(value) {}
  clearInv(value, index, transaction_id, outstanding) {
    console.log(this.transactions[index]);
    if (value == true) {
      this.selected_transactions.push(this.transactions[index]);
      // this.total_after_VAT = this.total_after_VAT + outstanding;
    } else {
      let indx = 0;
      this.selected_transactions.forEach((element) => {
        if (element.transaction_id == transaction_id) {
          this.selected_transactions.splice(indx, 1);
        }
        indx++;
      });

      console.log(this.selected_transactions);
    }
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    let filter = value.toLowerCase();
    // return this.nominal_accounts.filter(option => option.code_number.toLowerCase().includes(filter) || option.description.toLowerCase().includes(filter));
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onNoClick1(): void {
    this.dialogRef.close();
  }
}
