
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, AfterViewInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { SearchService } from '../_services/search/search.service';
import { TransactionsService } from '../sales/transactions/transactions.service';
// import { TransactionDialog } from '../transactions/transactions.component';
import { ActivatedRoute,Router } from '@angular/router';
// import { ViewProductTransaction } from '../view-product-transactions/view-product-transactions.component';
import { ReportingService } from './reporting.service';
import { CustomersService } from '../customers/customers.service';
import { ProductsService } from '../products/products.service';
import { StoresService } from '../stores/stores.service';
import { MatTableDataSource, PageEvent, MatPaginator, MatDatepickerInputEvent, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { tap, filter, } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ComponentFixtureNoNgZone } from '@angular/core/testing';
import { forEach } from '@angular/router/src/utils/collection';
import { ContentObserver } from '@angular/cdk/observers';
// import { ActivatedRoute } from '@angular/router';
enum TransactionsElement {
  transaction_id,
  type,
  total,
  payment,
  customer_id, customer_name,
  store_id, store_name,
  transaction_date,
  user_id, user_name, total_margin, total_percent_margin
}
enum ProductSoldOnElement {
  sell_id,
  product_id,
  store_id,
  sold_on_date,
  transaction_id,
  quantity_sold,
  customer_id,
  discount,
  price_sold_for
}

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit, OnDestroy, AfterContentChecked {
  period; invoices; product; customer; product_category; representative; which_period; from = new Date(); to = new Date(); day; month; year; date_f = new Date();
  selected_customer;
  daysOfTheWeek: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  monthsOfTheYear: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  store_id;
  colNames;
  products_data = []; products_source = new MatTableDataSource<TransactionsElement>(this.products_data); product_details: any;
  selectedColumns;
  dropdownList = []; reporting_subscription;
  selectedItems = [];
  dropdownSettings = {}; period_value;
  loaded = false;
  sort_by: FormControl;
  //////////////////////////////////
  displayedColumns: string[] = ["edit"
  ];
  only_table = false;
  ELEMENT_DATA: TransactionsElement[] = [];

  dataSource = new MatTableDataSource<TransactionsElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<TransactionsElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  report; years;
  date; stores;
  length; products_columns;
  pageSize = 25; transactions = [];
  pageSizeOptions: number[] = [5, 10, 25, 100]; pageIndex; pageEvent: PageEvent;
  products_pageIndex; products_pageSize = 10;
  products_pageEvent: PageEvent
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) products_paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent>; columns = [];
  // testarray=['sku','product id','test1'];
  currentUserSubscription; currentUser; store;
  protected _onDestroy = new Subject<void>();
  queryField: FormControl = new FormControl();
  protected customers: any[];

  /** control for the selected customer for multi-selection */
  public customerMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public customerMultiFilterCtrl: FormControl = new FormControl();

  public searching: boolean = false;
  /** list of customers filtered by search keyword */
  protected representatives: any[];

  /** control for the selected customer for multi-selection */
  public representativeMultiCtrl: FormControl = new FormControl(); selected_product;
 
  /** control for the MatSelect filter keyword multi-selection */
  public representativeMultiFilterCtrl: FormControl = new FormControl();

  /** list of customers filtered by search keyword */
  protected products: any[];

  /** control for the selected customer for multi-selection */
  public productMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public productMultiFilterCtrl: FormControl = new FormControl();
  @ViewChild(MatSort) sort: MatSort
  which_product = ""; which_cat = ""; which_customer = ""; which_rep = "";
  which_store;
  //sort_by = "";
  order_by = "asc";
  /** list of customers filtered by search keyword */
  arrData: any;
  constructor(private transactionsService: TransactionsService,
    private reportingService: ReportingService,
    private router: Router,
    public dialog: MatDialog,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    // private ViewProductTransaction: ViewProductTransaction,
    private toaster: ToastrService,
    private local: LocalStorageService,
    private _searchService: SearchService,
    private activatedRoute: ActivatedRoute) {
    window.scrollTo(0, 0);
    this.sort_by = new FormControl("");
    this.sort_by.valueChanges.subscribe(x => {

      this.pageIndex = 0;
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.pageSize;
      }
      this.search();
    });

    this.which_store = new FormControl("");
    this.which_store.valueChanges.subscribe(x => {

      this.pageIndex = 0;
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.pageSize;
      }
      this.search();
    });
    this.reportingService.getStores().subscribe(data => {
      this.stores = data;
    })


    //shamsail added
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }

  ngAfterContentChecked() {
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    if (this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
    // if(this.download_subscription) this.download_subscription.unsubscribe();
    if (this.reporting_subscription) this.reporting_subscription.unsubscribe();

  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 4,
      enableCheckAll: false,
      enableSelectAll: false,
    
    
    };
    
    
    
    this.loaded = true;
    // console.log(this.colNames);

    this.sort_by = new FormControl("");
    this.which_store = new FormControl("");
    this.which_store.valueChanges.subscribe(x => {

      this.pageIndex = 0;
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.pageSize;
      }
      this.search();
    });
    this.sort_by.valueChanges.subscribe(x => {

      this.pageIndex = 0;
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.pageSize;
      }
      this.search();
    });
    this.reportingService.getStores().subscribe(data => {
      this.stores = data;
    })
    var year = new Date().getFullYear();
    var range = [];
    // range.push(year);
    for (var i = 0; i < 10; i++) {
      range.push(year - i);
    }
    this.years = range;

    //shamsail
    if (this.local.get("transactions_margin")) {
      this.from = this.local.get("transactions_margin")["from"];
      this.to = this.local.get("transactions_margin")["to"];
    }
    else{

    }

  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    (type == "date_f") ? this.date_f = new Date(event.value) : (type == "from") ? this.from = new Date(event.value) : (type == "to") ? this.to = new Date(event.value) : "";
    if (type == "from") this.from = new Date(event.value);
    if (type == "to") this.to = new Date(event.value);

  }
  change(product) {
    this.selected_product = product.product_id;
    this.product_details = product;
    this.search();

  }
  getSum() {
    // debugger;
    // console.log(this.transactions);
    let sumArr = this.transactions.reduce((acc, cur) => {
      // console.log(cur);
      for (let key in cur) {
        if (key !== 'Name' && key !== 'SKU' && key !== 'Acc#' && key !== 'Description' && key !== 'User') {  // "We did this because there was only characters in RTYPE & CHANNEL , so we could not get sum of same."
          if (acc[key]) {
            acc[key] += cur[key]
          } else {
            acc[key] = cur[key]
          }
        }
      }

      return acc;
    }, {})
    // console.log('this is sum array');
    // console.log(sumArr);
    this.arrData = sumArr;
    this.arrData["Description"] = 'TOTAL'
    this.arrData["ACost"] = this.arrData["ACost"] / this.transactions.length;
    this.arrData["ASell"] = this.arrData["ASell"] / this.transactions.length;
    this.arrData["AMargin"] = this.arrData["AMargin"] / this.transactions.length;
    // console.log("Margin:",this.arrData["Margin"])
    // console.log("Total:",this.arrData["Total"])
    if (this.arrData["Margin"] && this.arrData["Total"])
    {
      this.arrData["Margin %"] = ( this.arrData["Margin"] / this.arrData["Total"] )*100;
    }
    else
    {
      this.arrData["Margin %"] = this.arrData["Margin %"] / this.transactions.length;
    }
    // console.log("this is array data");
    // console.log(this.arrData);


  }
  getColumnName(inp): string {
    if (inp == "transaction_id") return "Transaction Id";
    if (inp == "type") return "Payment Type";
    if (inp == "total") return "Total"; ///////////
    if (inp == "payment") return "Bill	Paid";
    if (inp == "customer_id" || inp == "customer_name") return "Customer";
    if (inp == "store_id" || inp == "store_name") return "Store";
    if (inp == "transaction_date") return "Transaction Date";
    if (inp == "user_id" || inp == "user_name") return "User";
    if (inp == "verified_by") return "Verified By";
    if (inp == "extra_note") return "Name";
    if (inp == "comments") return "Comments";
    if (inp == "total_margin") return "Margin";
    if (inp == "total_percent_margin") return "%age Margin";
  }
  onItemSelect(item: any) {
    this.selectedColumns = this.columns;
    // this.selectedColumns=this.columns;
    // console.log('selected items')
    // this.colNames = this.columns;
    // console.log("Selected items",this.columns);
    // this.transactionsService.setTransactionsColumnDisplay(item['item_id'], true).subscribe(data => { }
    // );
    // let itemIndex;
    // this.dropdownList.forEach((myObject, index) => {
    //   if (myObject["item_id"] == item["item_id"]) {
    //     itemIndex = index;
    //     this.displayedColumns.splice(itemIndex, 0, (item['item_id'] == "customer_id") ? "customer_name" :
    //       ((item['item_id'] == "store_id") ? "store_name" :
    //         ((item['item_id'] == "user_id") ? "user_name" : item["item_id"])));
    //   }
    // });

  }
  changeCustomer(event) {
  }
  //Need to change
  onItemDeSelect(item: any) {
    // this.colNames = this.columns;
    this.selectedColumns = this.colNames
    // this.selectedColumns=this.columns;
    // console.log('deselected items')
    // this.selectedColumns=this.colNames;
    // console.log(this.colNames)
    // this.columns=this.colNames;
    // console.log(this.columns)
    
  
    // this.transactionsService.setTransactionsColumnDisplay(item['item_id'], false).subscribe(data => { }
    // );
    // this.displayedColumns.splice(this.displayedColumns.indexOf(item["item_id"]), 1);
  }
  getNumberofTransactions = function () {
    this.transactionsService.getTransactionsCount(this.which_store).subscribe(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  
  // getAllTransactions(id) 
  // {
  //   this.router.navigate['/view/transactions/514'];
  //   // console.log("Store-id",this.which_store);
  //   this.reportingService.getAllTransactionIds(id,6).subscribe(respons => {
  //     console.log(respons);
  //     // this.length = respons[0]["COUNT(*)"];
  //   });
  //   // this.ViewProductTransaction.getAllTransactionIds(id,6);
  // }
  getReport(date) {
    this.transactionsService.getReport(date, this.which_store).subscribe(data => {
      this.report = data[0];
    })
  }
  getTransactionsShamsail(){
    // this.date_f.setHours(12, 30);
    // this.from.setHours(12, 30);
    // this.to.setHours(12, 30);
    // this.period_value = (this.which_period == 'day') ? this.day :
    //   (this.which_period == 'date_f') ? this.date_f :
    //     (this.which_period == 'month') ? this.month :
    //       (this.which_period == 'year') ? this.year :
    //         (this.which_period == 'range') ? {
    //           "from": this.from,
    //           "to": this.to
    //         } : null;

    this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.pageIndex+1 }, this.store_id,
        null,
        null, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
          
          this.ELEMENT_DATA = data;

         
          //this.transactions = data["items"];
          this.columns = ["Date","Inv Number","Item Sku","Item Name", "Quantity", "Unit Cost", "Unit Sold", "Total Cost","Total Selling", "Margin", "Percentage Margin"];
          this.length = data["count"];
          this.transactions = [];

          let tempElement = {};

          
          data["items"].forEach(element => {
            this.transactionsService.getTransaction(element["transaction_id"]).toPromise().then((subdata) => {
              console.log("subdata");
              console.log(subdata["transaction_details"]);
              subdata["transaction_details"].forEach(element1 => {
                let date = element["transaction_date"].split("T")[0].split("-");
                tempElement["Date"] = date[2]+"/"+date[1]+"/"+date[0];
                tempElement["Inv Number"] = element["transaction_number"];
                console.log(element1);
                tempElement["Item Sku"] = element1["sku"];
                tempElement["Item Name"] = element1["product_name"];
                tempElement["Quantity"] = element1["quantity_sold"];
                tempElement["Unit Cost"] = "£ " + element1["cost"];
                // tempElement["Unit Cost"] = tempElement["Unit Cost"].toFixed(2);
                tempElement["Unit Sold"] = "£ " + element1["price_sold_for"].toFixed(2);
                // tempElement["Unit Sold"] = tempElement["Unit Sold"].toFixed(2);
                tempElement["Total Cost"] = "£ " + (element1["quantity_sold"]*element1["cost"]).toFixed(2);
                // tempElement["Total Cost"] = tempElement["Total Cost"].toFixed(2);
                tempElement["Total Selling"] = "£ " + (element1["quantity_sold"]*element1["price_sold_for"]).toFixed(2);
                // tempElement["Total Selling"] = tempElement["Total Selling"].toFixed(2);
                //tempElement["Total Profit"] = "£" + ((element1["quantity_sold"]*element1["price_sold_for"])-(element1["quantity_sold"]*element1["cost"]));
                tempElement["Margin"] = (element1["price_sold_for"]-element1["cost"])/element1["price_sold_for"];
                tempElement["Margin"] = tempElement["Margin"].toFixed(2);
                tempElement["Percentage Margin"] = ((element1["price_sold_for"]-element1["cost"])/element1["price_sold_for"])*100;
                tempElement["Percentage Margin"] = tempElement["Percentage Margin"].toFixed(2);
                // console.log(element["quantity_sold"]);
                // console.log(element["best_price"]*element["price_sold_for"]);
                console.log("between shamsail");
                
                this.transactions.push(tempElement);
                tempElement = {};
              });
            });
          });


          //shamsail
          this.selectedColumns = this.columns;
          // Assigning Columns
          this.colNames = this.columns;

          var pageSettings = JSON.parse(localStorage.pageSettings);
          pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.pageIndex+1};
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings));

        });
    
  }
  getInvoicesTransactions(){
    this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.pageIndex+1 }, this.store_id,
        null,
        null, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
          console.log(data);
          console.log("shamsail");
          this.ELEMENT_DATA = data;

          

          this.transactions = data["items"];
          this.columns = ["transaction_date","transaction_number", "type", "store_name", "total", "total_margin"];
          this.length = data["count"];

          //shamsail
          this.selectedColumns = this.columns;
          // Assigning Columns
          this.colNames = this.columns;

          var pageSettings = JSON.parse(localStorage.pageSettings);
          pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.pageIndex+1};
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings));

          return data;
        });
  }
  getSubDataOfInvoices(id){
    this.transactionsService.getTransaction(id).toPromise().then((subdata) => {
              return subdata;
              // this.transaction = data["transaction"];
              // this.model = data["transaction_details"];
              // this.invoices = data["invoices"]?data["invoices"]: [];
              // this.customer = data["transaction"]["customer"];
              // this.store = data["transaction"]["store"];
              // this.company = data["transaction"]["company"];
              // this.sold_by = data["transaction"]["user"].first_name.charAt(0) + data["transaction"]["user"].last_name.charAt(0);
              // this.extra_note = this.transaction["extra_note"];
              // this.customer_ref = this.transaction["customer_ref"];
              // this.comments = this.transaction["comments"];
              // this.model.forEach(element => {
              //   element.display_price = (<number>(element.price_sold_for) + <number>(element.discount)) / 1.2;
              //   var str = (element.price_sold_for - element.discount).toFixed(2); // => '10.23'
              //   var number = Number(str);
              //   this.net_total = this.net_total + (element.price_sold_for / 1.2) * element.quantity_sold;
              // });
              // // this.net_total = this.net_total - this.transaction.discount
              // this.total = this.net_total  * 1.2;
              // this.vat_amount = (this.net_total* 20 / 100);
            });
  }
  getTransactions() {
    this.transactionsService.getAllTransactions({ "items_per_page": this.pageSize, "page": this.pageIndex }).subscribe((data: Array<TransactionsElement>) => {
      this.ELEMENT_DATA = data;

      console.log(data);
      console.log("shamsail");

      this.dataSource.paginator = this.paginator;
    });
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  search_search() {
    console.log("search function called");
    this.pageIndex = 0;

    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    
    console.log("entering search");
    this.search();
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TransactionsElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;

    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }
  toggleDD() {
    if (this.showdd1) {
      this.showdd1 = false;
    }
    else {
      this.showdd1 = true;
    }
  }
  search_new_invoices(change_in){
    if (change_in == "checkbox") {
      this.columns = [];
      this.sort_by.setValue("");
      console.log("hi shamsail .. are you there?");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();

  }
  search_new_product(change_in) {
    if (change_in == "checkbox") {
      this.columns = [];
      this.sort_by.setValue("");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  search_new_cat(change_in) {
    if (change_in == "checkbox") {
      this.columns = [];
      this.sort_by.setValue("");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  search_new_customer(change_in) {
    if (change_in == "checkbox") {
      this.columns = [];
      this.sort_by.setValue("");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  search_new_rep(change_in) {
    if (change_in == "checkbox") {
      this.columns = [];
      this.sort_by.setValue("");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  search_order() {

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  loadProducts() {
    if (this.product) {
      this.reportingService.getProductsReport().subscribe(data => {
        this.products_data = data["products"];
        this.products_source = new MatTableDataSource<any>(this.products_data);
        this.products_columns = data["columns"];
        this.products_source.paginator = this.products_paginator;
        this.products_source.sort = this.sort;

      })
    }
  }
  applyProductsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.products_source.filter = filterValue.trim().toLowerCase();
  }
  getSalesToday(event) {
    this.transactionsService.getTransactionsToday(
      this.from,
      this.to, this.which_store, event).subscribe((data: Array<TransactionsElement>) => {
        this.ELEMENT_DATA = data;

      });
  }
  search_store(change_in) {
    if (change_in == "checkbox") {
      this.columns = [];
      this.which_store.setValue("");
      this.sort_by.setValue("");
    }

    this.pageIndex = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSize;
    }
    this.search();
  }
  syncPrimaryPaginatorBelow(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.length = event.length;
    this.paginator.page.emit(event);
  }
  search(event?: PageEvent) {
    if(this.paginator !== undefined){
      this.pageIndex = this.paginator.pageIndex
      this.pageSize = this.paginator.pageSize
      this.length = this.paginator.length
    }
    console.log("//////////////////////////Inside search//////////////////////");
    this.date_f.setHours(12, 30);
    this.from.setHours(12, 30);
    this.to.setHours(12, 30);
    this.period_value = (this.which_period == 'day') ? this.day :
      (this.which_period == 'date_f') ? this.date_f :
        (this.which_period == 'month') ? this.month :
          (this.which_period == 'year') ? this.year :
            (this.which_period == 'range') ? {
              "from": this.from,
              "to": this.to
            } : null;
    // console.log(this.period, this.which_period)
    this.only_table = true;
    console.log("//////////////////////////Check Reporting subscription//////////////////////");
    if (this.reporting_subscription) {
      this.reporting_subscription.unsubscribe();
    }


    
    let data;
    let event1;
    if (event) {
      event1 = event;
    }
    else{
      event1 = {
        'length': this.length,
        'pageIndex': 0,
        'pageSize': this.pageSize,
        'previousPageIndex': 0
      };
    }

    data = {
      'sort_by': this.sort_by.value, 'order': this.order_by,
      "event": event1, 'store_id': this.store_id
    };


    data = this.makeDataForServer(data);
    console.log("shamsail");
    console.log(data);

    this.reporting_subscription = this.reportingService.getReportForPeriod(data).subscribe(result_data => {
      this.transactions = result_data["transactions"];
      console.log("transactions shamsail");
      console.log(this.transactions);
      this.columns = result_data["columns"]; this.getSum();
      this.length = result_data["count"]["count"];

      //shamsail
      this.selectedColumns = this.columns;

      // Assigning Columns
      
      this.colNames = this.columns;
      // console.log(this.columns ,'.... and ... is selected');
      
      
    });


    // console.log(this.columns , 'some representator endd')
    
    
      
    
      
    
    // console.log('here is end')
    // console.log(this.columns)
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  makeDataForServer(data){
    if(this.store){
      data['store'] = this.store;
      data['which_store'] = this.which_store.value;
      if(!this.customer && !this.representative && !this.period && !this.product){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
      if(!this.customer && !this.representative && this.period && !this.product){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
      if(!this.customer && this.representative && !this.period && !this.product){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
    }
    if(this.period){
      data['period'] = this.period;
      data['which_period'] = this.which_period;
      data['period_value'] = this.period_value;
    }
    if(this.product){
      data['product'] = this.product;
      data['which_product'] = this.which_product;

      if(!this.customer && !this.representative && !this.period){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
    }
    if(this.customer){
      data['customer'] = this.customer;
      data['which_customer'] = this.which_customer;

      if(!this.product && !this.representative && !this.period){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
    }
    if(this.representative){
      data['representative'] = this.representative;
      data['which_rep'] = this.which_rep;
    }

    if(this.invoices){
      data['invoices'] = this.invoices;
      this.getTransactionsShamsail();
    }
    
    return data;
  }

  makeDataForServerDownload(data){
    if(this.period){
      data['period'] = this.period;
      data['which_period'] = this.which_period;
      data['period_value'] = this.period_value;
    }
    if(this.product){
      data['product'] = this.product;
      data['which_product'] = this.which_product;

      if(!this.customer && !this.representative && !this.period){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
    }
    if(this.customer){
      data['customer'] = this.customer;
      data['which_customer'] = this.which_customer;

      if(!this.product && !this.representative && !this.period){
        data['product_category'] = this.product_category? this.product_category : "";
        data['which_cat'] = this.which_cat? this.which_cat : "";
      }
    }
    if(this.representative){
      data['representative'] = this.representative;
      data['which_rep'] = this.which_rep;
    }

    return data;
  }

  ConvertToCSV(objArray) {
    console.log("shamsail");
    console.log(this.selectedColumns);
    var tmp_array_remove = this.selectedColumns;
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";
    // console.log(objArray[0]); 

    for (var index in objArray[0]) {
      i++;
      var toSkip = tmp_array_remove.indexOf(index);
      if(toSkip==-1)
      {
        continue;
      }
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';
    // console.log(row);
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {

        var toSkip = tmp_array_remove.indexOf(index);
        if(toSkip==-1)
        {
          continue;
        }
        else
        {
          if (line != '')
          {
            line += ','
          }
          line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
  download() {
    this.date_f.setHours(12, 30);
    this.from.setHours(12, 30);
    this.to.setHours(12, 30);
    this.period_value = (this.which_period == 'day') ? this.day :
      (this.which_period == 'date_f') ? this.date_f :
        (this.which_period == 'month') ? this.month :
          (this.which_period == 'year') ? this.year :
            (this.which_period == 'range') ? {
              "from": this.from,
              "to": this.to
            } : null;
    // if (this.download_subscription) {
    //   this.download_subscription.unsubscribe();
    // }
    let csvData;


    var data = {
      'sort_by': this.sort_by.value, 'order': this.order_by, 'store_id': this.store_id
    };
    

    data = this.makeDataForServerDownload(data);
    console.log("shamsail");
    console.log(data);

    

    if(this.invoices && !this.customer && !this.product && !this.representative && !this.store && !this.period)
    {
      csvData = this.ConvertToCSV(this.transactions);
      console.log("/////////////////////////////////////////////////////////CUSTOMER/////////////////////////////////////////////////////");
      console.log(csvData);
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([csvData], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'Report.csv';
      a.click();

      return;
    }

    this.reportingService.downloadReportForPeriod(data).subscribe(result_data => {
      csvData = this.ConvertToCSV(result_data);
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([csvData], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'Report.csv';
      a.click();
    });
  }
}