import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor( private http: HttpClient) { }
  public getAllPromotions(){
    return  this.http.get(`${API_URL}promotions/`);
  }
  public getPromotion(id){
    return  this.http.get(`${API_URL}promotion/${id}/`);
  }
  public updatePromotion(id, promotion){
    return this.http.post(`${API_URL}update-promotion/${id}/`,promotion);
  }
  public addPromotion(promotion, store_id){
    promotion['store_id'] = store_id;
    return this.http.post(`${API_URL}add-promotion/`,promotion);
  }
  public getPromotionByProductID(id){
    return  this.http.get(`${API_URL}promotion-on-product/${id}/`);
  }
  public getPromotionsCount(store_id){
    return  this.http.post(`${API_URL}promotions-count/`, {"store_id":store_id});
  }
  public getPromotions(event, store_id){
    return  this.http.post(`${API_URL}get-promotions/`, {"event":event, "store_id": store_id});
  }
  public getPromotionsColumns() {
    return this.http.get(`${environment.apiUrl}promotions-columns/`);
  }
  public setPromotionsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-promotions-columns/`, {"item_id":item_id, "state":state});
  }
}