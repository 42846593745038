
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { RolesService } from '../roles/roles.service';
import { AuthenticationService, UserService } from '../_services';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { NgxSpinnerService} from 'ngx-spinner';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
})
export class LeftBarComponent implements OnInit {
  currentUser: any; roles;
  currentUserSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
    private rolesService: RolesService, private local: LocalStorageService,
    public spinner : NgxSpinnerService,
  ) {


    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      this.local.set("store_id", this.currentUser.store_id , 0, 's');
    });

    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").subscribe(data => {
      this.roles = data;
    })
  }

  ngOnInit() {
    
    this.rolesService.getRolesForType(this.currentUser ? this.currentUser.user_type : "").toPromise().then(data => {
      this.roles = data;
    });
  }


  // Toggle between showing and hiding the sidebar, and add overlay effect
  w3_open() {
    var mySidebar = document.getElementById("mySidebar");

  // Get the DIV with overlay effect
  var overlayBg = document.getElementById("myOverlay");
    if (mySidebar.style.display === 'block') {
      mySidebar.style.display = 'none';
      overlayBg.style.display = "none";
    } else {
      mySidebar.style.display = 'block';
      overlayBg.style.display = "block";
    }
  }

  // Close the sidebar with the close button
  w3_close() {
    var mySidebar = document.getElementById("mySidebar");

    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");
    mySidebar.style.display = "none";
    overlayBg.style.display = "none";
  }
  logout() {

    this.confirmationDialogService.confirm('Logout?', `Are you sure to Logout?`).then((confirmed) => {
      if (confirmed) {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  mouseEnter() {
    let element = document.getElementById("wrapper-mini-bar");
    if (element) {
      element.classList.remove("mini-bar");
    }
  }
  mouseLeave() {
    let element = document.getElementById("wrapper-mini-bar");
    if (element) {
      element.classList.add("mini-bar");
    }
  }
  subMenu(divId) {
    document.getElementById(divId).hidden = !document.getElementById(divId).hidden
  }
  onRefresh(route,query_parmas) {
    this.spinner.show()
    let redirectUrl = this.router.url.split('?')[0];
    if(route === redirectUrl) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      let currentUrl = this.router.url + '?';
      if(query_parmas !== ''){
        redirectUrl = redirectUrl + query_parmas
      }
      this.router.navigateByUrl(currentUrl)
        .then(() => {
          this.router.navigated = false;
          this.router.navigateByUrl(redirectUrl);
        });
    }
    else {
      this.router.navigate([route]);
    }
    setTimeout(() => {
      this.spinner.hide();
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return true;
      };
    }, 200);
  }
}
