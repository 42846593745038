import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsService } from '../sales//transactions/transactions.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { UserService, AuthenticationService } from '@app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delivery-note',
  templateUrl: './delivery-note.component.html',
  styleUrls: ['./delivery-note.component.css']
})
export class DeliveryNoteComponent implements OnInit {
  transaction_id; itemsToDeliver = new Array<any>(); currentUser: any;
  transaction; saleProducts; store; customer; checked = []; store_id; comments;
  currentUserSubscription: Subscription; customer_ref; extra_note; shipment; item_location;
  constructor(
    private route: ActivatedRoute, private router: Router,
    public local: LocalStorageService,
    private confirmationDialogService: ConfirmationDialogService,
    private transactionsService: TransactionsService,
    private toasterService: ToastrService,
    private authenticationService: AuthenticationService,
  ) {
    // this.transaction_id = this.route.snapshot.paramMap.get('transaction_id');
    // this.transactionsService.getTransactionForDelivery(this.transaction_id).subscribe(data=>{
    //   this.transaction = data["transaction"];
    //   this.saleProducts = data["products"];
    //   this.store = data["store"];
    //   this.customer = data["customer"];
    // })
  }

  ngOnInit() {
    this.transaction_id = this.route.snapshot.paramMap.get('transaction_id');

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }
    });
    var sale = this.local.get("tempDeliveryNote");
    console.log("Hello Sir!!!");
    console.log(sale);
    if (sale && sale.transaction.transaction_id == this.transaction_id) {
      console.log("Inside If");
      this.saleProducts = sale.saleProducts;
      this.store_id = sale.store_id;
      this.store = sale.store;
      this.customer = sale.customer;
      this.customer_ref = sale.customer_ref;
      this.extra_note = sale.extra_note;
      this.comments = sale.comments;
      this.transaction = sale.transaction;
      this.itemsToDeliver = sale.itemsToDeliver;
      // this.checked = sale.checked;
      for (let i = 0; i < this.saleProducts.length; i++) {
        // this.saleProducts[i].deliver_now = this.saleProducts[i]
        console.log("New Testing");

        this.addProduct(false, i, this.saleProducts[i]);
        this.addProduct(sale.checked[i], i, this.saleProducts[i]);
      }
    }
    else {
      //console.log("Inside Else");
      this.local.remove("tempDeliveryNote");
      this.transactionsService.getTransactionForDelivery(this.transaction_id).subscribe(data => {
        //console.log(data["products"]);
        this.transaction = data["transaction"][0];
        this.saleProducts = data["products"];
        console.log("Inside NgOnInit");
        console.log(this.saleProducts);
        console.log(this.itemsToDeliver);
        this.store = data["store"][0];
        this.customer = data["customer"][0];
      })
    }
  }
  ngOnDestroy() {
    console.log("Destroyed");
  }
  cancel = function(){
    this.local.remove("tempDeliveryNote");
    this.transaction ={};
    this.saleProducts = [];
    this.store = {};
    this.customer = {};
    this.router.navigate(['/transactions']);
  }
  quantityChange(event, i, left) {
    if (event > left) {
      this.toasterService.error("Items to deliver now should be less than or equal to Items Left.", "Incorrect Value", {
        timeOut: 5000
      });
      console.log(this.saleProducts[i]["product_id"]);
      document.getElementById("quantity_" + this.saleProducts[i]["product_id"])["value"] = left;
      this.saleProducts[i]["deliver_now"] = left;
    }
    else {
      this.saleProducts[i]["deliver_now"] = event;
      let ind = 0;
      this.itemsToDeliver.forEach(element => {
        if (this.saleProducts[i]["product_id"] == element["product_id"]) {
          this.itemsToDeliver[ind]["deliver_now"] = this.saleProducts[i]["deliver_now"];

        }
        ind++;
      });
    }
    console.log("Inside Quantity Change");
    console.log(this.itemsToDeliver);

  }

  addProduct(a, i, product) {
    console.log("Inside Add Product");
    console.log(product);
    console.log(a);
    this.checked[i] = a;
    if (a) {
      console.log("Inside If");
      this.itemsToDeliver.push(product);
    }
    else {
      console.log("Inside Else");
      // console.log(this.itemsToDeliver);
      var tempretSp = this.itemsToDeliver;
      this.itemsToDeliver = new Array<any>();
      tempretSp.forEach(element => {
        if (product["product_id"] != element["product_id"]) {
          this.itemsToDeliver.push(element);
          // console.log(this.itemsToDeliver);
        }
      });
    }

    console.log(this.itemsToDeliver);

  }
  submit() {
    let quantityToDeliver = 0;
    for (let i = 0; i < this.itemsToDeliver.length; i++) {
      if (!this.itemsToDeliver[i]["deliver_now"]) {
        quantityToDeliver += 0;
      } else {
        quantityToDeliver += this.itemsToDeliver[i]["deliver_now"];
      }     
    }
    for (let i = 0; i < this.saleProducts.length; i++) {
      if (!this.checked[i]) {
        this.saleProducts[i]["deliver_now"] = 0;
      }
    }
    console.log("Inside Submit");
    console.log(this.itemsToDeliver);
    console.log(quantityToDeliver);
    //console.log(this.saleProducts);
    if (this.itemsToDeliver.length == 0) {
      this.toasterService.error("Please select an item to deliver", "Nothing to deliver", {
        timeOut: 5000
      });
    }
    else if (this.itemsToDeliver.length > 0) {
      if (quantityToDeliver == 0) {
        this.toasterService.warning("Nothing to deliver.", "Sorry!", {
          timeOut: 5000
        });
      } else {
        this.confirmationDialogService.confirm('Please confirm..', `Do you really want to Complete Delivery? You won't be able to make changes after this!`).then((confirmed) => {
          if (confirmed) {
            this.local.set("tempDeliveryNote", {
              "saleProducts": this.saleProducts, "transaction_id": this.transaction_id,
              "customer": this.customer, "transaction": this.transaction,
              "sold_by": this.currentUser.first_name.charAt(0) + this.currentUser.last_name.charAt(0),
              "store_id": this.store_id, "store": this.store, "currentUser": this.currentUser, "customer_ref": this.customer_ref,
              "extra_note": this.extra_note, "comments": this.comments,
              "shipment": this.shipment, "itemsToDeliver": this.itemsToDeliver, "checked": this.checked
            }, 0, 's');
            this.router.navigate(['/picking-receipt']);
          }
        }).catch((error) => console.log(error));
      }    
    }
  }
}
