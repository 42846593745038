import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NominalAccountsService } from '../nominal-accounts/nominal-accounts.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';


@Component({
  selector: 'app-add-nominal-accounts',
  templateUrl: './add-nominal-accounts.component.html',
  styleUrls: ['./add-nominal-accounts.component.css']
})
export class AddNominalAccountsComponent implements OnInit {

  public id: string = null;
  model = {}; submitted = false;
  myform: FormGroup;
  
  acc_id: FormControl;
  code_number: FormControl;
  description: FormControl;
  debit: FormControl;
  credit: FormControl;
  for_sale_payment: FormControl;
  for_purchase_payment: FormControl;
  for_card: FormControl;
  constructor(private route: ActivatedRoute, private nominalService: NominalAccountsService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toaster: ToastrService) {
      window.scrollTo(0,0); }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.createFormControls();
    this.createForm();
    if (this.id != null) {
      this.nominalService.getNominalAccount(this.id).subscribe((data: Array<object>) => {
        this.myform.setValue(data[0]);
      });
    }
  }
  createFormControls() {
    this.acc_id = new FormControl('');
    this.code_number = new FormControl('', [Validators.required]);
    this.description = new FormControl('');
    this.debit = new FormControl('');
    this.credit = new FormControl('');
    this.for_sale_payment = new FormControl('');
    this.for_purchase_payment = new FormControl('');
    this.for_card = new FormControl('');
    
  }

  createForm() {
    this.myform = new FormGroup({
      acc_id: this.acc_id,
      code_number: this.code_number,
      description: this.description,
      debit: this.debit,
      credit: this.credit,
      for_sale_payment: this.for_sale_payment,
      for_purchase_payment: this.for_purchase_payment,
      for_card: this.for_card
    });
  }
  submit = function () {
    this.submitted = true;
    if (this.myform.status === "VALID") {
      this.confirmationDialogService.confirm('Submit?', `Are you sure to submit?`).then((confirmed) => {
        if (confirmed) {
          if (this.id != null) {
            this.nominalService.updateNominalAccount(this.id, this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/nominal']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              });
          }
          else {
            this.nominalService.addNominalAccount(this.myform.value).subscribe((data) => {
              this.toaster.success('Added successfully!', 'Success', {
                timeOut: 3000
              });
              this.router.navigate(['/nominal']);
            },
              error => {
                this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                  timeOut: 3000
                });
              });
          }
        }
      })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }
}
