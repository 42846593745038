import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { EmployeesService } from '../employees/employees.service';

import { ChangeDetectorRef } from '@angular/core';
enum EmployeesElement {
  user_id,
  first_name,
  last_name,
  username,
  password,
  created_on,
  user_type,
  store_id, store_name
}
@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  length; store_id;
  pageSize = 10;
  filter_dropdown = false;
   pageIndex; pageEvent: PageEvent;
  
  page;
  currentUser; currentUserSubscription;
  queryField: FormControl = new FormControl();
  loaded = false;
  columns;
  columns_selected = ['actions'];
  //////////////////////////////////
  displayedColumns: string[] = ['delete', 'edit'
  ];
  ELEMENT_DATA: EmployeesElement[];
  selection = new SelectionModel<EmployeesElement>(true, []);
  constructor(private employeesService: EmployeesService,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private local: LocalStorageService) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["employees"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["employees"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user[0];
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.getNumberofEmployees();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["employees"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["employees"]["current_page"];
    this.getServerData(null);
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchEmployee(data, this.store_id).toPromise().then((data: Array<EmployeesElement>) => {
        this.ELEMENT_DATA = data;
      });
    });
    this.employeesService.getEmployeesColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "store_id") ? "Store_name" : data[key]['column_name']);
        }
      }
    });
  }

  getColumnName(inp): string {
    if (inp == "user_id") return "User ID";
    if (inp == "first_name") return "First Name";
    if (inp == "last_name") return "Last Name";
    if (inp == "username") return "Username"; ///////////
    if (inp == "password") return "Password";
    if (inp == "created_on") return "Created On"; ///////////
    if (inp == "user_type") return "User Type";
    if (inp == "store_id" || inp == "Store_name") return "Store";
  }
  
  deleteUser = function (user_id) {
    this.employeesService.deleteUser(user_id).subscribe(data => {
      this.getServerData();
      this.cdr.detectChanges();
    });
  }
  selectionChange(item, i, event) {
    this.employeesService.setEmployeesColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.employees = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.employees = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  getNumberofEmployees = function () {
    this.employeesService.getEmployeesCount(this.store_id).toPromise().then(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  public getServerData(event?: PageEvent) {
    if (event) {
      this.employeesService.getEmployees(event, this.store_id).subscribe((data: Array<EmployeesElement>) => {
        this.ELEMENT_DATA = data;
        var pageSettings = JSON.parse(localStorage.pageSettings);
        
        pageSettings.employees = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
        localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
      });
    }
    else {
      var pageSettings = JSON.parse(localStorage.pageSettings);
      this.employeesService.getEmployees({
        'length': this.length,
        'pageIndex': pageSettings.employees.current_page,
        'pageSize': pageSettings.employees.items_per_page,
        'previousPageIndex': 0
      }, this.store_id).subscribe((data: Array<EmployeesElement>) => {
        this.ELEMENT_DATA = data;
      });
    }
    return event;
  }
  getEmployees() {
    this.employeesService.getAllEmployees().subscribe((data: Array<EmployeesElement>) => {
      this.ELEMENT_DATA = data;
    });
  }
  toggleDD(id) {
    if(document.getElementById(id).style.display == 'block'){
      document.getElementById(id).style.display = 'none';
    }
    else{
    document.getElementById(id).style.display = 'block';
    }
  }

}
