import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';

import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '@app/confirmation-dialog/confirmation-dialog.service';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PurchasesService {
  purchase_id; return_id; return_status; return_quantity=0; purchase_date = new Date();
  constructor(private http: HttpClient,
    private route: ActivatedRoute, private router: Router,
    public local: LocalStorageService,private toasterService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService) { }
  public returnProduct(data) {
    this.purchase_date = data.purchase_date;
    let r2: Array<any> = new Array();
    let r3: Array<any> = new Array();
    data.purchaseProducts.forEach(element => {
      this.return_quantity = this.return_quantity + element.quantity;
    });
    if(this.return_quantity>0){
    let r1 = this.http.post(`${API_URL}add-purchase-return/`,
      {
        "total": data.total_after_VAT, "vat": data.vat_amount, "net": data.net_total,
        "payment": data.payment ? ((data.payment != null && data.payment != undefined) ? data.payment : 0) : 0,
        "payment_type": data.payment_type, "payment_nominal_account":  data.payment_nominal_account,
        "supplier_id": data.supplier.supplier_id,    
        "store_id": data.store_id,
        "purchase_date": this.purchase_date,
        "user_id": data.currentUser.user_id,
        "verified_by": data.verified_by ? data.verified_by : null,
        "company_id": data.company ? data.company.company_id : null,
        "purchase_id": data.purchase_id,
        "supplier_ref": data.supplier_ref,
        "comments": data.comments,
        "shipment": data.shipment,
        "net_nominal_account": data.net_nominal_account,
        "vat_nominal_account": data.vat_nominal_account,
        "restocking_fee": data.restocking_fee, "additional_charges":  data.additional_charges
      }
    ).subscribe(result => {
      this.return_id = result["return_id"];
      data.purchaseProducts.forEach(element => {
        if(element.quantity > 0){
        r3.push(this.http.post(`${API_URL}return-purchase-product/`, {
          "purchase_id": data.purchase_id,
          "product_sku": element.sku,
          "product_id": element.product_id,
          "store_id": data.store_id,
          "return_id": this.return_id,
          "quantity": element.quantity,
          "supplier": data.supplier.supplier_id,
          "return_date": this.purchase_date,
          "return_price": element["cost"],
          "invoice": element["invoice"],
          "over_charge": element["over_charge"]
        }).subscribe(response => {
          if (response == "exceeded") {
            this.return_status = true;
            this.toasterService.error(element.product_name + ' Already returned', 'Failed!', {
            });
            r2.push(element.product_name);
            
            this.router.navigate(['/purchases']);
            return true;
          }
          else {
            this.toasterService.success(element.product_name + ' returned successful', 'Returned!', {
            });
            r2.push(element.product_name);
            this.return_status = false;
            this.local.remove("tempPurchase");
            this.router.navigate(['/purchases']);
            return false;
          }
        }), this.return_status);
      }
      });
    });
    return r2;
  }
  else{
    this.toasterService.warning("Nothing to return.", "Sorry!", {
      timeOut: 5000
    });
    this.local.remove("tempPurchase");
    this.router.navigate(['/purchases']);
  }
  }
  public submitPurchase(purchase_id, purchaseProducts, supplier, total, amount_due, currentUser, 
    payment, payment_type, payment_nominal_account, company, store_id, sold_by, net_amount, vat_amount, 
    supplier_ref, comments, shipment, additional_charges, net_nominal_account, vat_nominal_account, 
    purchase_date, discount) {
    let r2: Array<any> = new Array();
    let r3: Array<any> = new Array();
    let r1 = this.http.post(`${API_URL}add-purchase/`,
      {
        "purchase":{
        "purchase_id": purchase_id,
        "total": total,
        "net": net_amount,
        "vat": vat_amount,
        "payment": payment ? ((payment != null && payment != undefined) ? payment : 0) : 0,
        "payment_type": payment_type, "payment_nominal_account": payment_nominal_account,
        "supplier": supplier.supplier_id,
        "store_id": store_id,
        "supplier_ref": supplier_ref,
        "purchase_date": purchase_date,
        "user_id": currentUser.user_id,
        "company_id": company ? company.company_id : null,
        "comments": comments,
        "shipment": shipment,
        "additional_charges": additional_charges,
        "net_nominal_account": net_nominal_account,
        "vat_nominal_account": vat_nominal_account,
        "discount": discount
        },
        "purchase_products": purchaseProducts
      }
    ).toPromise().then(result => {
      this.toasterService.success('You have successfully added a products purchase.', 'Purchase Successfully!', {
      });
      this.local.remove("tempPurchase");
      this.router.navigate(['/purchases']);
    });

  }
  public getPurchaseID() {
    return this.http.get(`${API_URL}get-purchase-id/`);

  }
  
  public getPurchaseReturnID() {
    return this.http.get(`${API_URL}get-purchase-return-id/`);

  }
  public sendEmail(content) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    return this.http.post(`${API_URL}send-email/`, content)
  }
  public submitReceipt(data){
    return this.http.post(`${API_URL}submit-purchase-receipt/`, {"data": data});
  }

  public getPurchase(id){
    return  this.http.get(`${API_URL}purchase-transactions/view/${id}/`);
  }
  public addPurchaseReturn(data){
    this.purchase_date = data.purchase_date;
    data.purchaseProducts.forEach(element => {
      this.return_quantity = this.return_quantity + element.quantity;
    });
    if(this.return_quantity>0){
      this.http.post(`${API_URL}submit-purchase-return/`, data).toPromise().then(result => {
      
        this.toasterService.success('You have successfully returned products to supplier.', 'Purchase Successfully!', {
        });
        this.local.remove("tempPurchase");
        this.router.navigate(['/purchases']);
      });
    }
    else{
      this.toasterService.warning("Nothing to return.", "Sorry!", {
        timeOut: 5000
      });
      this.local.remove("tempPurchase");
      this.router.navigate(['/purchases']);
    }
  }
}