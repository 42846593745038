import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StoresService } from './stores.service';
import { PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';

enum StoresElement {
  store_id,     // 0
  store_name,         // 1
  address,       // 2
  city,
  postcode,
  phone,
  account_number
}

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  length;
  pageSize = 10;
   pageEvent: PageEvent; pageIndex;
  
  queryField: FormControl = new FormControl();
  currentUserSubscription;
  currentUser;
  store_id;
  filter_dropdown = false;
  page;
  columns;
  columns_selected = ['actions'];
  loaded = false;
  ELEMENT_DATA: StoresElement[];
  selection = new SelectionModel<StoresElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  constructor(private storesService: StoresService, private searchService: SearchService, private authenticationService: AuthenticationService,
    private toaster: ToastrService, private local: LocalStorageService, private cdr: ChangeDetectorRef) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["stores"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["stores"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.getNumberofStores();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["stores"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["stores"]["current_page"];
    this.getServerData(null);
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchStore(data, this.local.get("store_id")).toPromise().then((data: Array<StoresElement>) => {
        this.ELEMENT_DATA = data;
      });
    });
    this.storesService.getStoresColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "product_id") ? "product_name" : data[key]['column_name']);
        }
      }
      this.loaded = true;
    });
  }

  getColumnName(inp): string {
    if (inp == "store_id") return "Store ID";
    if (inp == "store_name") return "Name";
    if (inp == "address") return "Address";
    if (inp == "city") return "City"; ///////////
    if (inp == "postcode") return "Postcode";
    if (inp == "phone") return "Phone";
    if (inp == "account_number") return "Account#";
  }
  getNumberofStores = function () {
    this.storesService.getStoresCount().toPromise().then(respons => {
      this.length = respons[0]['COUNT(*)'];
    });
  };
  public getServerData(event?: PageEvent) {
    if (event) {
      this.storesService.getStores(event).subscribe((data: Array<StoresElement>) => {
        this.ELEMENT_DATA = data;
        var pageSettings = JSON.parse(localStorage.pageSettings);
        
        pageSettings.stores = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
        localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
      });
    } else {
      var pageSettings = JSON.parse(localStorage.pageSettings);
      this.storesService.getStores({
        'length': this.length,
        'pageIndex': pageSettings.stores.current_page,
        'pageSize': pageSettings.stores.items_per_page,
        'previousPageIndex': 0
      }).subscribe((data: Array<StoresElement>) => {
        this.ELEMENT_DATA = data;
      });
    }
    return event;
  }
  selectionChange(item, i, event) {
    this.storesService.setStoresColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push((this.columns[ind].column_name == "supplier_id") ? "supplier_name" :
        (((this.columns[ind].column_name == "store_id") ? "store_name" :
          (((this.columns[ind].column_name == "user_id") ? "first_name" : this.columns[ind].column_name)))));
        
      }
    }
    this.cdr.detectChanges();
  }
  
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.stores = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.stores = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  getStores() {
    this.storesService.getAllStores().subscribe((data: Array<StoresElement>) => {
      this.ELEMENT_DATA = data;
      var pageSettings = JSON.parse(localStorage.pageSettings);
      
      pageSettings.stores = {"items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"]};
      localStorage.setItem("pageSettings", JSON.stringify(pageSettings)); 
    });
  }
  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
}
