import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';
import { from } from 'rxjs';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor( private http: HttpClient) { }
  public getAllQuotations(){
    return  this.http.get(`${API_URL}quotations/`);
  }
  public getQuotation(id){
    return  this.http.get(`${API_URL}quotations/view/${id}/`);
  }
  public getQuotationsCount(store_id){
    return  this.http.post(`${API_URL}quotations-count/`, {"store_id":store_id});
  }
  public getTodaysQuotationsCount(store_id, from, to){
    return  this.http.post(`${API_URL}todays-quotations-count/`, {"from": from, "to": to, "store_id": store_id});
  }
  public getQuotations(event, store_id, to?, from?, order_by?, query?){
    return  this.http.post(`${API_URL}get-quotations/`, {"event":event, "store_id":store_id, "to":to, "from":from, "order_by":order_by, "query": query});
  }
  public getQuotationsColumns() {
    return this.http.get(`${environment.apiUrl}quotations-columns/`);
  }
  public setQuotationsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-quotations-columns/`, {"item_id":item_id, "state":state});
  }
  public getQuotationsToday(from, to, store_id, event, sort_by?){
    return this.http.post(`${API_URL}get-quotations-today/${store_id}/`, {"from":from, "to": to, "event":event, "sort_by": sort_by});
  }
  public getQuotationsCountToday(from, to, store_id, event?){
    return this.http.post(`${API_URL}todays-quotations/`, {"from":from,'to':to, "store_id": store_id});
  }
  public getReport(date, store_id){
    return this.http.post(`${API_URL}report/${store_id}/`, {"date":date});
  }
  public correctMargins(){
    return this.http.get(`${API_URL}correct-quotations-margin/`);
  }
  public resend(content){
    return this.http.post(`${API_URL}resend-email/`, content)
  }
  public setColumnsDisplay(columns){
    return this.http.post(`${API_URL}set-quotations-columns/`, columns)
  }
  public deleteQuotation(id){
    return this.http.post(`${API_URL}delete-quotation/`, {"quotation_id": id});
  }
  
}