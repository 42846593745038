import { Component, OnInit, ViewChild, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { NominalAccountsService } from './nominal-accounts.service';
import { MatTableDataSource, PageEvent, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from '@app/confirmation-dialog/confirmation-dialog.service';
enum NominalElement {
  acc_id,
  code_number,
  description,
  debit,
  credit
}
enum NominalTTElement {

  store_id,
  ntt_id,
  transaction_type,
  transaction_id,
  track_description,
  ntt_type,
  amount,
  acc_id,
  code_number,
  description,
  debit,
  credit,
  store_name,
  address,
  city,
  postcode,
  phone,
  account_number
}
@Component({
  selector: 'app-nominal-accounts',
  templateUrl: './nominal-accounts.component.html',
  styleUrls: ['./nominal-accounts.component.css']
})
export class NominalAccountsComponent implements OnInit {


  loaded = false;
  //////////////////////////////////
  displayedColumns: string[] = ['edit',
  ];
  columns;
  columns_selected = ['actions'];
  filter_dropdown = false;
  ELEMENT_DATA: NominalElement[];
  selection = new SelectionModel<NominalElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  length;
  currentUserSubscription;
  currentUser;
  store_id;
  pageSize = 10;
   pageIndex; pageEvent: PageEvent;
   page;

  queryField: FormControl = new FormControl();
  constructor(private nominalService: NominalAccountsService, private searchService: SearchService, private authenticationService: AuthenticationService,
    private toaster: ToastrService, private local: LocalStorageService, private cdr: ChangeDetectorRef,
    public dialog: MatDialog,) {
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["nominal_accounts"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["nominal_accounts"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["nominal_accounts"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["nominal_accounts"]["current_page"];
    this.getServerData({ "items_per_page": this.pageSize, "page": this.page });
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchNominalAccount(data, this.local.get("store_id"), { "items_per_page": this.pageSize, "page": this.page },).toPromise().then((data: Array<NominalElement>) => {
        this.ELEMENT_DATA = data;
      });
    });
    this.nominalService.getNominalAccountsColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "store_id") ? "store_name" : data[key]['column_name']);
        }
      }
      this.loaded = true;
    });
  }

  getColumnName(inp): string {
    if (inp == "acc_id") return "AccID";
    if (inp == "code_number") return "Code Number";
    if (inp == "description") return "Description";
    if (inp == "debit") return "Debit"; ///////////
    if (inp == "credit") return "Credit";
  }
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.nominal_accounts = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.getServerData({ "items_per_page": this.pageSize, "page": this.page });
    
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.nominal_accounts = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.getServerData({ "items_per_page": this.pageSize, "page": this.page });
  }
  selectionChange(item, i, event) {
    this.nominalService.setNominalAccountsColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  public getServerData(event) {
      var pageSettings = JSON.parse(localStorage.pageSettings);
      this.nominalService.getNominalAccounts(event, this.queryField.value).subscribe((data: Array<NominalElement>) => {
        this.ELEMENT_DATA = data;
        pageSettings.nominal_accounts = { "items_per_page": this.pageSize, "current_page": this.page };
        localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
      });
    }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  EXPORTcsv() {
    let csvData;
    this.nominalService.getAllNominalAccounts().subscribe(data => {
      csvData = this.ConvertToCSV(data);
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([csvData], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'NominalAccounts.csv';
      a.click();
    });
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */

  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
  viewNominalTransactions = function (acc_code): void {
    const dialogRef = this.dialog.open(NominalTransactionsDialog, {
      height: '100%',
      width: '85%',
      data: { "acc_code": acc_code }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
@Component({
  selector: 'nominal-transactions-dialog',
  templateUrl: 'nominal-transactions-dialog.html',
})
export class NominalTransactionsDialog {
  predefined = false; model; id; submitted = false; errorInPhone = false; transactions; from; to; order_by; date;
  selectedColumns;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  loaded = false;
  //////////////////////////////////
  displayedColumns: string[] = [
  ];
  ELEMENT_DATA: NominalTTElement[];
  selection = new SelectionModel<NominalElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  length;
  currentUserSubscription;
  currentUser;
  store_id;
  pageSize = 10;
   pageIndex; pageEvent: PageEvent;
  
  @Output() page: EventEmitter<PageEvent>;

  queryField: FormControl = new FormControl();
  selectedAccounts;
  constructor(
    public dialogRef: MatDialogRef<NominalTransactionsDialog>, private searchService: SearchService,
    private confirmationDialogService: ConfirmationDialogService, private authenticationService: AuthenticationService,
    private toasterService: ToastrService, private nominalService: NominalAccountsService, private local: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data) {
    window.scrollTo(0, 0);
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
      }

    });
    this.nominalService.getNominalTransactionsTrack(data).toPromise().then((data: NominalTTElement[]) => {
      this.ELEMENT_DATA = data;
    });

    this.nominalService.getNominalTTColumns().toPromise().then(data => {
      let i = 0;
      for (const key in data) {
        i++;
        this.dropdownList.push({ item_id: data[key]['column_name'], item_text: this.getColumnName(data[key]['column_name']) });
        if (data[key]['show'] == 1) {
          this.selectedItems.push({ item_id: data[key]['column_name'], item_text: data[key]['column_name'] });
          this.displayedColumns.push(data[key]['column_name']);
        }
      }
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        itemsShowLimit: 5,
        enableCheckAll: false,
        enableSelectAll: false,
      };
      this.loaded = true;
    });
  }
  getColumnName(inp): string {
    if (inp == "transaction_type") return "Transaction Category";
    if (inp == "transaction_id") return "Transaction ID";
    if (inp == "track_description") return "Account Name";
    if (inp == "code_number") return "Account Code";
    if (inp == "ntt_type") return "Transaction Type"; ///////////
    if (inp == "amount") return "Amount";
    if (inp == "description") return "For";
    if (inp == "store_name") return "Store Name";
    if (inp == "amount") return "Amount";
    if (inp == "transaction_date") return "Date";
  }
  onItemSelect(item: any) {
    this.nominalService.setNominalAccountsColumnDisplay(item['item_id'], true).subscribe(data => { }
    );
    let itemIndex;
    this.dropdownList.forEach((myObject, index) => {
      if (myObject["item_id"] == item["item_id"]) {
        itemIndex = index + 1;
        this.displayedColumns.splice(itemIndex, 0, item['item_id']);
      }
    });

  }
  onItemDeSelect(item: any) {
    this.nominalService.setNominalAccountsColumnDisplay(item['item_id'], false).subscribe(data => { }
    );
    this.displayedColumns.splice(this.displayedColumns.indexOf(item["item_id"]), 1);
  }

  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
  onNoClick(): void {
    this.dialogRef.close(this.model);
  }
  onNoClick1(): void {
    this.dialogRef.close();
  }



}
