import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from "@angular/common/http";
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "angular-web-storage";

import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogService } from "@app/confirmation-dialog/confirmation-dialog.service";

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: "root",
})
export class SalesService {
  transaction_id;
  return_id;
  return_status;
  return_quantity = 0;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public local: LocalStorageService,
    private toasterService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}
  public returnProduct(data) {
    let r2: Array<any> = new Array();
    let r3: Array<any> = new Array();
    data.saleProducts.forEach((element) => {
      this.return_quantity = this.return_quantity + element.quantity;
    });
    if (this.return_quantity > 0) {
      let r1 = this.http
        .post(`${API_URL}add-return/`, {
          payment_type: data.payment_type,
          total: data.total_after_VAT,
          payment: data.payment
            ? data.payment != null && data.payment != undefined
              ? data.payment
              : 0
            : 0,
          payment_nominal_account: data.payment_nominal_account,
          customer_id: data.customer.customer_id,
          store_id: data.store_id,
          transaction_date: "",
          cash: data.cash,
          card: data.card,
          user_id: data.currentUser.user_id,
          verified_by: data.verified_by ? data.verified_by : null,
          company_id: data.company ? data.company.company_id : null,
          transaction_id: data.transaction_id,
          return_id: data.return_id,
          extra_note: data.extra_note,
          net_nominal_account: data.net_nominal_account,
          restocking_fee: data.restocking_fee,
          transactions_to_clear: data.transactions_to_clear,
          current_credit: data.customer.current_credit
        })
        .subscribe((result) => {
          this.return_id = result["return_id"];
          data.saleProducts.forEach((element) => {
            console.log(element.quantity);
            if (element.quantity > 0) {
              r3.push(
                this.http
                  .post(`${API_URL}return-product/`, {
                    transaction_id: data.transaction_id,
                    product_sku: element.product.sku,
                    store_id: data.store_id,
                    return_id: this.return_id,
                    quantity: element.quantity,
                    customer: data.customer.customer_id,
                    discount: element.promotion
                      ? element.promotion.discount
                      : 0,
                    price_sold_for: element["price_on_promotion"],
                  })
                  .subscribe((response) => {
                    if (response == "exceeded") {
                      this.return_status = true;
                      this.toasterService.error(
                        element.product.product_name + " Already returned",
                        "Failed!",
                        {}
                      );
                      r2.push(element.product.product_name);

                      this.router.navigate(["/sales"]);
                      return true;
                    } else {
                      this.toasterService.success(
                        element.product.product_name + " returned successful",
                        "Returned!",
                        {}
                      );
                      r2.push(element.product.product_name);
                      this.return_status = false;
                      this.local.remove("tempSale");
                      this.local.remove("transaction_id");
                      this.local.remove("tempQuotationSale");
                      this.local.remove("return_id");
                      this.router.navigate(["/sales"]);
                      return false;
                    }
                  }),
                this.return_status
              );
            }
          });
        });
      return r2;
    } else {
      this.toasterService.warning("Nothing to return.", "Sorry!", {
        timeOut: 5000,
      });
      this.local.remove("tempSale");
      this.local.remove("transaction_id");
      this.local.remove("tempQuotationSale");
      this.router.navigate(["/sales"]);
    }
  }

  public saveTransaction(
    saleProducts,
    customer,
    quotation_number,
    total,
    amount_due,
    payment_type,
    payment_nominal_account,
    currentUser,
    payment,
    verified_by,
    company,
    store_id,
    sold_by,
    vat_amount,
    customer_ref,
    extra_note,
    comments,
    net_nominal_account,
    card,
    cash,
    shipment,
    customer_address,
    discount
  ) {
    var total_margin = 0;
    var total_cost = 0;
    saleProducts.forEach((element) => {
      total_cost =
        <number>total_cost +
        (<number>element["product"]["cost"] +
          (<number>element["product"]["cost"] * 20) / 100) *
          <number>element["quantity"];
      element["margin"] =
        <number>element["price_on_promotion"] * <number>element["quantity"] -
        (<number>element["product"]["cost"] +
          <number>((element["product"]["cost"] * 20) / 100)) *
          <number>element["quantity"];
      element["percent_margin"] =
        (<number>element["margin"] /
          (<number>element["price_on_promotion"] *
            <number>element["quantity"])) *
        100;
      total_margin = <number>total_margin + <number>element["margin"];
    });
    total_margin = <number>total_margin / 1.2;
    var percent_margin =
      ((<number>total - <number>total_cost) / <number>total) * 100;
    let initial_credit = Math.round((total - payment) * 100) / 100;
    return this.http.post(`${API_URL}make-transaction/`, {
      transaction: {
        payment_type: payment_type,
        payment_nominal_account: payment_nominal_account,
        total: Math.round(total * 100) / 100,
        payment: payment
          ? payment != null && payment != undefined
            ? payment
            : 0
          : 0,
        customer: customer.customer_id,
        store_id: store_id,
        customer_ref: customer_ref,
        transaction_date: "",
        user_id: currentUser.user_id,
        verified_by: verified_by ? verified_by : null,
        company_id: company ? company.company_id : null,
        total_margin: Math.round(total_margin * 100) / 100,
        total_percent_margin: percent_margin,
        extra_note: extra_note,
        comments: comments,
        net_nominal_account: net_nominal_account,
        cash: cash,
        card: card,
        shipment: shipment,
        customer_address: customer_address,
        discount: discount,
        quotation_number: quotation_number,
        initial_credit: initial_credit
      },
      saleProducts: saleProducts,
    });
  }

  public submitSale(
    transaction_id,
    saleProducts,
    customer,
    quotation_number,
    total,
    amount_due,
    payment_type,
    payment_nominal_account,
    currentUser,
    payment,
    verified_by,
    company,
    store_id,
    sold_by,
    vat_amount,
    customer_ref,
    extra_note,
    comments,
    net_nominal_account,
    card,
    cash,
    shipment,
    discount
  ) {
    var total_margin = 0;
    var total_cost = 0;
    saleProducts.forEach((element) => {
      total_cost =
        <number>total_cost +
        (<number>element["product"]["cost"] +
          (<number>element["product"]["cost"] * 20) / 100) *
          <number>element["quantity"];
      element["margin"] =
        <number>element["price_on_promotion"] * <number>element["quantity"] -
        (<number>element["product"]["cost"] +
          <number>((element["product"]["cost"] * 20) / 100)) *
          <number>element["quantity"];
      element["percent_margin"] =
        (<number>element["margin"] /
          (<number>element["price_on_promotion"] *
            <number>element["quantity"])) *
        100;
      total_margin = <number>total_margin + <number>element["margin"];
    });
    total_margin = <number>total_margin / 1.2;
    var percent_margin =
      ((<number>total - <number>total_cost) / <number>total) * 100;

    let r2: Array<any> = new Array();
    let r3: Array<any> = new Array();
    let r1 = this.http
      .post(`${API_URL}add-transaction/`, {
        transaction_id: transaction_id,
        payment_type: payment_type,
        payment_nominal_account: payment_nominal_account,
        total: total,
        payment: payment
          ? payment != null && payment != undefined
            ? payment
            : 0
          : 0,
        customer: customer.customer_id,
        store_id: store_id,
        customer_ref: customer_ref,
        transaction_date: "",
        user_id: currentUser.user_id,
        verified_by: verified_by ? verified_by : null,
        company_id: company ? company.company_id : null,
        total_margin: total_margin,
        total_percent_margin: percent_margin,
        extra_note: extra_note,
        comments: comments,
        net_nominal_account: net_nominal_account,
        cash: cash,
        card: card,
        shipment: shipment,
        discount: discount,
        quotation_number: quotation_number,
      })
      .toPromise()
      .then((result) => {
        this.transaction_id = result["transaction_id"];

        saleProducts.forEach((element) => {
          console.log(element);
          r2.push(
            this.http
              .post(`${API_URL}update-stock/`, {
                product_id: element.product.product_id,
                quantity: element.quantity,
                store_id: store_id,
              })
              .subscribe((res) => {
                let margin =
                  element.quantity * element["price_on_promotion"] -
                  res["total_cost"] * 1.2;
                let percent_margin = (margin / total) * 100;
                r3.push(
                  this.http
                    .post(`${API_URL}add-products-sold-on/`, {
                      product_id: element.product.product_id,
                      store_id: store_id,
                      transaction_id: this.transaction_id,
                      quantity: element.quantity,
                      customer: customer.customer_id,
                      discount: element.promotion
                        ? element.promotion.discount
                        : 0,
                      price_sold_for: element["price_on_promotion"],
                      margin: margin,
                      percent_margin: percent_margin,
                      stock_track: res["ret_ele"],
                      name_at_time: element.product.product_name,
                      index: element.index,
                      quantity_delivered: element.quantity_delivered,
                    })
                    .subscribe((response) => {
                      this.http
                        .post(`${API_URL}transaction-margin/`, {
                          transaction_id: this.transaction_id,
                        })
                        .subscribe();
                    })
                );
              })
          );

          this.local.remove("tempSale");
          this.local.remove("transaction_id");
          this.local.remove("tempQuotationSale");
          this.router.navigate(["/sales"]);
          return this.transaction_id;
        });
      });
  }

  public getSalesTransactionByCustomerID(customer_id, product_id) {
    return this.http.get(
      `${API_URL}get-sales-transactions-by-customer/${customer_id}/${product_id}`
    );
  }

  public transactionMargin(transaction_id) {
    return this.http
      .post(`${API_URL}transaction-margin/`, { transaction_id: transaction_id })
      .subscribe();
  }

  public getTransactionID(store_id) {
    return this.http.post(`${API_URL}get-transaction-id/`, {
      store_id: store_id,
    });
  }

  public getReturnID(store_id) {
    return this.http.post(`${API_URL}get-return-id/`, { store_id: store_id });
  }

  public getDefaultCustomer() {
    return this.http.get(`${API_URL}get-default-customer/`);
  }

  public getNetSalesNominal() {
    return this.http.get(`${API_URL}sales-net-account/`);
  }

  public authenticate(authentication_code) {
    return this.http.post(`${API_URL}authenticate/`, {
      verified_by: authentication_code,
    });
  }
  public sendEmail(content) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });

    //   return this.http.post(`${API_URL}send-email/`, JSON.stringify(content), {
    //     "headers": headers
    // })
    return this.http.post(`${API_URL}send-email/`, content);
  }
  public resend(content, data) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = { headers: headers };
    let data_to_send = [];
    data_to_send.push(data);
    data_to_send.push(JSON.stringify(content));
    return this.http.post(
      `${API_URL}resend-email/${data["customer"]["email_id"]}/`,
      JSON.stringify(content),
      options
    );
    // return this.http.post(`${API_URL}resend-email/`, {"content" : JSON.stringify(content), "data": data}, options);
  }
  public submitReceipt(data) {
    return this.http.post(`${API_URL}submit-receipt/`, data);
  }
  public getCustomerOutstanding(customer, store_id?) {
    if (store_id) {
      return this.http.post(`${API_URL}get-cash-customer-transactions/`, {
        customer: customer,
        store_id: store_id,
      });
    } else {
      return this.http.post(`${API_URL}get-customer-outstanding/`, customer);
    }
  }
  public mailDataSale(data) {
    return this.http.post(`${API_URL}mail-data-sale/`, data);
  }
  public addDeliveryNote(data) {
    return this.http.post(`${API_URL}add-delivery-note/`, data);
  }
}
