import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'product-csv-upload',
  templateUrl: './product-csv-upload.component.html',
  styleUrls: ['./product-csv-upload.component.scss']
})
export class ProductCSVUploadComponent implements OnInit {
  file_path; data; loading = false; url_segment = "";
  constructor(private papa: Papa, private productsService: ProductsService, private router: Router, private route: ActivatedRoute) {
    // console.log(this.route.url._value[1].path);
    // console.log(this.route);
    this.route.queryParams.subscribe(params => {
      if (params['import'] == "products") {
        this.url_segment = "csv-import";
      }
      else if (params['import'] == "stock") {
        this.url_segment = "add-stock";
      }
    });
  }

  ngOnInit() {
  }
  done = function () {
  }
  ConvertCSVtoJSON() {
    this.loading = true;
    // this.productsService.addProductCSV(this.products, this.purchaseFromSupplier, this.stock).subscribe((data: Array<object>) => {
    if (this.url_segment == "csv-import") {
      this.productsService.addProductCSV(this.data).subscribe((data: Array<object>) => {
        this.router.navigate(['/products']);
      }, err => { this.loading = false; }
      );
    }
    else {
      // this.productsService.addProductCSV(this.products, this.purchaseFromSupplier, this.stock).subscribe((data: Array<object>) => {
      if (this.url_segment == "add-stock") {
        this.productsService.addStockCSV(this.data).subscribe((data: Array<object>) => {
          this.router.navigate(['/products']);
        }, err => { this.loading = false; }
        );
      }
    }
  }
  stock = [];
  purchaseFromSupplier = [];
  products = [];
  // category_relation = [];
  handleFileSelect(evt) {
    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          this.data = results;
          for (let i = 0; i < results.data.length; i++) {
            let product = {
              sku: results.data[i].sku,
              alternate_sku: results.data[i].alternate_sku,
              product_name: results.data[i].product_name,
              product_description: results.data[i].product_description,
              category_id: results.data[i].category_name,
              price_per_unit: results.data[i].price_per_unit,
              reorder_level: results.data[i].reorder_level,
              discontinued: results.data[i].discontinued,
              cost: results.data[i].cost,
              default_price: results.data[i].default_price,
              best_price: results.data[i].best_price,
              upc: results.data[i].upc,
              brand: results.data[i].brand,
              quantity: results.data[i].quantity
            }
            if (product.sku) {
              this.products.push(product);
              let inventory = {
                total_purchased_by_all_suppliers: results.data[i].total_purchased_by_all_suppliers,
                total_sold: results.data[i].total_sold,
                in_stock: results.data[i].in_stock,
                store_id: results.data[i].store_name
              };
              this.stock.push(inventory);
              let purchase = {
                supplier_id: results.data[i].supplier_name,
                quantity: results.data[i].quantity
              };
              this.purchaseFromSupplier.push(purchase);
            }

          }
          console.log('Parsed: k', results.data);
        }
      });
    }
  }
}
