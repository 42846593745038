
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { TransactionsService } from '../sales/transactions/transactions.service';
import { CustomersService } from '../customers/customers.service';
import { ProductsService } from '../products/products.service';
import { StoresService } from '../stores/stores.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, PageEvent, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import { DataService } from '@app/_services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.scss']
})
export class DeliveryNotesComponent implements OnInit {
  type = null; order_by = 'desc'; id = null;
  loaded = false; from = null; to = null;
  //////////////////////////////////
  displayedColumns: string[] = ["edit"
  ];
  filter_dropdown = false;
  showdd1 = false;
  showDeleteButton = false;
  report;
  date; tempColumns;
  length; page;
  pageSize = 10; sort_by;
  pageIndex; pageEvent: PageEvent;
  ELEMENT_DATA;
  currentUserSubscription; currentUser; store_id;
  columns;
  columns_selected = ['actions'];

  queryField: FormControl = new FormControl();
  constructor(private transactionsService: TransactionsService,
    public dialog: MatDialog,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private local: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef) {

    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["delivery_notes"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["delivery_notes"]["current_page"];
    // this.transactionsService.correctMargins().subscribe();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
    this.getDeliveryNotes({ "items_per_page": this.pageSize, "page": this.page });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["delivery_notes"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["delivery_notes"]["current_page"];
    this.queryField.valueChanges.subscribe(data => {
      this.transactionsService.getDeliveryNotes({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe((data) => {
        this.ELEMENT_DATA = data;
      });
    });
    this.transactionsService.getDeliveryNoteColumnsDisplay().subscribe(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "customer_id") ? "customer_name" :
            (((data[key]['column_name'] == "store_id") ? "store_name" :
              (((data[key]['column_name'] == "user_id") ? "user_name" : data[key]['column_name'])))));

        }
      }
      this.loaded = true;
    });

  }
  selectionChange(item, i, event) {
    this.transactionsService.setDeliveryNotesColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
  getDeliveryNotes(paginator?){
    this.transactionsService.getDeliveryNotes(paginator? paginator :{ "items_per_page": this.pageSize, "page": this.page }, this.store_id).subscribe(data => {
      this.ELEMENT_DATA = data;
    })
  }
  getDeliveryNotesForRange(){
    this.transactionsService.getDeliveryNotes({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe(data => {
      this.ELEMENT_DATA = data;
    })
  }
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.deliver_notes = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.getDeliveryNotes({ "items_per_page": this.pageSize, "page": this.page });
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.deliver_notes = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.getDeliveryNotes({ "items_per_page": this.pageSize, "page": this.page });
  }
  getColumnName(inp): string {
    if (inp == "transaction") return "Invoice";
    if (inp == "dn_number") return "DN#";
    if (inp == "dn_date") return "Date"; ///////////
    if (inp == "delivery_address") return "Address";
    if (inp == "customer" || inp == "customer_name") return "Customer";
    if (inp == "customer_details") return "Details";
    if (inp == "user") return "User";
    if (inp == "store" || inp == "store_id") return "Store";
    if (inp == "comments") return "Comments";
  }

}
