import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NominalAccountsService {

  constructor( private http: HttpClient) { }
  public getAllNominalAccounts(){
    return  this.http.get(`${API_URL}nominal-accounts/`);
  }
  public getAllNominalAccountsNet(){
    return  this.http.get(`${API_URL}nominal-accounts-net/`);
  }
  public getAllSalesNominalAccounts(){
    return  this.http.get(`${API_URL}sales-nominal-accounts/`);
  }
  public getAllPurchasesNominalAccounts(){
    return  this.http.get(`${API_URL}purchases-nominal-accounts/`);
  }
  public getNominalAccount(id){
    return  this.http.get(`${API_URL}nominal-account/${id}`);
  }
  public updateNominalAccount(id, nominal_account){
    return this.http.post(`${API_URL}update-nominal-account/${id}/`,nominal_account);
  }
  public addNominalAccount(nominal_account){
    return this.http.post(`${API_URL}add-nominal-account/`,nominal_account);
  }
  public getNominalAccountsCount(){
    return  this.http.get(`${API_URL}nominal-accounts-count/`);
  }
  public getNominalAccounts(event, query?){
    return  this.http.post(`${API_URL}get-nominal-accounts/`, {"paginator": event, "query": query});
  }
  public getNominalAccountsColumns() {
    return this.http.get(`${environment.apiUrl}nominal-accounts-columns/`);
  }
  public setNominalAccountsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-nominal-accounts-columns/`, {"item_id":item_id, "state":state});
  }
  public getNominalTTColumns() {
    return this.http.get(`${environment.apiUrl}nominal-tt-columns/`);
  }
  public setNominalTTColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-nominal-tt-columns/`, {"item_id":item_id, "state":state});
  }
  public getNominalTransactionsTrack(code_number){
    return this.http.post(`${API_URL}get-nominal-transactions-track/`, code_number);
  }
  public addNominalAccountCSV(data){
    return this.http.post(`${API_URL}add-nominals-csv/`, data);
  }
  public transferBalance(data){
    return this.http.post(`${API_URL}transfer-balance/`, data);
  }
}
