import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog-new',
  templateUrl: './confirmation-dialog-new.component.html',
})
export class ConfirmationDialogComponentNew implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnProceedText: string;
  @Input() btnCancelText: string;
  @Input() btnPaymentText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close('decline');
  }

  public proceed() {
    this.activeModal.close('proceed');
  }

  public acpayment() {
    this.activeModal.close('payment');
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
