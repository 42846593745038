import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient ,HttpHeaders, HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor( private http: HttpClient) { }
  public getAllCustomers(){
    return  this.http.get(`${API_URL}customers/`);
  }
  public getCustomer(id, store_id){
    return  this.http.post(`${API_URL}customer/${id}/`, store_id);
  }
  public updateCustomer(id, customer, store_id){
    if(store_id != "none" && store_id != "undefined") 
    {customer['store_id'] = store_id;}
    else{
    }
    return this.http.post(`${API_URL}update-customer/${id}/`,customer);
  }
  public addCustomer(customer, store_id){
    customer['store_id'] = store_id;
    return this.http.post(`${API_URL}add-customer/`,customer);
  }
  public getCustomers(event, store_id, sort_by?, order_by?, query?){
    return  this.http.post(`${API_URL}get-customers/`, {"paginator":event, "store_id": store_id, "sort_by":sort_by,  "order_by":order_by,  "query":query});
  }
  public getCustomerMonthly(months, store_id){
    return  this.http.post(`${API_URL}get-customer-monthly/`, {"months": months, "store_id": store_id});
  }
  
  public setSelectedCustomer(id){
    return this.http.get(`${API_URL}set-customer-selection/${id}`);
  }
  public getCustomersCount(store_id){
    return  this.http.post(`${API_URL}customers-count/`, {"store_id":store_id});
  }
  public getCustomersColumns() {
    return this.http.get(`${environment.apiUrl}customers-columns/`);
  }
  public setCustomersColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-customers-columns/`, {"item_id":item_id, "state":state});
  }
  public addCustomerCSV(csvData){
    return this.http.post(`${API_URL}add-customer-csv/`, csvData);
  }

  public getCustomerStatements(id){
    return this.http.post(`${API_URL}get-customer-statement/`, id);
  }
  public getCustomerStatementsColumns(){
    return this.http.get(`${API_URL}get-customer-statements-columns/`);
  }
  public setCustomerStatementsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-customer-statements-columns/`, {"item_id":item_id, "state":state});
  }
  public getReturn(id){
    return this.http.get(`${API_URL}returns/view/${id}`);
  }
  public getTransaction(id){
    return this.http.get(`${API_URL}transactions/view/${id}`);
  }
  public deleteCustomer(id){
    return this.http.post(`${API_URL}delete-customer/`, {"customer_id": id});
  }
  public addShortCustomer(name){
    return this.http.post(`${API_URL}add-short-customer/`, {"name": name});
  }
  public mergefirstnameandlastnamecs(a) {
    return this.http.post(`${API_URL}merge-first-name-and-last-name/`, { "test": a });
  }
  public resend(content, data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    let data_to_send = [];
    data_to_send.push(data);
    data_to_send.push(JSON.stringify(content));
    return this.http.post(`${API_URL}resend-email/${data["customer"]["email_id"]}/`, JSON.stringify(content), options);
    // return this.http.post(`${API_URL}resend-email/`, {"content" : JSON.stringify(content), "data": data}, options);
  }
}