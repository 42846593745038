import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SmtpService } from "./smtp.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-email-creds",
  templateUrl: "./email-creds.component.html",
  styleUrls: ["./email-creds.component.css"],
})
export class EmailCredsComponent implements OnInit {
  emailCreds: any;

  constructor(
    private smtpService: SmtpService,
    private toasterService: ToastrService
  ) {
    this.emailCreds = { host: "", password: "" };
  }

  async ngOnInit() {
    try {
      setTimeout(() => {
        this.toasterService.info("Loading Email Credentials");
      });

      const data = await this.smtpService.getEmailCreds().toPromise();
      // console.log(data);
      if (data["result"].length) {
        this.emailCreds = data["result"][0];
      }
      // console.log(this.emailCreds);
    } catch (error) {
      console.error(error);
      this.toasterService.error(
        "Failed to load Email Credentials",
        "Error"
      );
    }
  }

  updateCreds() {
    this.toasterService.info("Updating Email Credentials");

    this.smtpService
      .updateEmailCreds({
        host: this.emailCreds.host,
        password: this.emailCreds.password,
      })
      .subscribe(
        (res) => {
          this.toasterService.success(
            "Email Credentials Updated",
            "Success"
          );
        },
        (error) => {
          console.log(error);
          this.toasterService.error(
            "Something went wrong...",
            "Error"
          );
        }
      );
  }
}
