﻿import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
} from "@angular/material";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxLoadingModule } from "ngx-loading";
import { MaterialModule } from "./material.module";
import { NgxSpinnerModule } from "ngx-spinner";

// used to create fake backend
import { ViewProductTransactionsComponent } from "./view-product-transactions/view-product-transactions.component";

import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import { SearchService } from "./_services/search/search.service";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import { ColumnsService } from "./_services/columns.service";
import { DataService, DataShareService } from "./_services/data.service";

import { AlertComponent } from "./_components";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login";
import { RegisterComponent } from "./register";
import { NavComponent } from "./nav/nav.component";
import { LeftBarComponent } from "./left-bar/left-bar.component";
import { FooterComponent } from "./footer/footer.component";
import { RawProductsComponent } from "./raw-products/raw-products.component";
import { ProductImagesDialog } from "./products/products/products.component";
import { StockProductImagesDialog } from "./stock/products/products.component";
import { EditStockDialog } from "./stock/products/products.component";
import { EditInvoiceDialog } from "./sales/transactions/transactions.component";
import { AdminStoreSelectDialog } from "./login/login.component";
import { EmployeesComponent } from "./employees/employees.component";
import { StoresComponent } from "./stores/stores.component";
import { CompaniesComponent } from "./companies/companies.component";
import { ReceiptComponent } from "./receipt/receipt.component";
import { ProductImagesComponent } from "./product-images/product-images.component";
import { PromotionsComponent } from "./promotions/promotions.component";
import { AddProductComponent } from "./add-product/add-product.component";
import { AddStoreComponent } from "./add-store/add-store.component";
import { AddEmployeeComponent } from "./add-employee/add-employee.component";
import { AddPromotionComponent } from "./add-promotion/add-promotion.component";
import { AddProductVariationComponent } from "./add-product-variation/add-product-variation.component";
import { ProductCSVUploadComponent } from "./product-csv-upload/product-csv-upload.component";
import { CustomerCSVUploadComponent } from "./customer-csv-upload/customer-csv-upload.component";
import {
  AddProductInformationComponent,
  AddProductCategoryDialog,
  AddProductSubCategoryDialog,
  AddProductSubSubCategoryDialog,
} from "./add-product-information/add-product-information.component";
import { LeftBarDialog } from "../app/nav/nav.component";
import { PapaParseModule } from "ngx-papaparse";
import { NgSelectModule } from "@ng-select/ng-select";
import { AddCompanyComponent } from "./add-company/add-company.component";

import {
  NgbModule,
  NgbPaginationModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AngularWebStorageModule } from "angular-web-storage";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogComponentNew } from "./confirmation-dialog-new/confirmation-dialog-new.component";
import { ConfirmationDialogService } from "./confirmation-dialog/confirmation-dialog.service";
import { ConfirmationDialogServiceNew } from "./confirmation-dialog-new/confirmation-dialog-new.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";

import { RolesComponent } from "./roles/roles.component";
import { ToastrModule } from "ngx-toastr";
import { EditProductComponent } from "./edit-raw-product/edit-raw-product.component";

import "ng-bootstrap-to-bootstrap-3";
import { ReportingComponent } from "./reporting/reporting.component";
import { NgxTinymceModule } from "ngx-tinymce";
import { NgxEditorModule } from "ngx-editor";
import { FileUploadModule } from "ng2-file-upload";
import {
  NominalAccountsComponent,
  NominalTransactionsDialog,
} from "./nominal-accounts/nominal-accounts.component";
import { AddNominalAccountsComponent } from "./add-nominal-accounts/add-nominal-accounts.component";
import { PurchaseReceiptComponent } from "./purchase-receipt/purchase-receipt.component";
import {
  QuotationsComponent,
  QuotationDialog,
} from "./quotations/quotations.component";
import { QuotesComponent } from "./quote/quote.component";
import { EditQuotesComponent } from "./edit-quote/edit-quote.component";
import { NominalAccountCSVUploadComponent } from "./nominal-csv-upload/nominal-csv-upload.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PurchasePaymentComponent } from "./purchase-payment/purchase-payment.component";
import { MakePurchasePaymentComponent } from "./make-purchase-payment/make-purchase-payment.component";
import { NominalTransferComponent } from "./nominal-transfer/nominal-transfer.component";
import { BreadcrumbModule } from "angular-crumbs";
import { DeliveryNoteComponent } from "./delivery-note/delivery-note.component";
import { DeliveryNotesComponent } from "./delivery-notes/delivery-notes.component";
import { DeliveryReceiptComponent } from "./delivery-receipt/delivery-receipt.component";
import { PickingReceiptComponent } from "./picking-receipt/picking-receipt.component";
import {
  AuthenticationDialog,
  AddRawProductDialog,
  CustomerDialog,
  SuspendedDialog,
  CashTransactionsDialog,
} from "./sales/sales/sales.component";
import { PurchaseTransactionDialog } from "./purchases/purchase-transactions/purchase-transactions.component";
import { PurchaseReturnDialog } from "./purchases/purchase-returns/purchase-returns.component";
import { PurchaseReceiptDialog } from "./purchases/purchase-receipts/purchase-receipts.component";
import { EmailCredsComponent } from "./email-creds/email-creds.component";
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MaterialModule,
    ReactiveFormsModule,
    routing,
    PapaParseModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    AngularWebStorageModule,
    NgbPopoverModule,
    NgxEditorModule,
    NgbPaginationModule,
    FileUploadModule,
    NgxSpinnerModule,
    BreadcrumbModule,

    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxTinymceModule.forRoot({
      // baseURL: './assets/tinymce/'
      // or cdn
      baseURL: "//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/",
    }),
  ],
  // exports:[
  //     NgbPagination,
  // ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NavComponent,
    LeftBarComponent,
    FooterComponent,
    NavComponent,
    HomeComponent,
    LeftBarComponent,
    FooterComponent,
    EmployeesComponent,
    StoresComponent,
    CompaniesComponent,
    AddCompanyComponent,
    PromotionsComponent,
    ReceiptComponent,
    AddProductComponent,
    NominalTransferComponent,
    AddStoreComponent,
    AddEmployeeComponent,
    AddPromotionComponent,
    AddProductVariationComponent,
    ProductCSVUploadComponent,
    CustomerCSVUploadComponent,
    AddProductInformationComponent,
    LoginComponent,
    AlertComponent,
    RegisterComponent,
    AddProductCategoryDialog,
    AddProductSubCategoryDialog,
    AddProductSubSubCategoryDialog,
    ConfirmationDialogComponent,
    ConfirmationDialogComponentNew,
    LeftBarDialog,
    AuthenticationDialog,
    ProductImagesDialog,
    StockProductImagesDialog,
    EditStockDialog,
    EditInvoiceDialog,
    RawProductsComponent,
    EditProductComponent,
    RolesComponent,
    AddRawProductDialog,
    PurchaseReceiptComponent,
    ReportingComponent,
    ProductImagesComponent,
    NominalAccountsComponent,
    AddNominalAccountsComponent,
    NominalTransactionsDialog,
    QuotationsComponent,
    QuotationDialog,
    QuotesComponent,
    EditQuotesComponent,
    NominalAccountCSVUploadComponent,
    NotFoundComponent,
    PurchasePaymentComponent,
    MakePurchasePaymentComponent,
    AdminStoreSelectDialog,
    PurchaseReturnDialog,
    PurchaseReceiptDialog,
    DeliveryNoteComponent,
    PickingReceiptComponent,
    DeliveryReceiptComponent,
    DeliveryNotesComponent,
    CustomerDialog,
    SuspendedDialog,
    CashTransactionsDialog,
    PurchaseTransactionDialog,
    ViewProductTransactionsComponent,
    EmailCredsComponent,
  ],
  entryComponents: [
    LeftBarDialog,
    AddProductCategoryDialog,
    ConfirmationDialogComponent,
    ConfirmationDialogComponentNew,
    AddProductSubCategoryDialog,
    AddProductSubSubCategoryDialog,
    PurchaseReturnDialog,
    PurchaseReceiptDialog,
    QuotationDialog,
    AddRawProductDialog,
    PurchaseTransactionDialog,
    AuthenticationDialog,
    NominalTransactionsDialog,
    AdminStoreSelectDialog,
    ProductImagesDialog,
    StockProductImagesDialog,
    EditStockDialog,
    EditInvoiceDialog,
    CustomerDialog,
    SuspendedDialog,
    CashTransactionsDialog,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    SearchService,
    ConfirmationDialogService,
    ConfirmationDialogServiceNew,
    DataService,
    ColumnsService,
    DataShareService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
