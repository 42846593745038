import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, RouterModule } from '@angular/router';
import { EmployeesService } from '../employees/employees.service';
import { StoresService } from '../stores/stores.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { CompaniesService } from '@app/companies/companies.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService } from 'angular-web-storage';
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  public id: string;
  model; stores;
  stor_id; comp_id;
  myform: FormGroup;
  first_name: FormControl;
  last_name: FormControl;
  username: FormControl;
  password: FormControl;
  store_id: FormControl;
  company_id: FormControl;
  user_type: FormControl;
  created_on: FormControl;
  user_id: FormControl;
  auth_code: FormControl;
  deleted: FormControl;
  user_types; companies;
  submitted = false;currentUserSubscription;currentUser;
  constructor(private route: ActivatedRoute, private employeesService: EmployeesService,
    private router: Router, private storesService: StoresService, private companiesService: CompaniesService,
    private confirmationDialogService: ConfirmationDialogService, private toaster: ToastrService,
    private authenticationService: AuthenticationService,
    private local: LocalStorageService) {
      window.scrollTo(0,0);
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user? user[0] : "";
        if (this.currentUser.user_type != 'admin') {
          this.stor_id = this.currentUser.store_id;
          this.local.set("store_id", this.store_id, 0, 's');
        }
        else {
          this.stor_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
        }
  
      });
     }


  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.companiesService.getAllCompanies().subscribe((data: Array<object>) => {
      this.companies = data;
    });
    this.storesService.getAllStores().subscribe((data: Array<object>) => {
      this.stores = data;
    });
    if (this.id != null) {
      this.employeesService.getEmployee(this.id).subscribe((data: Array<object>) => {
        this.myform.setValue(data[0]);
      });
    }
    this.employeesService.getUserTypes().subscribe(data => {
      this.user_types = data;
    });
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.stor_id = this.currentUser.store_id;
        this.local.set("store_id", this.stor_id, 0, 's');
      }
      else {
        this.stor_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
    this.createFormControls();
    this.createForm();
  }
 
  createFormControls() {
    this.user_id = new FormControl('');
    this.first_name = new FormControl('', Validators.required);
    this.last_name = new FormControl('');
    this.username = new FormControl('', [
      Validators.required
    ]);
    this.auth_code = new FormControl("");
    this.deleted = new FormControl("");
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(15)
    ]);
    this.store_id = new FormControl("");
    this.company_id = new FormControl('');
    this.user_type = new FormControl('', [
      Validators.required,
    ]);
    this.created_on = new FormControl('');
  }

  createForm() {
    this.myform = new FormGroup({
      user_id: this.user_id,
      first_name: this.first_name,
      last_name: this.last_name,
      username: this.username,
      password: this.password,
      store_id: this.store_id,
      company_id: this.company_id,
      user_type: this.user_type,
      created_on: this.created_on,
      auth_code: this.auth_code,
      deleted: this.deleted
    });
  }
  submit = function () {
    if(!this.store_id){
    this.store_id.setValue(this.stor_id);
    }
    // if(this.myform.controls.store_id.value == null){ this.myform.controls['store_id'].setValue(this.stor_id); }
    this.submitted = true;
    if (this.myform.status === "VALID") {
      this.confirmationDialogService.confirm('Submit Employee?')
        .then((confirmed) => {
          if (confirmed) {
            if (this.id != null) {
              this.employeesService.updateEmployee(this.id, this.myform.value).subscribe((data) => {
                this.toaster.success('Added successfully!', 'Success', {
                  timeOut: 3000
                });
                this.router.navigate(['/employees']);
              },
                error => {
                  this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                    timeOut: 3000
                  });
                });
            } else {
              this.employeesService.addEmployee(this.myform.value).subscribe((data) => {
                this.toaster.success('Added successfully!', 'Success', {
                  timeOut: 3000
                });
                this.router.navigate(['/employees']);
              },
                error => {
                  this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                    timeOut: 3000
                  });
                });
            }
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }
}
