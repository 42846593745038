import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CompaniesService } from './companies.service';
import { MatTableDataSource, PageEvent, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SearchService } from '../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';

enum CompaniesElement {
  company_id,     // 0
  company_name,         // 1
  address,       // 2
  city,
  postcode,
  phone,
  account_number
}

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  length;
   pageEvent: PageEvent; pageIndex;
  
  queryField: FormControl = new FormControl();
  currentUserSubscription;
  currentUser;
  company_id;
  page;
  filter_dropdown = false;
  pageSize = 10;
  columns;
  columns_selected = ['actions'];
  loaded = false;
  ELEMENT_DATA: CompaniesElement[];
  selection = new SelectionModel<CompaniesElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  constructor(private companiesService: CompaniesService, private searchService: SearchService, private authenticationService: AuthenticationService,
    private toaster: ToastrService, private cdr: ChangeDetectorRef, private local: LocalStorageService) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["companies"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["companies"]["current_page"];
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user[0];
      if (this.currentUser.user_type != 'admin') {
        this.company_id = this.currentUser.company_id;
      }
      else {
        this.currentUser.company_id = this.local.get("store_id");
      }

    });
  }

  ngOnInit() {
    this.getNumberofCompanies();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["companies"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["companies"]["current_page"];
    this.queryField.valueChanges.subscribe(data => {
      this.searchService.searchCompany(data, this.local.get("store_id")).toPromise().then((data: Array<CompaniesElement>) => {
        this.ELEMENT_DATA = data;
      });
    });

    this.getCompanies();

    this.companiesService.getCompaniesColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "store_id") ? "store_name" : data[key]['column_name']);
        }
      }
    });
  }

  getColumnName(inp): string {
    if (inp == "company_name") return "Company";
    if (inp == "store_name") return "Store";
  }
 
  selectionChange(item, i, event) {
    this.companiesService.setCompaniesColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }
  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.companies = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.companies = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
  }
  
  getNumberofCompanies = function () {
    this.companiesService.getCompaniesCount().toPromise().then(respons => {
      this.length = respons[0]['COUNT(*)'];
    });
  };
  public getServerData(event?: PageEvent) {
    if (event) {
      this.companiesService.getCompanies(event).toPromise().then((data: Array<CompaniesElement>) => {
        this.ELEMENT_DATA = data;
        var pageSettings = JSON.parse(localStorage.pageSettings);
        pageSettings.companies = { "items_per_page": this.pageEvent["pageSize"], "current_page": this.pageEvent["pageIndex"] };
        localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
      });
    } else {
      var pageSettings = JSON.parse(localStorage.pageSettings);
      this.companiesService.getCompanies({
        'length': this.length,
        'pageIndex': pageSettings.companies.current_page,
        'pageSize': pageSettings.companies.items_per_page,
        'previousPageIndex': 0
      }).toPromise().then((data: Array<CompaniesElement>) => {
        this.ELEMENT_DATA = data;
      });
    }
    return event;
  }
  getCompanies() {
    this.companiesService.getAllCompanies().toPromise().then((data: Array<CompaniesElement>) => {
      this.ELEMENT_DATA = data;
    });
  }
  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
}
