
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { TransactionsService } from './transactions.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, PageEvent, MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../_services/search/search.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService} from 'angular-web-storage';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/_services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
enum TransactionsElement {
  transaction_id,
  type,
  total,
  payment,
  customer_id, customer_name,
  store_id, store_name,
  transaction_date,
  user_id, user_name, total_margin, total_percent_margin
}
export enum ProductSoldOnElement {
  sell_id,
  product_id,
  store_id,
  sold_on_date,
  transaction_id,
  quantity_sold,
  customer_id,
  discount,
  price_sold_for
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  type = null; order_by = 'desc';
  loaded = false; from = null; to = null;
  to_searchviasku = null;from_searchviasku = null;
  searchviasku_display= "Please search";
  //////////////////////////////////
  displayedColumns: string[] = ["edit"
  ];
  filter_dropdown = false;
  ELEMENT_DATA: TransactionsElement[];
  selection = new SelectionModel<TransactionsElement>(true, []);
  showdd1 = false;
  showDeleteButton = false;
  report;
  date; tempColumns;
  length; page;
  pageSize = 10; sort_by;
  pageIndex; pageEvent: PageEvent;
  varsimplesearch = true; varsearchviasku = false;
  currentUserSubscription; currentUser; store_id;
  columns;
  columns_selected = ['actions'];

  queryField: FormControl = new FormControl();
  constructor(private transactionsService: TransactionsService,
    public dialog: MatDialog,
    private searchService: SearchService,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService,
    private local: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,) {
    window.scrollTo(0, 0);
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["transactions"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["transactions"]["current_page"];
    // this.transactionsService.correctMargins().subscribe();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user ? user[0] : "";
      if (this.currentUser.user_type != 'admin') {
        this.store_id = this.currentUser.store_id;
        this.local.set("store_id", this.store_id, 0, 's');
      }
      else {
        this.store_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
      }

    });
  }
  ngOnInit() {
    // this.spinner.show();
    this.pageSize = JSON.parse(localStorage.getItem('pageSettings'))["transactions"]["items_per_page"];
    this.page = JSON.parse(localStorage.getItem('pageSettings'))["transactions"]["current_page"];
    if(!this.page){
      this.page = 1;
    }
    console.log(this.page);
    console.log("this.page");
    if (this.local.get("transactions_margin")) {
      this.from = this.local.get("transactions_margin")["from"];
      this.to = this.local.get("transactions_margin")["to"];
      this.transactionsService.getTransactionsColumns().subscribe(data => {
        this.tempColumns = data;
      })
      this.sort_by = "percent_margin";
      var columns = [
        { "item_id": "transaction_id", "state": 1 },
        { "item_id": "type", "state": 1 },
        { "item_id": "total", "state": 1 },
        { "item_id": "payment", "state": 0 },
        { "item_id": "customer_id", "state": 1 },
        { "item_id": "store_id", "state": 0 },
        { "item_id": "transaction_date", "state": 1 },
        { "item_id": "user_id", "state": 1 },
        { "item_id": "verified_by", "state": 0 },
        { "item_id": "total_margin", "state": 1 },
        { "item_id": "total_percent_margin", "state": 1 },
        { "item_id": "extra_note", "state": 0 },
        { "item_id": "comments", "state": 0 },

      ];
      this.transactionsService.setTransactionsColumnsDisplay(columns).subscribe();
      this.local.remove("transactions_margin");

    }
    else {
      this.date = this.activatedRoute.snapshot.paramMap.get('date');
      this.from = this.activatedRoute.snapshot.paramMap.get('from');
      this.to = this.activatedRoute.snapshot.paramMap.get('to');
      this.type = this.activatedRoute.snapshot.paramMap.get('type');
      var columns = [
        { "item_id": "transaction_id", "state": 1 },
        { "item_id": "type", "state": 1 },
        { "item_id": "total", "state": 1 },
        { "item_id": "payment", "state": 1 },
        { "item_id": "customer_id", "state": 1 },
        { "item_id": "store_id", "state": 0 },
        { "item_id": "transaction_date", "state": 1 },
        { "item_id": "user_id", "state": 0 },
        { "item_id": "verified_by", "state": 0 },
        { "item_id": "total_margin", "state": 0 },
        { "item_id": "total_percent_margin", "state": 0 },
        { "item_id": "extra_note", "state": 1 },
        { "item_id": "comments", "state": 0 },

      ];
      this.transactionsService.setTransactionsColumnsDisplay(columns).subscribe();
    }
    if (this.from != null) {
      if (this.type != null) {
        this.from = new Date(this.from);
        this.to = new Date(this.to);
        this.transactionsService.getTransactionsCountToday(
          this.from,
          this.to, this.store_id, this.type).toPromise().then((respons) => {
            this.length = respons[0]["COUNT(*)"];
          });
        var pageSettings = JSON.parse(localStorage.pageSettings);
        this.getSalesToday({
          'length': this.length,
          'pageIndex': pageSettings.transactions.current_page,
          'pageSize': pageSettings.transactions.items_per_page,
          'previousPageIndex': 0
        }, this.sort_by);
        this.getReport(this.date);
      }
      else {
        this.from = new Date(this.from);
        this.to = new Date(this.to);
        this.transactionsService.getTransactionsCountToday(
          this.from,
          this.to, this.store_id).toPromise().then((respons) => {
            this.length = respons[0]["COUNT(*)"];
          });
        var pageSettings = JSON.parse(localStorage.pageSettings);
        this.getSalesToday({
          'length': this.length,
          'pageIndex': pageSettings.transactions.current_page,
          'pageSize': pageSettings.transactions.items_per_page,
          'previousPageIndex': 0
        }, this.sort_by);
        this.getReport(this.date);
      }
    }
    else {
      this.getServerData({ "items_per_page": this.pageSize, "page": this.page });
      this.getReport(null);
    }
    this.queryField.valueChanges.subscribe(data => {
      if(this.varsimplesearch){
        this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
          this.ELEMENT_DATA = data;
        });
      }
      else if(this.varsearchviasku){
        if(this.queryField.value.length === 0){
          this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
          this.ELEMENT_DATA = data;
          });
        }
        // else{
        //  this.ELEMENT_DATA = []
        //  this.transactionsService.getTransactionsviaSKU({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
        //     this.ELEMENT_DATA = data;
        //   });
        // }
      }
      });
        
      
    
    this.transactionsService.getTransactionsColumns().toPromise().then(data => {
      let i = 0;
      this.columns = data;
      for (const key in data) {
        i++;
        if (data[key]['show'] == 1) {
          this.columns_selected.push((data[key]['column_name'] == "customer_id") ? "customer_name" :
            (((data[key]['column_name'] == "store_id") ? "store_name" :
              (((data[key]['column_name'] == "user_id") ? "user_name" : data[key]['column_name'])))));

        }
      }
      this.loaded = true;
    });
    this.transactionsService.getReportForRange(event, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe(data => {
      this.report = data;
    });
    // setTimeout(()=>{                           //<<<---using ()=> syntax
    // this.spinner.hide();
    // }, 2000);
  }
  simplesearch() {
    this.varsearchviasku = false;
    this.varsimplesearch = true;
    this.filter_dropdown = false;
    this.queryField.setValue('');
    this.clearFilters();
  }
  searchviasku() {
    this.varsearchviasku = true;
    this.varsimplesearch = false;
    this.filter_dropdown = false;
    this.queryField.setValue('');
    this.clearFilters();
  }
  clearFilters() {
    this.from = null; this.to = null; this.order_by = 'desc';
    this.from_searchviasku = null; this.to_searchviasku = null;
  }
  selectionChange(item, i, event) {
    this.transactionsService.setTransactionsColumnDisplay(item['column_name'], event).subscribe(data => { }
    );
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].column_name == item.column_name) {
        this.columns[ind].show = event;
        break;
      }
    }
    this.columns_selected = ["actions"];
    for (let ind = 0; ind < this.columns.length; ind++) {
      if (this.columns[ind].show) {
        this.columns_selected.push(this.columns[ind].column_name);
      }
    }
    this.cdr.detectChanges();
  }

  PageChanged() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.search({ "items_per_page": this.pageSize, "page": this.page });
  }
  pageSizeChange() {
    var pageSettings = JSON.parse(localStorage.pageSettings);
    pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.page };
    localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    this.search({ "items_per_page": this.pageSize, "page": this.page });
  }
  getColumnName(inp): string {
    if (inp == "transaction_id") return "Invoice";
    if (inp == "type") return "Payment";
    if (inp == "total") return "Total"; ///////////
    if (inp == "payment") return "Amount Paid";
    if (inp == "customer_id" || inp == "customer_name") return "Customer";
    if (inp == "store_id" || inp == "store_name") return "Store";
    if (inp == "transaction_date") return "Transaction Date";
    if (inp == "user_id" || inp == "user_name") return "User";
    if (inp == "verified_by") return "Verified By";
    if (inp == "total_margin") return "Margin";
    if (inp == "total_percent_margin") return "%age Margin";
    if (inp == "extra_note") return "Name";
    if (inp == "comments") return "Comments";
  }
  getNumberofTransactions = function () {
    this.transactionsService.getTransactionsCount(this.store_id).toPromise().then(respons => {
      this.length = respons[0]["COUNT(*)"];
    });
  }
  public getServerData(event) {
    if (this.store_id !== 'all') {
      this.transactionsService.getTransactions(event, this.store_id,
        this.from,
        this.to, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
          this.ELEMENT_DATA = data;
          var pageSettings = JSON.parse(localStorage.pageSettings);
          pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.page};
          localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
        });
    }
    else {
      this.getTransactions()
    }
    return event;
  }
  getReport(date) {
    this.transactionsService.getReportForRange({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe(data => {
      this.report = data;
    });
  }
  getTransactions() {
    this.transactionsService.getAllTransactions({ "items_per_page": this.pageSize, "page": this.page }).subscribe((data: Array<TransactionsElement>) => {
      this.ELEMENT_DATA = data;
      var pageSettings = JSON.parse(localStorage.pageSettings);
      pageSettings.transactions = { "items_per_page": this.pageSize, "current_page": this.page };
      localStorage.setItem("pageSettings", JSON.stringify(pageSettings));
    });
  }

  toggleDD(id) {
    if (document.getElementById(id).style.display == 'block') {
      document.getElementById(id).style.display = 'none';
    }
    else {
      document.getElementById(id).style.display = 'block';
    }
  }
  search(event) {
    if(this.varsimplesearch){
      this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
        this.ELEMENT_DATA = data;
        this.transactionsService.getReportForRange({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe(data => {
          this.report = data;
        })
      });
    }
    else if(this.varsearchviasku){
      if(this.queryField.value.length === 0){
          this.transactionsService.getTransactions({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
            this.ELEMENT_DATA = data;
            this.transactionsService.getReportForRange({ "items_per_page": this.pageSize, "page": this.page }, this.store_id, this.to, this.from, this.order_by, this.queryField.value).subscribe(data => {
              this.report = data;
            })
          });
        }
        else{
         this.ELEMENT_DATA = []
         this.searchviasku_display = "Searching..."
         this.transactionsService.getTransactionsviaSKU({ "items_per_page": this.pageSize, "page": this.page }, this.store_id,this.to_searchviasku, this.from_searchviasku, this.queryField.value).subscribe((data: Array<TransactionsElement>) => {
            this.searchviasku_display = "Search completed"
            this.ELEMENT_DATA = data;
          });
        }
    }
      
  }

    //  Code Start
  editInvoice(transactionId, transactionDate) {
    console.log("New testing start");
    // console.log(prodId);
    console.log(transactionId);
    console.log(transactionDate);
    console.log("New testing end");
    let storeId = this.local.get("store_id");
    // this.productsService.CheckMainOrRelated(storeId, prodId).subscribe(data => {
    //   // console.log("Yo Man!!!");
    //   // this.ELEMENT_DATA = data;
    //   //this.prod_Related = data;
    //   //console.log(this.prod_Related);
    //   //console.log(this.prod_Related.length);
    //   console.log("Test Start");
    //   console.log(data);
    //   this.prods_Related = data["response_array"];
    //   this.IsMainItem = data["mainWithRelated"];
    //   this.IsRelatedItem = data["mainOrRelated"];
    //   console.log("Is Main Item with Related Items: " + this.IsMainItem);
    //   console.log("Is Main Item with no Related Items: " + this.IsRelatedItem);

    //   // this.prods_Related = data;
    //  }

     
    // );
    this.transactionsService.getTransaction(transactionId).toPromise().then((indata) => {

      console.log(indata["transaction"].transaction_number);
      console.log(indata["transaction"].shipment);
      console.log(indata["transaction"].customer_ref);
      console.log(indata["transaction"]);
      const dialogRef = this.dialog.open(EditInvoiceDialog, {
        height: '800px',
        width: '35%',
        data: { "number": indata["transaction"].transaction_number,
         "id": transactionId,
         "date": transactionDate, 
         "deliveryAddress": indata["transaction"].shipment, 
         "customerRef": indata["transaction"].customer_ref
         }
      });

      dialogRef.afterClosed().subscribe(() => {
        // location.reload();
      });
    });    
  }
  //  Code End
  
  getSalesToday(event, sort_by?) {
    this.transactionsService.getTransactionsToday(
      this.from,
      this.to, this.store_id, { "items_per_page": this.pageSize, "page": this.page }, this.type, sort_by).toPromise().then((data: Array<TransactionsElement>) => {
        this.ELEMENT_DATA = data;
      });
  }
}


//  Code Start
@Component({
  selector: 'edit-invoice-dialog',
  templateUrl: 'edit-invoice-dialog.html',
  styleUrls: ['./edit-invoice-dialog.scss'],
})
export class EditInvoiceDialog implements OnInit {
  transacNum; transacId; transacDate; deliveryAddress; customerRef
  constructor(public dialogRef: MatDialogRef<EditInvoiceDialog>, private local: LocalStorageService, private transactionsService: TransactionsService, private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) { 
      window.scrollTo(0, 0);

    this.transacNum = data.number;
    this.transacId = data.id;
    console.log("lllll");
    console.log(this.transacId);
    this.transacDate = data.date;
    this.deliveryAddress = data.deliveryAddress;
    this.customerRef = data.customerRef;
    // this.product_name = data.name;
    // this.sku = data.sku;
    // this.quantity = data.quantity;
    // this.storeId = this.local.get("store_id");
    // this.prevQuantity = data.prevQuantity;
  }

  ngOnInit() {
    /* this.productsService.getProduct(this.id, this.local.get("store_id")).subscribe(product => {
      this.product = product[0];
      // console.log("Start");
      // console.log(this.prod_Related);
      // console.log("End");
    }) */
  }

  OnConfirm() {
    console.log("Edit Invoice!");
    this.transactionsService.UpdateDeliveryAddressandReference(this.transacId, this.deliveryAddress, this.customerRef).subscribe(data => {    
      console.log(data);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
    this.toaster.success('Invoice has been updated successfully!', 'Invoice Updated', {})
  }
}
//  Code End
