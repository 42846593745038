import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor( private http: HttpClient) { }
  public getAllProducts(paginator){
    return  this.http.post(`${API_URL}products/`, {"paginator": paginator});
  }
  public getProductsCount(store_id){
    return  this.http.post(`${API_URL}products-count/`, {"store_id":store_id});
  }
  public getProducts(event, store_id, from?, to?, date_from?, date_to?, sort_by?, order_by?, query?, in_stock_filter?){
    return  this.http.post(`${API_URL}get-products/`, {"paginator":event, "store_id": store_id, "from": from, "to": to, "sort_by": sort_by, "order_by": order_by, "query":query, "in_stock": in_stock_filter, "date_from": date_from, "date_to": date_to});
  }
  public getProductCategories(event, sort_by?, order_by?, query?){
    return  this.http.post(`${API_URL}get-product-categories/`, {"paginator":event, "sort_by": sort_by, "order_by": order_by, "query":query});
  }
  public getProduct(id, store_id){
    return  this.http.get(`${API_URL}product/${id}`, store_id);
  }
  public getMainItemSKU(id){
    return  this.http.get(`${API_URL}main-product/${id}`);
  }
  public exportProducts(store_id){
    return  this.http.get(`${API_URL}export-product-csv/${store_id}`);
  }
  public updateProduct(id, product, stor_id){
    product["store_id"] = stor_id;
    console.log("HASSAN")
    console.log(product["location"])
    if(product["location"] === undefined) product["location"] = ""
    return this.http.post(`${API_URL}update-product/${id}/`,product);
  }
  public addProduct(product){
    return this.http.post(`${API_URL}add-product/`,product);
  }
  public getAllProductCategories(){
    return  this.http.get(`${API_URL}product-categories/`);
  }
  public getProductFieldNames(){
    return this.http.get(`${API_URL}product-fields/`);
  }
  public addProductCategory(product_category){
    return this.http.post(`${API_URL}add-product-category/`, product_category);
  }
  public updateProductCategory(product_category){
    return this.http.post(`${API_URL}update-product-category/`, product_category);
  }
  public addProductSubCategories(product_subcategory, cid){
    return this.http.post(`${API_URL}add-product-subcategory/${cid}/`, product_subcategory);
  }
  public getAllProductSubCategories(id){
    return this.http.get(`${API_URL}product-subcategories/${id}/`);
  }
  public addProductSubSubCategories(product_sub_subcategory, cid){
    return this.http.post(`${API_URL}add-product-sub-subcategory/${cid}/`, product_sub_subcategory);
  }
  public getAllProductSubSubCategories(id){
    return this.http.get(`${API_URL}product-sub-subcategories/${id}/`);
  }
  public addProductCategories(product_categories){
    return this.http.post(`${API_URL}add-categories-relation/`, product_categories);
  }
  public addToStock(stock){
    return this.http.post(`${API_URL}add-stock/`, stock);
  }
  public addPurchaseFromSupplier(PurchaseObject){
    return this.http.post(`${API_URL}add-product-purchase/`, PurchaseObject);
  }
  public addProductCSV(csvData){
    return this.http.post(`${API_URL}add-product-csv/`, csvData);
  }
  public addStockCSV(csvData){
    return this.http.post(`${API_URL}add-stock-csv/`, csvData);
  }
  public addProductImagesCSV(csvData){
    return this.http.post(`${API_URL}add-product-images-csv/`, csvData);
  }
  public getProductCategoryName(cat_id){
    return this.http.get(`${API_URL}get-category-name/${cat_id}/`);
  }
  public submitrawProduct(rawProduct){
    return this.http.post(`${API_URL}add-raw-product/`, rawProduct);
  }
  public getRawProductsCount(store_id){
    return  this.http.post(`${API_URL}raw-products-count/`, {"store_id":store_id});
  }
  public getRawProducts(event, store_id){
    return  this.http.post(`${API_URL}get-raw-products/`, {"event":event, "store_id":store_id});
  }
  public getAllRawProducts(){
    return  this.http.get(`${API_URL}get-all-raw-products/`);
  }
  public getAllRawProductsWithNoSKU(){
    return this.http.get(`${API_URL}get-all-raw-products-with-no-sku/`);
  }
  public getRawProduct(sku){
    return  this.http.get(`${API_URL}get-raw-product/${sku}/`);
  }
  public getProductbySKU(sku){
    return  this.http.get(`${API_URL}get-product-by-sku/${sku}/`);
  }
  public deleteRawProduct(sku){
    return this.http.delete(`${API_URL}delete-raw-product/${sku}/`);
  }
  public deleteProduct(id){
    return this.http.post(`${API_URL}delete-product/`, {"product_id": id});
  }
  public deleteProductCategory(id){
    return this.http.post(`${API_URL}delete-product-category/`, {"category_id": id});
  }
  
  public getProductsColumns() {
    return this.http.get(`${environment.apiUrl}products-columns/`);
  }
  public getProductCategoryColumns() {
    return this.http.get(`${environment.apiUrl}product-category-columns/`);
  }
  public setProductsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-products-columns/`, {"item_id":item_id, "state":state});
  }
  public setProductCategoryColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-product-category-columns/`, {"item_id":item_id, "state":state});
  }
  public getProductCategory(id){
    return this.http.post(`${API_URL}get-category/`, {"id":id});
  }
  
  public getRawProductsColumns() {
    return this.http.get(`${environment.apiUrl}raw-products-columns/`);
  }
  public setRawProductsColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-raw-products-columns/`, {"item_id":item_id, "state":state});
  }
  public getImages(id, quantity){
    return this.http.post(`${environment.apiUrl}get-images/${id}/`, {"quantity": quantity});
  }
  public deleteImage(image_id){
    return this.http.post(`${API_URL}delete_product_image/`, {"product_image_id":image_id});
  }
}