import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ViewProductTransaction {

  constructor( private http: HttpClient) { }
  public getAllTransactionIds(id,store_id){
    console.log("here in get all transaction ids")
    return this.http.get(`${API_URL}getAllTransactionIds/${id}/${store_id}`);}
}