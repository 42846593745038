import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SmtpService {
  constructor(private http: HttpClient) {}

  updateEmailCreds(data: { host: string; password: string }) {
    return this.http.put(
      `${environment.apiUrl}get-email-creds`,
      data
    );
  }
  getEmailCreds(){
    return this.http.get(`${environment.apiUrl}get-email-creds`)
  }
}
