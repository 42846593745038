import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient , HttpClientModule } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor( private http: HttpClient) { }
  public getAllCompanies(){
    return  this.http.get(`${API_URL}companies/`);
  }
  public getCompany(id){
    return  this.http.get(`${API_URL}company/${id}/`);
  }
  public updateCompany(id, company_object){
    return this.http.post(`${API_URL}update-company/${id}/`,company_object);
  }
  public addCompany(company_object){
    return this.http.post(`${API_URL}add-company/`,company_object);
  }
  public getCompaniesCount(){
    return  this.http.get(`${API_URL}companies-count/`);
  }
  public getCompanies(event){
    return  this.http.post(`${API_URL}get-companies/`, event);
  }
  public getCompaniesColumns() {
    return this.http.get(`${environment.apiUrl}companies-columns/`);
  }
  public setCompaniesColumnDisplay(item_id, state){
    return this.http.post(`${API_URL}set-companies-columns/`, {"item_id":item_id, "state":state});
  }
}
