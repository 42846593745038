import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { from } from 'rxjs';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: HttpClient) { }
  public UpdateDeliveryAddressandReference(transaction_id,delivery_address,customer_reference) {
    return this.http.post(`${API_URL}update-delivery-address-and-reference/`, { "transaction_id": transaction_id, 'delivery_address': delivery_address, "customer_reference": customer_reference});
  }
  public getAllTransactions(paginator) {
    return this.http.post(`${API_URL}transactions/`, {"paginator": paginator});
  }
  public getTransaction(id) {
    return this.http.get(`${API_URL}transactions/view/${id}/`);
  }
  public getTransactionByOffset(id,buttonValue,storeId,fromcustomerstatement,customer_id) {
    console.log(id);
    console.log("ggghh");
    console.log("BtnVal:" + buttonValue);
    console.log(storeId);
    console.log(fromcustomerstatement)
    console.log(customer_id)
    return this.http.get(`${API_URL}get-transactions-id/${id}/${buttonValue}/${storeId}/${fromcustomerstatement}/${customer_id}`);
  }
  public getTransactionForDelivery(id) {
    return this.http.get(`${API_URL}get-transaction-for-delivery/${id}/`);
  }
  public getTransactionsCount(store_id) {
    return this.http.post(`${API_URL}transactions-count/`, { "store_id": store_id });
  }
  public getTodaysTransactionsCount(store_id, from, to) {
    return this.http.post(`${API_URL}todays-sales-count/`, { "from": from, "to": to, "store_id": store_id });
  }
  public getTransactionByTransactionNumber(transaction_number) {
    return this.http.get(`${API_URL}transaction_number/${transaction_number}/`);
  }
  public getTransactions(event, store_id, to?, from?, order_by?, query?) {
    return this.http.post(`${API_URL}get-transactions/`, { "paginator": event, "store_id": store_id, "to": to, "from": from, "order_by": order_by, "query": query });
  }
  public getTransactionsviaSKU(event, store_id,to_searchviasku,from_searchviasku, query) {
    return this.http.post(`${API_URL}get-transactions-via-sku/`, { "paginator": event, "store_id": store_id,"to_searchviasku": to_searchviasku, "from_searchviasku": from_searchviasku, "query": query });
  }
  public getTransactionsColumns() {
    return this.http.get(`${environment.apiUrl}transactions-columns/`);
  }
  public setTransactionsColumnDisplay(item_id, state) {
    return this.http.post(`${API_URL}set-transactions-columns/`, { "item_id": item_id, "state": state });
  }
  public setTransactionsColumnsDisplay(data) {
    return this.http.post(`${API_URL}set-transactions-columns-s/`, data);
  }
  public getTransactionsToday(from, to, store_id, event, type = null, sort_by?) {
    return this.http.post(`${API_URL}get-transactions-today/${store_id}/`, { "from": from, "to": to, "event": event, "sort_by": sort_by, "type": type });
  }
  public getTransactionsCountToday(from, to, store_id, type?: string, event?: object) {
    return this.http.post(`${API_URL}todays-sales/`, { "from": from, 'to': to, "store_id": store_id, "type": type });
  }
  public getReport(date, store_id) {
    return this.http.post(`${API_URL}report/${store_id}/`, { "date": date });
  }
  public correctMargins() {
    return this.http.get(`${API_URL}correct-transactions-margin/`);
  }
  public resend(content, data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    let data_to_send = [];
    data_to_send.push(data);
    data_to_send.push(JSON.stringify(content));
    return this.http.post(`${API_URL}resend-email/${data["customer"]["email_id"]}/`, JSON.stringify(content), options);
    // return this.http.post(`${API_URL}resend-email/`, {"content" : JSON.stringify(content), "data": data}, options);
  }
  public resendquotation(content, data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    let data_to_send = [];
    data_to_send.push(data);
    data_to_send.push(JSON.stringify(content));
    return this.http.post(`${API_URL}resend-quotation-email/${data["customer"]["email_id"]}/`, JSON.stringify(content), options);
    // return this.http.post(`${API_URL}resend-email/`, {"content" : JSON.stringify(content), "data": data}, options);
  }
  public setColumnsDisplay(columns) {
    return this.http.post(`${API_URL}set-transactions-columns/`, columns)
  }
  public exportSales(store_id, from, to) {
    return this.http.post(`${API_URL}export-sales/`, {"store_id": store_id, "from": from, "to":to})
  }
  public getReportForRange(event, store_id, to, from, order_by, value){
    return this.http.post(`${API_URL}get-psr/`, {"store_id": store_id, "from": from, "to":to})
  }
  public getDeliveryNotes(event, store_id, to?, from?, order_by?, query?){
    return this.http.post(`${API_URL}get-delivery-notes/`,  { "paginator": event, "store_id": store_id, "to": to, "from": from, "order_by": order_by, "query": query });
  }
  public getDeliveryNote(id){
    return this.http.get(`${API_URL}get-delivery-note/${id}/`);
  }
  public getDeliveryNoteColumns(event, store_id, to?, from?, order_by?, query?){
    return this.http.post(`${API_URL}get-delivery-notes/`,  { "paginator": event, "store_id": store_id, "to": to, "from": from, "order_by": order_by, "query": query });
  }
  
  public getDeliveryNoteColumnsDisplay() {
    return this.http.get(`${environment.apiUrl}dn-columns/`);
  }
  public setDeliveryNotesColumnDisplay(item_id, state) {
    return this.http.post(`${API_URL}set-dn-columns/`, { "item_id": item_id, "state": state });
  }
}