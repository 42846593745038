import { Component, OnInit, Inject, ViewEncapsulation, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ProductsService } from '../products/products.service';
import { SuppliersService } from '../suppliers/suppliers.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AuthenticationService } from '@app/_services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'add-product-information',
  templateUrl: './add-product-information.component.html',
  styleUrls: ['./add-product-information.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddProductInformationComponent implements OnInit {
  // related = false;
  supplier_id; id;
  productCategories = []; suppliers = [];
  productSubcategories = []; productSubSubcategories = [];
  product = {};
  categoriesRelation = {};
  myform: FormGroup;
  product_name: FormControl;
  category: FormControl;
  subcategory_id: FormControl;
  sub_subcategory_id: FormControl;
  upc: FormControl;
  sku: FormControl;
  alternate_sku: FormControl;
  location: FormControl;
  brand: FormControl;
  product_description: FormControl;
  image_description: FormControl;
  price_c: FormControl;
  reorder_level: FormControl;
  deleted: FormControl;
  cost: FormControl;
  price_a: FormControl;
  price_b: FormControl;
  is_related: FormControl;
  related: FormControl;
  submitted = false; currentUser; currentUserSubscription; stor_id;
  constructor(private route: ActivatedRoute,
    private productsService: ProductsService,
    private router: Router, public dialog: MatDialog,
    private suppliersService: SuppliersService,
    private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService,
    private authenticationService: AuthenticationService,
    private local: LocalStorageService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user[0];
      if (this.currentUser.user_type != 'admin') {
        this.stor_id = this.currentUser.store_id;
        this.local.set("store_id", this.stor_id, 0, 's');
      }
      else {
        this.currentUser.store_id = this.local.get("store_id");
        this.stor_id = this.local.get("store_id");
      }

    });
  }
  submitProduct = function () {
    this.price_a.setValue(this.product["default_price"] * 1.2);
    this.price_b.setValue(this.product["best_price"] * 1.2);
    this.price_c.setValue(this.product["price_per_unit"] * 1.2);

    if (this.currentUser.user_type != 'admin') {
      this.stor_id = this.currentUser.store_id;
      this.local.set("store_id", this.stor_id, 0, 's');
    }
    else {
      this.stor_id = (this.local.get("store_id") == "all") ? this.currentUser.store_id : this.local.get("store_id");
    }
    this.submitted = true;

    if (this.myform.status === "VALID") {
      this.confirmationDialogService.confirm('Please confirm', 'Do you really want to submit Product?')
        .then((confirmed) => {
          if (confirmed) {
            if (this.id != null) {
              this.productsService.updateProduct(this.id, this.myform.value, this.stor_id).subscribe((data) => {
                this.toaster.success('Updated successfully!', 'Success', {
                  timeOut: 3000
                });
                this.router.navigate(['/products']);
              },
                error => {0
                  this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                    timeOut: 3000
                  });
                });
            } else {
              this.productsService.addProduct(this.myform.value).subscribe((data) => {
                this.submittedProductID = data.product_id;
                var stock = {
                  "product_id": data.product_id,
                  "store_id": this.stor_id,
                  "quantity": 0
                }
                this.productsService.addToStock(stock).subscribe((data) => {
                  this.toaster.success('Added successfully!', 'Success', {
                    timeOut: 3000
                  });
                },
                  error => {
                    error => this.error = error;
                    this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                      timeOut: 3000
                    });
                  }
                );
                this.categoriesRelation["product_id"] = this.submittedProductID;
                this.categoriesRelation["category_id"] = this.category.value;
                this.categoriesRelation["subcategory_id"] = this.subcategory_id.value;
                this.categoriesRelation["sub_subcategory_id"] = this.sub_subcategory_id.value;
                this.productsService.addProductCategories(this.categoriesRelation).subscribe((data) => {
                  this.toaster.success('Added successfully!', 'Success', {
                    timeOut: 3000
                  });
                  this.router.navigate(['/products/']);
                },
                  error => {
                    this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                      timeOut: 3000
                    });
                  });
              });
            }
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.createFormControls();
    this.createForm();

    this.category.valueChanges.subscribe(value => this.loadSubCategories());
    this.subcategory_id.valueChanges.subscribe(value => this.loadSubSubCategories());
    if (this.id != null) {
      this.productsService.getProduct(this.id, this.stor_id).toPromise().then((data) => {
        this.product = data[0];
        this.sku.setValue(this.product["sku"]);
        this.alternate_sku.setValue(this.product["alternate_sku"]);
        this.location.setValue(this.product["location"]);
        this.product_name.setValue(this.product["product_name"]);
        this.product_description.setValue(this.product["product_description"]);
        this.image_description.setValue(this.product["image_description"]);
        this.category.setValue(this.product["category_id"]);
        this.price_c.setValue(this.product["price_per_unit"] * 1.2);
        this.reorder_level.setValue(this.product["reorder_level"]);
        this.deleted.setValue(this.product["deleted"]);
        this.cost.setValue(this.product["cost"]);
        this.price_a.setValue(this.product["default_price"] * 1.2);
        this.price_b.setValue(this.product["best_price"] * 1.2);
        this.brand.setValue(this.product["brand"]);
        this.upc.setValue(this.product["upc"]);
        // this.is_related.setValue(this.product["related"]);
        this.related.setValue(this.product["related"]);

        if (this.related.value) {
          this.productsService.getMainItemSKU(this.id).toPromise().then(data => {
            this.is_related.setValue(data["related"]);
          })
        }
      })
    }
    this.getAllProductCategories();
  }
  createFormControls() {
    this.sku = new FormControl('', Validators.required);
    this.alternate_sku = new FormControl('');
    this.product_name = new FormControl('', [
      Validators.required,
    ]);
    this.location = new FormControl('');
    this.product_description = new FormControl('');
    this.image_description = new FormControl('');
    this.category = new FormControl('', [
    ]);
    this.subcategory_id = new FormControl('');
    this.sub_subcategory_id = new FormControl('');
    this.price_c = new FormControl('', [
      Validators.required,
    ]);
    this.reorder_level = new FormControl('', [
    ]);
    this.deleted = new FormControl('');
    this.cost = new FormControl('', [
    ]);
    this.price_a = new FormControl('');
    this.price_b = new FormControl('', [
    ]);
    this.brand = new FormControl('', [
    ]);
    this.upc = new FormControl('', [
    ]);
    this.is_related = new FormControl("");
    this.related = new FormControl(false);
  }
  createForm() {
    this.myform = new FormGroup({
      product_name: this.product_name,
      category: this.category,
      subcategory_id: this.subcategory_id,
      sub_subcategory_id: this.sub_subcategory_id,
      upc: this.upc,
      sku: this.sku,
      alternate_sku: this.alternate_sku,
      location: this.location,
      brand: this.brand,
      product_description: this.product_description,
      image_description: this.image_description,
      price_c: this.price_c,
      reorder_level: this.reorder_level,
      deleted: this.deleted,
      cost: this.cost,
      price_a: this.price_a,
      price_b: this.price_b,
      is_related: this.is_related,
      related: this.related,
    });
  }
  Achange(event) {
    this.product["default_price"] = event.target.value / 1.2;
  }
  Bchange(event) {
    this.product["best_price"] = event.target.value / 1.2;
  }
  Cchange(event) {
    this.product["price_per_unit"] = event.target.value / 1.2;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAllSuppliers = function () {
    this.suppliersService.getAllSuppliers().subscribe((data: Array<object>) => {
      this.suppliers = data;
    });
  }
  getAllProductCategories = function () {
    this.productsService.getAllProductCategories().subscribe((data: Array<object>) => {
      this.productCategories = data;
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAllProductSubCategories = function () {
    this.productsService.getAllProductSubCategories(this.category.value).subscribe((data: Array<object>) => {
      this.productSubcategories = data;
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getAllProductSubSubCategories = function () {
    this.productsService.getAllProductSubSubCategories(this.subcategory_id.value).subscribe((data: Array<object>) => {
      this.productSubSubcategories = data;
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openProductCategoryDialog(): void {
    const dialogRef = this.dialog.open(AddProductCategoryDialog, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllProductCategories();
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openProductSubCategoryDialog(): void {
    const dialogRef = this.dialog.open(AddProductSubCategoryDialog, {
      data: { category: this.category.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllProductSubCategories();
      this.loadSubSubCategories();
      this.loadSubCategories();
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openProductSubSubCategoryDialog(): void {
    const dialogRef = this.dialog.open(AddProductSubSubCategoryDialog, {
      data: { subcategory_id: this.subcategory_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllProductSubSubCategories();
      this.loadSubSubCategories();
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  loadSubCategories = function () {
    this.productsService.getAllProductSubCategories(this.category.value).subscribe((data: Array<object>) => {
      this.productSubcategories = data;
    });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  loadSubSubCategories = function () {
    this.productsService.getAllProductSubSubCategories(this.subcategory_id.value).subscribe((data: Array<object>) => {
      this.productSubSubcategories = data;
    });
  }

}

///////////////////////////////////////////////////////////Product Category //////////////////////////////////////////////////////////////////////////
@Component({
  selector: 'add-product-category-dialog',
  templateUrl: 'add-product-category-dialog.html',
})
export class AddProductCategoryDialog {

  model;
  constructor(
    public dialogRef: MatDialogRef<AddProductCategoryDialog>,
    private productsService: ProductsService, private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.model = {};
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  submit = function () {
    // this.best_price.value = this.best_price.value ? this.best_price.value: this.default_price.value + this.default_price.value*10/100 ;
    // this.price_per_unit.value = this.price_per_unit.value ? this.price_per_unit.value: this.default_price.value + this.default_price.value*20/100 ;
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure to add this product category?')
      .then((confirmed) => {
        if (confirmed) {
          this.productsService.addProductCategory(this.model).subscribe((data) => {
            this.toaster.success('Added successfully!', 'Success', {
              timeOut: 3000
            });
            this.onNoClick();
          },
            error => {
              this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                timeOut: 3000
              });
            });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}

///////////////////////////////////////////////////////////Product SubCategory //////////////////////////////////////////////////////////////////////////
@Component({
  selector: 'add-product-subcategory-dialog',
  templateUrl: 'add-product-subcategory-dialog.html',
})
export class AddProductSubCategoryDialog {

  model;
  constructor(
    public dialogRef: MatDialogRef<AddProductSubCategoryDialog>,
    private productsService: ProductsService, private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data: DialogData,) {
    this.model = {};
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  submit = function () {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure to add this product sub-category?')
      .then((confirmed) => {
        if (confirmed) {
          this.productsService.addProductSubCategories(this.model, this.data.category).subscribe((data) => {
            this.toaster.success('Added successfully!', 'Success', {
              timeOut: 3000
            });
            this.onNoClick();
          },
            error => {
              this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                timeOut: 3000
              });
            });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}

///////////////////////////////////////////////////////////Product Sub sub Category //////////////////////////////////////////////////////////////////////////
@Component({
  selector: 'add-product-sub-subcategory-dialog',
  templateUrl: 'add-product-sub-subcategory-dialog.html',
})
export class AddProductSubSubCategoryDialog {

  model;
  constructor(
    public dialogRef: MatDialogRef<AddProductSubSubCategoryDialog>,
    private productsService: ProductsService, private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    window.scrollTo(0, 0);
    this.model = {};
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  submit = function () {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure to add this product sub sub-category?')
      .then((confirmed) => {
        if (confirmed) {
          this.productsService.addProductSubSubCategories(this.model, this.data.subcategory_id).subscribe((data) => {
            this.toaster.success('Added successfully!', 'Success', {
              timeOut: 3000
            });
            this.onNoClick();
          },
            error => {
              this.toaster.error('OOPS! Somehing Went Wrong', 'Major Error', {
                timeOut: 3000
              });
            });

        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
